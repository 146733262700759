import { KPITimeScale } from 'src/@types/kpi';
import { KPIMetricheEnum } from 'src/parse/models/interfaces/kpi-metriche.enum';
import { KPIInternalModel } from 'src/redux/models/KPIInternalModel';

export function getKPICaption(kpi: KPIMetricheEnum) {
  switch (kpi) {
    case KPIMetricheEnum.vivacizzatori:
      return 'Vivacizzatori';
    case KPIMetricheEnum.asteTotali:
      return 'Tutte le aste su Vivapro';
    case KPIMetricheEnum.asteProvinceCoperte:
      return 'Aste province coperte';
    case KPIMetricheEnum.asteAttiveProvinceCoperte:
      return 'Aste Attive Province Coperte';
    case KPIMetricheEnum.totaleVivacizzazioni:
      return 'Vivacizzazioni Totali';
    case KPIMetricheEnum.vivacizzatoriConGuadagni:
      return 'Vivacizzatori con Guadagni';
    case KPIMetricheEnum.vivacizzazioniSuVivacizzatori:
      return '% Vivacizzazioni / Vivacizzatori';
    case KPIMetricheEnum.vivacizzazioniSuAste:
      return 'Vivacizzazioni / Aste Portale';
    case KPIMetricheEnum.percentualeProvinceSenzaVivacizzatori:
      return '% Province senza vivacizzatori';
    case KPIMetricheEnum.percentualeVivacizzatoriSenzaAste:
      return '% Vivacizzatori senza aste';
    case KPIMetricheEnum.provinceConVivacizzatori:
      return 'Province con vivacizzatori';
    case KPIMetricheEnum.provinceSenzaAsteConVivacizzatori:
      return 'Province senza aste con vivacizzatori';
    case KPIMetricheEnum.provinceVivacizzabili:
      return 'Province vivacizzabili';
    case KPIMetricheEnum.astePerProvinceVivacizzabili:
      return 'Aste per province vivacizzabili';
    case KPIMetricheEnum.provinceVivacizzate:
      return 'Province vivacizzate';
    case KPIMetricheEnum.provinceVivacizzateSuVivacizzabili:
      return 'Province vivacizzate/Province vivacizzabili';
    case KPIMetricheEnum.totaleProvvigioniRevivaPotenziali:
      return 'Totale provvigioni Reviva potenziali';
    case KPIMetricheEnum.totaleProvvigioniRevivaMaturato:
      return 'Totale provvigioni Reviva maturato';
    case KPIMetricheEnum.vivacizzantiSuVivacizzatori:
      return 'Vivacizzanti / Vivacizzatori';
    case KPIMetricheEnum.asteMultiVivacizzate:
      return 'Aste vivacizzate con più di un vivacizzatore';
    case KPIMetricheEnum.provinceSenzaVivacizzatori:
      return 'Province senza vivacizzatori';
    case KPIMetricheEnum.vivacizzatoriAttiviMese:
      return 'Vivacizzatori Attivi';
    case KPIMetricheEnum.nuoviVivacizzatori:
      return 'Totale Nuovi vivacizzatori';
    case KPIMetricheEnum.totaleRecessi:
      return 'Totale Recessi';
    case KPIMetricheEnum.vivacizzatoriAgenti:
      return 'Vivacizzatori Agenti';
    case KPIMetricheEnum.vivacizzatoriConsulenti:
      return 'Vivacizzatori Consulenti';
    case KPIMetricheEnum.agentiSuVivacizzatori:
      return 'Agenti / Totale vivacizzatori';
    case KPIMetricheEnum.consulentiSuVivacizzatori:
      return 'Consulenti / Totale vivacizzatori';
    case KPIMetricheEnum.vivacizzazioniDaAgenti:
      return 'Vivacizzazioni da agenti';
    case KPIMetricheEnum.vivacizzazioniDaConsulenti:
      return 'Vivacizzazioni da consulenti';
    case KPIMetricheEnum.vivacizzazioniDaAgentiSuTotali:
      return 'Vivacizzazioni da agenti / Vivacizzazioni totali';
    case KPIMetricheEnum.vivacizzazioniDaConsulentiSuTotali:
      return 'Vivacizzazioni da consulenti / Vivacizzazioni totali';
    case KPIMetricheEnum.vivacizzatoriAgentiConAste:
      return 'Vivacizzatori Agenti con aste';
    case KPIMetricheEnum.agentiConAsteSenzaGuadagno:
      return "Vivacizzatori Agenti che potevano Vivacizzare e non l'hanno fatto";
    case KPIMetricheEnum.vivacizzatoriConsulentiConAste:
      return 'Vivacizzatori Consulenti con aste';
    case KPIMetricheEnum.consulentiConAsteSenzaGuadagno:
      return "Vivacizzatori Consulenti che potevano Vivacizzare e non l'hanno fatto";
    case KPIMetricheEnum.asteSenzaVivacizzatori:
      return 'Numero aste su Vivapro senza Vivacizzatori';
    case KPIMetricheEnum.totaleRicaviAbbonamenti:
      return 'Totale ricavi abbonamenti';
    case KPIMetricheEnum.vivacizzatoriRientrati:
      return 'Vivacizzatori Rientrati';
    case KPIMetricheEnum.vivacizzatoriConAste:
      return 'Vivacizzatori Con Aste';
    case KPIMetricheEnum.vivacizzatoriSenzaAste:
      return 'Vivacizzatori Senza Aste';
    case KPIMetricheEnum.vivacizzatoriMaiVivacizzato:
      return "Vivacizzatori che non hanno mai vivacizzato (dall'entrata)";
    case KPIMetricheEnum.vivacizzatoriSenzaGuadagno:
      return 'Vivacizzatori che non hanno vivacizzato';
    case KPIMetricheEnum.vivacizzatoriConAsteSenzaGuadagno:
      return "Vivacizzatori che potevano vivacizzare ma non l'hanno fatto";
    case KPIMetricheEnum.vivacizzatoriSenzaAsteSuTotale:
      return 'Vivacizzatori Senza Aste su totale';
    case KPIMetricheEnum.vivacizzatoriConAsteSenzaGuadagnoSuTotale:
      return "Vivacizzatori che potevano vivacizzare ma non l'hanno fatto sul totale";
    case KPIMetricheEnum.vivacizzazioniSenzaGuadagnoSuVivacizzatoriConAste:
      return 'Vivacizzatori che non hanno vivacizzato su vivacizzatori con aste';
    case KPIMetricheEnum.vivacizzatoreAsteMese:
      return 'Aste da vivacizzare nel mese';
    case KPIMetricheEnum.aggiudicateProvinceVivacizzatore:
      return 'Aste aggiudicate globalmente';
    case KPIMetricheEnum.vivacizzatoreVivacizzazioni:
      return 'Aste che hai vivacizzato';
    case KPIMetricheEnum.vivacizzatorePotenzialeGuadagno:
      return 'Potenziale Guadagno';
    case KPIMetricheEnum.vivacizzatoreGuadagni:
      return 'Il tuo guadagno';

    default:
      return 'KPI non definito';
  }
}

export function getKPIChartInfoForHistogram(
  kpiType: KPIMetricheEnum,
  timeScale: KPITimeScale,
  kpiData: KPIInternalModel[]
) {
  const tokens: {
    label: string;
    value: number;
  }[] = [];
  kpiData.forEach((kpiDataItem) => {
    const theValue = getKPIChartValue(kpiType, kpiDataItem);
    const theLabel = getKPIChartLabel(timeScale, kpiDataItem);
    const token = { label: theLabel, value: theValue };
    tokens.push(token);
  });
  return tokens;
}

export function getKPIChartLabelsForStatistics(
  timeScale: KPITimeScale,
  kpiData: KPIInternalModel[]
) {
  const labels: string[] = [];
  kpiData.forEach((kpiDataItem) => {
    const theLabel = getKPIChartLabel(timeScale, kpiDataItem);
    labels.push(theLabel);
  });
  return labels;
}

export function getKPIChartValuesForStatistics(
  kpiType: KPIMetricheEnum,
  kpiData: KPIInternalModel[]
) {
  const values: number[] = [];
  kpiData.forEach((kpiDataItem) => {
    const theValue = getKPIChartValue(kpiType, kpiDataItem);
    values.push(theValue);
  });
  return values;
}

export function getKPIChartValuesForPolar(
  kpiType: KPIMetricheEnum,
  timeScale: KPITimeScale,
  kpiData: KPIInternalModel[]
) {
  const tokens: {
    label: string;
    value: number;
  }[] = [];
  kpiData.forEach((kpiDataItem) => {
    const theValue = getKPIChartValue(kpiType, kpiDataItem);
    const theLabel = getKPIChartLabel(timeScale, kpiDataItem);
    const token = { label: theLabel, value: theValue === null ? 0 : theValue };
    tokens.push(token);
  });
  return tokens;
}

export function getQuarterFromDate(date: Date) {
  return Math.floor(date.getMonth() / 3 + 1);
}

export function getSemesterFromDate(date: Date) {
  return Math.floor(date.getMonth() / 6 + 1);
}

export function getKPIChartValue(kpiType: KPIMetricheEnum, kpiData: KPIInternalModel) {
  const kpiItem = kpiData.kpis.get(kpiType);
  return kpiItem ? (kpiItem.valore !== null ? kpiItem.valore : 0) : 0;
}

export function getKPIChartLabel(timeUnit: KPITimeScale, kpiData: KPIInternalModel) {
  var label = 'N/A';
  if (timeUnit === KPITimeScale.MONTH) {
    const month = kpiData.referenceDate.toLocaleString('it-IT', { month: 'short' });
    label = `${kpiData.referenceDate.getFullYear()} ${capitalizeFirstLetter(month)}`;
  } else if (timeUnit === KPITimeScale.QUARTER) {
    label = `${kpiData.referenceDate.getFullYear()} Q${getQuarterFromDate(kpiData.referenceDate)}`;
  } else if (timeUnit === KPITimeScale.SEMESTER) {
    label = `${kpiData.referenceDate.getFullYear()} S${getSemesterFromDate(kpiData.referenceDate)}`;
  } else if (timeUnit === KPITimeScale.YEAR) {
    label = `${kpiData.referenceDate.getFullYear()}`;
  }
  return label;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
