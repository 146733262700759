import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SORT_BY_DATE_DESC_KEY } from 'src/sections/@dashboard/lista/AsteSort';
import RANGES from 'src/_const/ranges';

export interface IUIState {
  sortBy: string | null;
  searchText: string | null;
  filters: IUIActionFilters;
  showFavourites: boolean;
  showLivenUp: boolean;
  showNewAuctionOnly: boolean;
}

export interface IUIActionFilters {
  cities: string[];
  provinces: string[];
  regions: string[];
  typologies: string[];
  offertaMinimaRange: [number, number];
}

const initialState: IUIState = {
  sortBy: SORT_BY_DATE_DESC_KEY,
  filters: {
    cities: [],
    provinces: [],
    regions: [],
    typologies: [],
    offertaMinimaRange: [0, RANGES.MAX_OFFERTA_MINIMA],
  },
  searchText: null,
  showFavourites: false,
  showLivenUp: false,
  showNewAuctionOnly: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setSorting(state, action: PayloadAction<string | null>) {
      state.sortBy = action.payload;
    },

    setFilters(state, action: PayloadAction<IUIActionFilters>) {
      state.filters.cities = action.payload.cities;
      state.filters.provinces = action.payload.provinces;
      state.filters.regions = action.payload.regions;
      state.filters.typologies = action.payload.typologies;
      state.filters.offertaMinimaRange = action.payload.offertaMinimaRange;
    },

    setSearchText(state, action: PayloadAction<string | null>) {
      state.searchText = action.payload;
    },

    setShowFavourites(state, action: PayloadAction<boolean>) {
      state.showFavourites = action.payload;
    },

    setShowLivenUp(state, action: PayloadAction<boolean>) {
      state.showLivenUp = action.payload;
    },

    setShowNewAuctionOnly(state, action: PayloadAction<boolean>) {
      state.showNewAuctionOnly = action.payload;
    },

    clearFilters(state) {
      state.showLivenUp = false;
      state.showFavourites = false;
      state.showNewAuctionOnly = false;
      state.sortBy = SORT_BY_DATE_DESC_KEY;
      state.searchText='';
      state.filters.cities = [];
      state.filters.provinces = [];
      state.filters.regions = [];
      state.filters.typologies = [];
      state.filters.offertaMinimaRange = [0, RANGES.MAX_OFFERTA_MINIMA];
    },
  },
});

export const uiStateActions = uiSlice.actions;

export default uiSlice.reducer;
