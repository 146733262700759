import { Theme } from '@mui/material';
import { AstaModel } from 'src/parse/models/asta.model';
import { TipoNotifiche } from 'src/parse/models/interfaces/tipo-notifiche.enum';
import { NotificaModel } from 'src/parse/models/notifica.model';

export type LayoutToken = {
  primaryText: string;
  secondaryText: string;
  iconPath: string;
  iconColor?: string;
};

const getAuctionDetails = (notification: NotificaModel) => {
  let details = '';
  const auctionModel = getAuctionModelFromNotification(notification);
  if (auctionModel) {
    details = `RGE: ${auctionModel.rge} Lotto: ${auctionModel.lotto}`;
  }
  return details;
};

export const getAuctionModelFromNotification = (notification: NotificaModel) => {
  let theModel = undefined;
  switch (notification.tipo) {
    case TipoNotifiche.ADMIN_NOMINATIVO:
      if (notification.riferimenti && notification.riferimenti.length > 1) {
        theModel = notification.riferimenti[1] as AstaModel;
      }
      break;
    case TipoNotifiche.PARTNER_GUADAGNO_ADD:
    case TipoNotifiche.PARTNER_GUADAGNO_EDIT:
      if (notification.riferimenti && notification.riferimenti.length > 1) {
        theModel = notification.riferimenti[1] as AstaModel;
      }
      break;
    case TipoNotifiche.PARTNER_GUADAGNO_DELETE:
      if (notification.riferimenti && notification.riferimenti.length > 0) {
        theModel = notification.riferimenti[0] as AstaModel;
      }
      break;
    case TipoNotifiche.PARTNER_PARTICIPATION_ACCEPTED:
    case TipoNotifiche.PARTNER_PARTICIPATION_REJECTED:
    case TipoNotifiche.PARTNER_PARTICIPATION_LATE:
    case TipoNotifiche.PARTNER_PARTICIPATION_ADD:
      if (notification.riferimenti && notification.riferimenti.length > 0) {
        theModel = notification.riferimenti[0] as AstaModel;
      }
      break;
    default:
      break;
  }
  return theModel;
};

export function getNotificationData2Display(
  notification: NotificaModel,
  theme: Theme
): LayoutToken {
  switch (notification.tipo) {
    case TipoNotifiche.ADMIN_IMPORT:
      return {
        primaryText: 'Importazione',
        secondaryText: '',
        iconPath: 'ic:sharp-import-export',
        iconColor: theme.palette.warning.darker,
      };
    case TipoNotifiche.ADMIN_NOMINATIVO:
      return {
        primaryText: 'Nuovo nominativo',
        secondaryText: `aggiunto da ${notification.originator.anagrafica.nome} ${
          notification.originator.anagrafica.cognome
        } sull' asta ${getAuctionDetails(notification)}`,
        iconPath: 'ant-design:user-add-outlined',
        iconColor: theme.palette.info.main,
      };
    case TipoNotifiche.ADMIN_RICHIESTA_PROVINCE:
      return {
        primaryText: 'Cambio Provincia',
        secondaryText: `richiesto da ${notification.originator.anagrafica.nome} ${
          notification.originator.anagrafica.cognome
        } sull' asta ${getAuctionDetails(notification)}`,
        iconPath: 'bx:message-square-edit',
        iconColor: theme.palette.info.main,
      };
    case TipoNotifiche.PARTNER_BROADCAST:
      return {
        primaryText: 'Avviso di sistema',
        secondaryText: notification.content,
        iconPath: 'bx:broadcast',
        iconColor: theme.palette.info.main,
      };
    case TipoNotifiche.PARTNER_GUADAGNO_ADD:
      return {
        primaryText: 'Nuovo guadagno',
        secondaryText: "sull' asta " + getAuctionDetails(notification),
        iconPath: 'material-symbols:euro',
        iconColor: theme.palette.success.main,
      };
    case TipoNotifiche.PARTNER_GUADAGNO_DELETE:
      return {
        primaryText: 'Guadagno rimosso',
        secondaryText: "sull' asta " + getAuctionDetails(notification),
        iconPath: 'material-symbols:euro',
        iconColor: theme.palette.error.main,
      };
    case TipoNotifiche.PARTNER_GUADAGNO_EDIT:
      return {
        primaryText: 'Guadagno modificato',
        secondaryText: "sull' asta " + getAuctionDetails(notification),
        iconPath: 'material-symbols:euro',
        iconColor: theme.palette.warning.main,
      };
    case TipoNotifiche.PARTNER_BROADCAST_RISORSA_ADD:
      return {
        primaryText: 'Nuova Media',
        secondaryText: 'disponibile',
        iconPath: 'mdi:folder-media-outline',
        iconColor: theme.palette.warning.main,
      };
    case TipoNotifiche.PARTNER_PARTICIPATION_ACCEPTED:
      return {
        primaryText: 'Partecipazione accettata',
        secondaryText: "sull' asta " + getAuctionDetails(notification),
        iconPath: 'mdi:email-fast-outline',
        iconColor: theme.palette.success.main,
      };
    case TipoNotifiche.PARTNER_PARTICIPATION_REJECTED:
      return {
        primaryText: 'Partecipazione rifiutata',
        secondaryText: "sull' asta " + getAuctionDetails(notification),
        iconPath: 'mdi:email-fast-outline',
        iconColor: theme.palette.error.main,
      };
    case TipoNotifiche.PARTNER_PARTICIPATION_LATE:
      return {
        primaryText: 'Primo deposito mancato',
        secondaryText: "sull' asta " + getAuctionDetails(notification),
        iconPath: 'mdi:email-fast-outline',
        iconColor: theme.palette.warning.main,
      };
    case TipoNotifiche.PARTNER_PARTICIPATION_ADD:
      return {
        primaryText: 'Nuova Partecipazione',
        secondaryText: "sull' asta " + getAuctionDetails(notification),
        iconPath: 'mdi:email-fast-outline',
        iconColor: theme.palette.info.main,
      };

    default:
      return {
        primaryText: '',
        secondaryText: '',
        iconPath: '',
      };
  }
}
