import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// routes
import { EDIT_PATH } from '../routes/paths';
// hooks
import useTabs from '../hooks/useTabs';
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
// sections
import {
  AccountGeneral,
} from '../sections/@dashboard/user/account';
import { useLocation, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { UserService } from 'src/parse/services/user.service';
import { UserModel } from 'src/parse/models/user.model';
import SkeletonPartnerEdit from 'src/components/skeleton/SkeletonPartnerEdit';

// ----------------------------------------------------------------------
export default function PartnerEdit() {
  const { themeStretch } = useSettings();

  const { id = '' } = useParams();

  const { pathname } = useLocation();
  
  const [partner, setPartner] = useState<UserModel>();

  const isNewPartner = !pathname.includes(EDIT_PATH);

  useEffect(() => {
    const getPartner = async () => {
      const partner = await UserService.getUserById(id);
      setPartner(partner);
    }

    getPartner()
      .catch(console.error);
  }, [id]);

  const { currentTab, onChangeTab } = useTabs('generale');

  const generateTabs = () => {
    if (partner || isNewPartner) {
      return [
        {
          value: 'generale',
          icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
          component: <AccountGeneral isNew={isNewPartner} user={partner!} />,
        },
      ]
    }
    return []
  }

  const getLastLink = () => {
    if (isNewPartner) {
      return "Nuovo Partner"
    } else if (partner) {
      return partner.anagrafica?.nome + ' ' + partner.anagrafica?.cognome
    } else {
      return '';
    }
  }

  return (
    <Page title="Gestione partner">
      {partner || isNewPartner ? (
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="Account"
            links={[
              { name: 'Partners', href: "/partners" },
              { name: getLastLink() },
            ]}
          />

          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {generateTabs().map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 5 }} />

          {generateTabs().map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      ) : (
        <SkeletonPartnerEdit />
      )}
    </Page>
  );
}
