import * as Parse from 'parse';

export class ComuneModel extends Parse.Object {
    
    public static className = "Comuni";

    public static fields = {
       objectId: "objectId",
       createdAt: "createdAt",
       updatedAt: "updatedAt",
       ACL: "ACL",
       cap: "cap",
       ntn: "ntn",
       nome: "nome",
       regione: "regione",
       provincia: "provincia",
       coordinate: "coordinate",
       codiceIstat: "codiceIstat",
       codiceCatasto: "codiceCatasto",
       siglaProvincia: "siglaProvincia",
       codiceIstatRegione: "codiceIstatRegione",
       codiceIstatProvincia: "codiceIstatProvincia"
   };
    
    constructor() {
        super(ComuneModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(ComuneModel.className, ComuneModel);
    }

    public get objectId(): string {
        return this.get(ComuneModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(ComuneModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(ComuneModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(ComuneModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(ComuneModel.fields.ACL, ACL);
    }

    public get cap(): string {
        return this.get(ComuneModel.fields.cap);
    }

    public set cap(cap: string) {
        this.set(ComuneModel.fields.cap, cap);
    }

    public get ntn(): string {
        return this.get(ComuneModel.fields.ntn);
    }

    public set ntn(ntn: string) {
        this.set(ComuneModel.fields.ntn, ntn);
    }

    public get nome(): string {
        return this.get(ComuneModel.fields.nome);
    }

    public set nome(nome: string) {
        this.set(ComuneModel.fields.nome, nome);
    }

    public get regione(): string {
        return this.get(ComuneModel.fields.regione);
    }

    public set regione(regione: string) {
        this.set(ComuneModel.fields.regione, regione);
    }

    public get provincia(): string {
        return this.get(ComuneModel.fields.provincia);
    }

    public set provincia(provincia: string) {
        this.set(ComuneModel.fields.provincia, provincia);
    }

    public get coordinate(): Parse.GeoPoint {
        return this.get(ComuneModel.fields.coordinate);
    }

    public set coordinate(coordinate: Parse.GeoPoint) {
        this.set(ComuneModel.fields.coordinate, coordinate);
    }

    public get codiceIstat(): string {
        return this.get(ComuneModel.fields.codiceIstat);
    }

    public set codiceIstat(codiceIstat: string) {
        this.set(ComuneModel.fields.codiceIstat, codiceIstat);
    }

    public get codiceCatasto(): string {
        return this.get(ComuneModel.fields.codiceCatasto);
    }

    public set codiceCatasto(codiceCatasto: string) {
        this.set(ComuneModel.fields.codiceCatasto, codiceCatasto);
    }

    public get siglaProvincia(): string {
        return this.get(ComuneModel.fields.siglaProvincia);
    }

    public set siglaProvincia(siglaProvincia: string) {
        this.set(ComuneModel.fields.siglaProvincia, siglaProvincia);
    }

    public get codiceIstatRegione(): string {
        return this.get(ComuneModel.fields.codiceIstatRegione);
    }

    public set codiceIstatRegione(codiceIstatRegione: string) {
        this.set(ComuneModel.fields.codiceIstatRegione, codiceIstatRegione);
    }

    public get codiceIstatProvincia(): string {
        return this.get(ComuneModel.fields.codiceIstatProvincia);
    }

    public set codiceIstatProvincia(codiceIstatProvincia: string) {
        this.set(ComuneModel.fields.codiceIstatProvincia, codiceIstatProvincia);
    }
}

