import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { portalStateActions } from 'src/redux/slices/portalSlice';
import * as Parse from 'parse';

/**
 * Hanlde function call returning appropriate error message to display in the component
 * @param error the catched error object
 * @param dispatch useDispatch hook
 * @param defaultErrorMessage in case of unmanaged error type, function returns the default error message provided here. If not provided a standard error message is returned
 */
const DEFAULT_MSG = 'Errore: aggiornmento non riuscito!';
const OBJECT_NOT_FOUND_ERROR_MSG = 'Errore: la vecchia password non coincide.';
const SESSION_EXPIRED_TITLE = 'Attenzione! La tua sessione è scaduta';
const SESSION_EXPIRED_DESC = "Ti preghiamo di effettuare nuovamente l'accesso.";
const USERNAME_TAKEN_ERROR_MSG = 'Impossibile creare il partner, mail già utilizzata!'

export function handleParseError(
  error: any,
  dispatch: Dispatch<AnyAction>,
  defaultErrorMessage?: string
): string {
  let errorMessage = defaultErrorMessage ? defaultErrorMessage : DEFAULT_MSG;
  console.error(error);
  if (error instanceof Parse.Error) {
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        dispatch(
          portalStateActions.showPortal({
            title: SESSION_EXPIRED_TITLE,
            description: SESSION_EXPIRED_DESC,
          })
        );
        break;
      case Parse.Error.OBJECT_NOT_FOUND:
        errorMessage = OBJECT_NOT_FOUND_ERROR_MSG;
        break;
      case Parse.Error.USERNAME_TAKEN:
          errorMessage = USERNAME_TAKEN_ERROR_MSG;
        break;
      default:
        errorMessage = error.message;
    }
  }
  return errorMessage;
}
