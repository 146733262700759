import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ZonaCompetenzaValore } from 'src/parse/models/interfaces/zone-competenza.interface';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Divider,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Stack,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// hooks
import useTabs from '../hooks/useTabs';
import useSettings from '../hooks/useSettings';
import useTable from '../hooks/useTable';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { TableNoData, TableEmptyRows, TableHeadCustom, getComparator, emptyRows, TableColumnDefinition } from '../components/table';
// sections
import { UserTableToolbar, UserTableRow } from '../sections/@dashboard/partners/list';
import { UserService } from '../parse/services/user.service';
import { StatusUser } from 'src/parse/models/interfaces/status-user.enum';
import { ListPartnerItem } from 'src/@types/partner';
import SkeletonPartners from 'src/components/skeleton/SkeletonPartnersTable';
import { UserModel } from 'src/parse/models/user.model';
import PartnersDownload from 'src/sections/@dashboard/partners/PartnerDownload';
import SubscriptionsDownload from 'src/sections/@dashboard/partners/SubscriptionDownload';
import STATUS_OPTIONS, { STATUS_OPTION_ACTIVE, STATUS_OPTION_ALL, STATUS_OPTION_INACTIVE, TabStatusType } from './common/TabNames';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nomeCognome', label: 'Cognome Nome', align: 'left' },
  { id: '' },
  { id: 'email', label: 'E-mail', align: 'left' },
  { id: 'telefono', label: 'Telefono', align: 'left' },
  { id: 'zoneCompetenza', label: 'Zone competenza', align: 'center' },
  { id: 'numeroVivacizzazioni', label: 'N. Vivacizz.', align: 'center' },
  { id: 'guadagnoTotale', label: 'Guadagno', align: 'center' },
  { id: 'status', label: 'Stato', align: 'center' },
] as TableColumnDefinition[];

// ----------------------------------------------------------------------

interface Props {
  enableAddPartner?: boolean;
  partnerIds?: string[];
  defaultTab?: TabStatusType;
}

export default function Partners({ enableAddPartner, partnerIds, defaultTab }: Props) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const [loading, setLoading] = useState(true);

  const [tableData, setTableData] = useState<ListPartnerItem[]>([]);

  const [filterUser, setFilterUser] = useState('');

  const [filterOwner, setFilterOwner] = useState(false);

  const [filterUnassigned, setFilterUnassigned] = useState(false);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus, setCurrentTab } = useTabs(defaultTab ? defaultTab : STATUS_OPTION_ACTIVE);

  const currentUser = UserModel.current() as UserModel;

  useEffect(() => {
    setCurrentTab(defaultTab ? defaultTab : STATUS_OPTION_ACTIVE)
  }, [defaultTab, setCurrentTab]);

  useEffect(() => {
    const fetchPartners = async () => {
      setLoading(true);
      const partners = partnerIds ? await UserService.getPartnersByIds(partnerIds) : await UserService.getPartners();
      const listPartners = partners.map(
        (partner) =>
          ({
            user: partner,
            nomeCognome: `${partner.anagrafica.nome} ${partner.anagrafica.cognome}`,
            nome: partner.anagrafica.nome,
            cognome: partner.anagrafica.cognome,
            email: partner.email,
            telefono: partner.anagrafica.telefono,
            zoneCompetenza: partner.zoneCompetenza,
            numeroVivacizzazioni: partner.infoVivacizzazioni?.numeroVivacizzazioni,
            guadagnoTotale: partner.infoVivacizzazioni?.guadagnoTotale,
            status: partner.status,
            tipologiaPartner: partner.tipologiaPartner,
            referente: partner.referente,
            dataCreazione: partner.createdAt,
          } as ListPartnerItem)
      );
      setTableData(listPartners);
      setLoading(false);
    };

    fetchPartners().catch(console.error);
  }, [partnerIds]);

  const handleFilterUser = (filterUser: string) => {
    setFilterUser(filterUser);
    setPage(0);
  };

  const handlerFilterOwner = (filterOwner: boolean) => {
    setFilterOwner(filterOwner);
    setPage(0);
  };

  const handlerFilterUnassigned = (filterUnassigned: boolean) => {
    setFilterUnassigned(filterUnassigned);
    setPage(0);
  };

  const handleDetailUserClick = (id: string) => {
    window.open(PATH_DASHBOARD.partners.edit(id), '_blank');
  };

  const handleAuctionListClick = (id: string) => {
    window.open(PATH_DASHBOARD.immobili.viewByUser(id), '_blank');
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterUser,
    filterStatus,
    filterOwner,
    filterUnassigned,
    currentUser,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterUser) || (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Partners">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Partners"
          links={[{ name: 'Partners', href: PATH_DASHBOARD.user.root }]}
          action={
            <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
                <PartnersDownload partners={dataFiltered} />
                <Stack spacing={8}  direction={'row'}>
              {(enableAddPartner === undefined || enableAddPartner === true) && (<Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.partners.new}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                Nuovo partner
              </Button> )}


              <SubscriptionsDownload/>
            </Stack>
            </Stack>
            
          }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <UserTableToolbar
            filterUser={filterUser}
            filterOwner={filterOwner}
            filterUnassigned={filterUnassigned}
            onFilterUser={handleFilterUser}
            onFilterOwner={handlerFilterOwner}
            onFilterUnassigned={handlerFilterUnassigned}
          />

          <Scrollbar>
            {loading ? (
              <SkeletonPartners />
            ) : (
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    tableColumns={TABLE_HEAD}
                    rowCount={tableData.length}
                    onSort={onSort}
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                        <UserTableRow
                          key={row.user.id}
                          row={row}
                          onDetailUserClick={() => handleDetailUserClick(row.user.id)}
                          onAuctionListClick={() => handleAuctionListClick(row.user.id)}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelDisplayedRows={(page) =>
                `${page.from}-${page.to === -1 ? page.count : page.to} di ${page.count}`
              }
              labelRowsPerPage="Righe per pagina"
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Compatto"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterUser,
  filterStatus,
  filterOwner,
  filterUnassigned,
  currentUser,
}: {
  tableData: ListPartnerItem[];
  comparator: (a: any, b: any) => number;
  filterUser: string;
  filterStatus: string;
  filterOwner: boolean;
  filterUnassigned: boolean;
  currentUser: UserModel;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterUser) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.nomeCognome?.toLowerCase().indexOf(filterUser.toLowerCase()) > -1 ||
        item.email?.toLowerCase().indexOf(filterUser.toLowerCase()) > -1 ||
        item.comune?.toLowerCase().indexOf(filterUser.toLowerCase()) > -1 ||
        item.zoneCompetenza?.province?.filter(
          (zona: ZonaCompetenzaValore) =>
            zona.codice?.toLowerCase().indexOf(filterUser.toLowerCase()) > -1
        )?.length > 0 ||
        item.numeroVivacizzazioni?.toString().indexOf(filterUser.toLowerCase()) > -1 ||
        item.guadagno?.toString().indexOf(filterUser.toLowerCase()) > -1 ||
        item.tipologiaPartner?.toLowerCase().indexOf(filterUser.toLowerCase()) > -1
    );
  }

  if (filterStatus !== STATUS_OPTION_ALL) {
    let mappedFilterStatus = '';
    switch (filterStatus) {
      case STATUS_OPTION_ACTIVE:
        mappedFilterStatus = StatusUser.attivo;
        break;
      case STATUS_OPTION_INACTIVE:
        mappedFilterStatus = StatusUser.inattivo;
        break;
    }
    tableData = tableData.filter((item: ListPartnerItem) => item.status === mappedFilterStatus);
  }

  if (filterOwner) {
    tableData = tableData.filter((item: ListPartnerItem) =>
      item.referente ? item.referente.id === currentUser.id : false
    );
  }

  if (filterUnassigned) {
    tableData = tableData.filter((item: ListPartnerItem) => (item.referente ? false : true));
  }
  return tableData;
}
