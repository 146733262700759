import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import auctionSlice from './slices/auctionSlice';
import uiSlice from './slices/uiSlice';
import globalSlice from './slices/globalSlice';
import staticDataSlice from './slices/staticDataSlice';
import partnerSlice from './slices/partnerSlice';
import notificationSlice from './slices/notificationSlice';
import portalSlice from './slices/portalSlice';
import kpiSlice from './slices/kpiSlice';
import resourceSlice from './slices/resourceSlice';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: {
    ui: uiSlice,
    global: globalSlice,
    staticData: staticDataSlice,
    auction: auctionSlice,
    partner: partnerSlice,
    notification: notificationSlice,
    portal: portalSlice,
    kpi: kpiSlice,
    resource: resourceSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>

export default store;

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
