import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RisorsaModel } from 'src/parse/models/risorsa.model';

export interface IResourceState {
  layout: 'grid' | 'list';
  dataAvailable: boolean;
  resources: RisorsaModel[];
}

const initialState: IResourceState = {
  layout: 'list',
  dataAvailable: false,
  resources: [],
};

const resourceSlice = createSlice({
  name: 'resource',
  initialState: initialState,
  reducers: {
    setLayout(state, action: PayloadAction<'grid' | 'list'>) {
      state.layout = action.payload;
    },
    setResources(state, action: PayloadAction<RisorsaModel[]>) {
      state.resources = action.payload;
      state.dataAvailable = true;
    },
  },
});

export const resourceStateActions = resourceSlice.actions;

export default resourceSlice.reducer;
