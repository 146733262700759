// @mui
import { Typography, BoxProps, Stack, Chip, Tooltip, Avatar } from '@mui/material';
import { ZoneCompetenza } from '../../../parse/models/interfaces/zone-competenza.interface';

export enum ModePartnerZoneCompetenzaPreview {
    limited = "limited",
    full = "full"
}

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    zoneCompetenza: ZoneCompetenza;
    mode: ModePartnerZoneCompetenzaPreview;
    limit?: number;
}

export default function PartnerZoneCompetenzaPreview({ zoneCompetenza, mode = ModePartnerZoneCompetenzaPreview.limited, limit = 3, sx }: Props) {

    const { province } = zoneCompetenza;

    const showProvince = province.slice(0, limit);
    const moreProvince = province.slice(limit, province.length);
    const moreProvinceSigle = moreProvince.map(provincia => provincia.codice);

    return (
        <>
            {mode === ModePartnerZoneCompetenzaPreview.limited &&
                (
                    <Stack direction="row" spacing={0.2}>
                        {showProvince.map((provincia) => (
                            <Chip key={provincia.codice} label={provincia.codice} color="primary" size="small" />
                        ))}
                        {province.length > limit &&
                            <Tooltip title={moreProvinceSigle.join(", ")} arrow sx={{ cursor: "pointer" }}>
                                <Typography variant="subtitle2">{`+${moreProvince.length}`}</Typography>
                            </Tooltip>
                        }
                    </Stack>
                )
            }
            {mode === ModePartnerZoneCompetenzaPreview.full &&
                (
                    <>
                        {
                            province.map((provincia) => (
                                <Chip key={provincia.codice} avatar={<Avatar>{provincia.codice}</Avatar>} label={provincia.valore} color="primary" sx={{ m: 0.2 }} />
                            ))
                        }
                    </>
                )
            }
        </>
    );
}
