import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import Label from 'src/components/Label';

export type IResourceNewProps = {
  isNew: boolean;
  sx?: SxProps<Theme>;
};

const ResourceNew: React.FC<IResourceNewProps> = ({ isNew, sx }) => (
  <>
    {isNew && (
      <Label
        sx={{ marginRight: 2, borderStyle: 'solid', borderWidth: 'thin', ...sx }}
        variant="ghost"
        color={'warning'}
      >
        New
      </Label>
    )}
  </>
);

export default ResourceNew;
