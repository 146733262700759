import * as Parse from 'parse';
import { IPartnerSubscription } from '../models/interfaces/subscription.interface';

export class SubscriptionService {
  public static async getSubscription(
    month: number,
    year: number
  ): Promise<IPartnerSubscription[]> {
    const response = (await Parse.Cloud.run('extractSubscriptions', {
      month: month,
      year: year,
    })) as IPartnerSubscription[];
    return response;
  }
}
