import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AstaModel } from 'src/parse/models/asta.model';

export interface IAuctionState {
  auctions: AstaModel[];
}

const initialState: IAuctionState = {
  auctions: [],
};

const auctionSlice = createSlice({
  name: 'auction',
  initialState: initialState,
  reducers: {
    setAuctions(state, action: PayloadAction<AstaModel[]>) {
      state.auctions = action.payload;
    },
  },
});


export const auctionStateActions = auctionSlice.actions;

export default auctionSlice.reducer;
