// eslint-disable-next-line import/no-duplicates
import { format, getTime, formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { it } from "date-fns/locale";

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateIt(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy', { locale: it });
}

export function formatDateAndTimeIt(date: Date | string | number) {
  return format(new Date(date), "dd/MM/yyyy HH:mm", { locale: it });
}



export function fDateExt(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true, locale: it
  });
}
