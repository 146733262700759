import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificaModel } from 'src/parse/models/notifica.model';

export interface INotificationState {
  notifications: NotificaModel[];
}

const initialState: INotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<NotificaModel[]>) {
      state.notifications = action.payload;
    },
  },
});


export const notificationStateActions = notificationSlice.actions;

export default notificationSlice.reducer;
