import * as Parse from 'parse';

export class PartecipazioniFilesModel extends Parse.Object {
  public static className = 'PartecipazioniFiles';

  public static fields = {
    objectId: 'objectId',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    ACL: 'ACL',
    file: 'file',
    fileName: 'fileName',
    tagIds: 'tagIds',
  };

  constructor() {
    super(PartecipazioniFilesModel.className);
  }

  public static registerParseSubclass(): void {
    Parse.Object.registerSubclass(PartecipazioniFilesModel.className, PartecipazioniFilesModel);
  }

  public get objectId(): string {
    return this.get(PartecipazioniFilesModel.fields.objectId);
  }

  public get createdAt(): Date {
    return this.get(PartecipazioniFilesModel.fields.createdAt);
  }

  public get updatedAt(): Date {
    return this.get(PartecipazioniFilesModel.fields.updatedAt);
  }

  public get ACL(): Parse.ACL {
    return this.get(PartecipazioniFilesModel.fields.ACL);
  }

  public set ACL(ACL: Parse.ACL) {
    this.set(PartecipazioniFilesModel.fields.ACL, ACL);
  }

  public get file(): Parse.File {
    return this.get(PartecipazioniFilesModel.fields.file);
  }

  public set file(file: Parse.File) {
    this.set(PartecipazioniFilesModel.fields.file, file);
  }

  public get fileName(): string {
    return this.get(PartecipazioniFilesModel.fields.fileName);
  }

  public set fileName(valore: string) {
    this.set(PartecipazioniFilesModel.fields.fileName, valore);
  }

  public get tagIds(): string[] {
    return this.get(PartecipazioniFilesModel.fields.tagIds);
  }

  public set tagIds(vivacizzanti: string[]) {
    this.set(PartecipazioniFilesModel.fields.tagIds, vivacizzanti);
  }
}
