import * as Parse from 'parse';

import { ICaratteristicheImmobile } from './interfaces/caratteristiche-immobile.interface';
import { ComuneModel } from "./comune.model";
import { ServicerModel } from "./servicer.model";
import { TipologiaImmobileModel } from "./tipologia-immobile.model";
import { IDatiCustodeAsta } from './interfaces/dati-custode-asta.interface';
import { IDatiDelegatoAsta } from './interfaces/dati-delegato-asta.interface';
import { IAstaDocumento } from './interfaces/asta-documento';

export class AstaModel extends Parse.Object {
    
    public static className = "Aste";

    public static fields = {
       objectId: "objectId",
       createdAt: "createdAt",
       updatedAt: "updatedAt",
       ACL: "ACL",
       idReviva: "idReviva",
       idAffidamentoReviva: "idAffidamentoReviva",
       rge: "rge",
       rito: "rito",
       esito: "esito",
       lotto: "lotto",
       oraAsta: "oraAsta",
       dataAsta: "dataAsta",
       st: "st",
       indirizzo: "indirizzo",
       comune: "comune",
       caratteristicheImmobile: "caratteristicheImmobile",
       gallery: "gallery",
       tipologia: "tipologia",
       servicer: "servicer",
       documenti: "documenti",
       prezzoBase: "prezzoBase",
       datiCustode: "datiCustode",
       tipoVendita: "tipoVendita",
       datiDelegato: "datiDelegato",
       linkTribunale: "linkTribunale",
       offertaMinima: "offertaMinima",
       rialzoMinimo: "rialzoMinimo",
       comuneTribunale: "comuneTribunale",
       provinciaTribunale: "provinciaTribunale",
       dataAggiudicazione: "dataAggiudicazione",
       riferimentoServicer: "riferimentoServicer",
       importoAggiudicazione: "importoAggiudicazione",
       oraTerminePresentazioneOfferta: "oraTerminePresentazioneOfferta",
       dataTerminePresentazioneOfferta: "dataTerminePresentazioneOfferta",
       archiviata: "archiviata",
       novita: "novita",
       dataUltimoImport: "dataUltimoImport",
       probabilitaPre: "probabilitaPre",
       probabilitaPost: "probabilitaPost",
       valutazione: "valutazione",
       vivacizzanti: "vivacizzanti",
       coordinate: "coordinate"
   };
    
    constructor() {
        super(AstaModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(AstaModel.className, AstaModel);
    }

    public get objectId(): string {
        return this.get(AstaModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(AstaModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(AstaModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(AstaModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(AstaModel.fields.ACL, ACL);
    }

    public get idReviva(): string {
        return this.get(AstaModel.fields.idReviva);
    }

    public set idReviva(idReviva: string) {
        this.set(AstaModel.fields.idReviva, idReviva);
    }

    public get idAffidamentoReviva(): string {
        return this.get(AstaModel.fields.idAffidamentoReviva);
    }

    public set idAffidamentoReviva(idAffidamentoReviva: string) {
        this.set(AstaModel.fields.idAffidamentoReviva, idAffidamentoReviva);
    }

    public get rge(): string {
        return this.get(AstaModel.fields.rge);
    }

    public set rge(rge: string) {
        this.set(AstaModel.fields.rge, rge);
    }

    public get rito(): string {
        return this.get(AstaModel.fields.rito);
    }

    public set rito(rito: string) {
        this.set(AstaModel.fields.rito, rito);
    }

    public get esito(): string {
        return this.get(AstaModel.fields.esito);
    }

    public set esito(esito: string) {
        this.set(AstaModel.fields.esito, esito);
    }

    public get lotto(): string {
        return this.get(AstaModel.fields.lotto);
    }

    public set lotto(lotto: string) {
        this.set(AstaModel.fields.lotto, lotto);
    }

    public get oraAsta(): string {
        return this.get(AstaModel.fields.oraAsta);
    }

    public set oraAsta(oraAsta: string) {
        this.set(AstaModel.fields.oraAsta, oraAsta);
    }

    public get dataAsta(): Date {
        return this.get(AstaModel.fields.dataAsta);
    }

    public set dataAsta(dataAsta: Date) {
        this.set(AstaModel.fields.dataAsta, dataAsta);
    }

    public get st(): string {
        return this.get(AstaModel.fields.st);
    }

    public set st(st: string) {
        this.set(AstaModel.fields.st, st);
    }

    public get comune(): ComuneModel {
        return this.get(AstaModel.fields.comune);
    }

    public set comune(comune: ComuneModel) {
        this.set(AstaModel.fields.comune, comune);
    }

    public get gallery(): any[] {
        return this.get(AstaModel.fields.gallery);
    }

    public set gallery(gallery: any[]) {
        this.set(AstaModel.fields.gallery, gallery);
    }

    public get indirizzo(): string {
        return this.get(AstaModel.fields.indirizzo);
    }

    public set indirizzo(indirizzo: string) {
        this.set(AstaModel.fields.indirizzo, indirizzo);
    }

    public get tipologia(): TipologiaImmobileModel {
        return this.get(AstaModel.fields.tipologia);
    }

    public set tipologia(tipologia: TipologiaImmobileModel) {
        this.set(AstaModel.fields.tipologia, tipologia);
    }

    public get caratteristicheImmobile(): ICaratteristicheImmobile {
        return this.get(AstaModel.fields.caratteristicheImmobile);
    }

    public set caratteristicheImmobile(caratteristicheImmobile: ICaratteristicheImmobile) {
        this.set(AstaModel.fields.caratteristicheImmobile, caratteristicheImmobile);
    }

    public get servicer(): ServicerModel {
        return this.get(AstaModel.fields.servicer);
    }

    public set servicer(servicer: ServicerModel) {
        this.set(AstaModel.fields.servicer, servicer);
    }

    public get documenti(): IAstaDocumento[] {
        return this.get(AstaModel.fields.documenti);
    }

    public set documenti(documenti: IAstaDocumento[]) {
        this.set(AstaModel.fields.documenti, documenti);
    }

    public get prezzoBase(): number {
        return this.get(AstaModel.fields.prezzoBase);
    }

    public set prezzoBase(prezzoBase: number) {
        this.set(AstaModel.fields.prezzoBase, prezzoBase);
    }

    public get datiCustode(): IDatiCustodeAsta {
        return this.get(AstaModel.fields.datiCustode);
    }

    public set datiCustode(datiCustode: IDatiCustodeAsta) {
        this.set(AstaModel.fields.datiCustode, datiCustode);
    }

    public get tipoVendita(): string {
        return this.get(AstaModel.fields.tipoVendita);
    }

    public set tipoVendita(tipoVendita: string) {
        this.set(AstaModel.fields.tipoVendita, tipoVendita);
    }

    public get datiDelegato(): IDatiDelegatoAsta {
        return this.get(AstaModel.fields.datiDelegato);
    }

    public set datiDelegato(datiDelegato: IDatiDelegatoAsta) {
        this.set(AstaModel.fields.datiDelegato, datiDelegato);
    }

    public get linkTribunale(): string {
        return this.get(AstaModel.fields.linkTribunale);
    }

    public set linkTribunale(linkTribunale: string) {
        this.set(AstaModel.fields.linkTribunale, linkTribunale);
    }

    public get offertaMinima(): number {
        return this.get(AstaModel.fields.offertaMinima);
    }

    public set offertaMinima(offertaMinima: number) {
        this.set(AstaModel.fields.offertaMinima, offertaMinima);
    }

    public get rialzoMinimo(): number {
        return this.get(AstaModel.fields.rialzoMinimo);
    }

    public set rialzoMinimo(rialzoMinimo: number) {
        this.set(AstaModel.fields.rialzoMinimo, rialzoMinimo);
    }

    public get comuneTribunale(): string {
        return this.get(AstaModel.fields.comuneTribunale);
    }

    public set comuneTribunale(comuneTribunale: string) {
        this.set(AstaModel.fields.comuneTribunale, comuneTribunale);
    }

    public get provinciaTribunale(): string {
        return this.get(AstaModel.fields.provinciaTribunale);
    }

    public set provinciaTribunale(provinciaTribunale: string) {
        this.set(AstaModel.fields.provinciaTribunale, provinciaTribunale);
    }

    public get dataAggiudicazione(): Date {
        return this.get(AstaModel.fields.dataAggiudicazione);
    }

    public set dataAggiudicazione(dataAggiudicazione: Date) {
        this.set(AstaModel.fields.dataAggiudicazione, dataAggiudicazione);
    }

    public get riferimentoServicer(): string {
        return this.get(AstaModel.fields.riferimentoServicer);
    }

    public set riferimentoServicer(riferimentoServicer: string) {
        this.set(AstaModel.fields.riferimentoServicer, riferimentoServicer);
    }

    public get importoAggiudicazione(): number {
        return this.get(AstaModel.fields.importoAggiudicazione);
    }

    public set importoAggiudicazione(importoAggiudicazione: number) {
        this.set(AstaModel.fields.importoAggiudicazione, importoAggiudicazione);
    }

    public get oraTerminePresentazioneOfferta(): string {
        return this.get(AstaModel.fields.oraTerminePresentazioneOfferta);
    }

    public set oraTerminePresentazioneOfferta(oraTerminePresentazioneOfferta: string) {
        this.set(AstaModel.fields.oraTerminePresentazioneOfferta, oraTerminePresentazioneOfferta);
    }

    public get dataTerminePresentazioneOfferta(): Date {
        return this.get(AstaModel.fields.dataTerminePresentazioneOfferta);
    }

    public set dataTerminePresentazioneOfferta(dataTerminePresentazioneOfferta: Date) {
        this.set(AstaModel.fields.dataTerminePresentazioneOfferta, dataTerminePresentazioneOfferta);
    }

    public get archiviata(): boolean {
        return this.get(AstaModel.fields.archiviata);
    }

    public set archiviata(archiviata: boolean) {
        this.set(AstaModel.fields.archiviata, archiviata);
    }

    public get novita(): boolean {
        return this.get(AstaModel.fields.novita);
    }

    public set novita(novita: boolean) {
        this.set(AstaModel.fields.novita, novita);
    }

    public get dataUltimoImport(): Date {
        return this.get(AstaModel.fields.dataUltimoImport);
    }

    public set dataUltimoImport(dataUltimoImport: Date) {
        this.set(AstaModel.fields.dataUltimoImport, dataUltimoImport);
    }

    public get probabilitaPre(): number {
        return this.get(AstaModel.fields.probabilitaPre);
    }

    public set probabilitaPre(probabilitaPre: number) {
        this.set(AstaModel.fields.probabilitaPre, probabilitaPre);
    }

    public get probabilitaPost(): number {
        return this.get(AstaModel.fields.probabilitaPost);
    }

    public set probabilitaPost(probabilitaPost: number) {
        this.set(AstaModel.fields.probabilitaPost, probabilitaPost);
    }

    public get valutazione(): number {
        return this.get(AstaModel.fields.valutazione);
    }

    public set valutazione(valutazione: number) {
        this.set(AstaModel.fields.valutazione, valutazione);
    }

    public get vivacizzanti(): string[] {
        return this.get(AstaModel.fields.vivacizzanti);
    }

    public set vivacizzanti(vivacizzanti: string[]) {
        this.set(AstaModel.fields.vivacizzanti, vivacizzanti);
    }

    public get coordinate(): Parse.GeoPoint {
        return this.get(AstaModel.fields.coordinate);
    }

    public set coordinate(coordinate: Parse.GeoPoint) {
        this.set(AstaModel.fields.coordinate, coordinate);
    }
}
