import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { List, Collapse, Link } from '@mui/material';
//
import { NavListProps } from '../type';
import NavItem from './NavItem';
import { getActive, isExternalLink } from '..';
import { AuctionLogger } from 'src/utils/loggerManager';
import { useDispatch } from 'react-redux';
import { kpiStateActions } from 'src/redux/slices/kpiSlice';
import { VerticalNavItemFactory } from './factory';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChildren: boolean;
  isCollapse?: boolean;
  dataTestId: string;
};

export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false,
  dataTestId,
}: NavListRootProps) {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const active = getActive(data.path, pathname);

  const [open, setOpen] = useState(active);

  const handleClickItem = () => {
    AuctionLogger('Generic', 'UI Action', `Navigate on ${data.title} to path ${data.path}`);
    if (!hasChildren) {
      navigate(data.path);
    }
    dispatch(kpiStateActions.reset());
    setOpen(!open);
  };


  return (
    <>
      {isExternalLink(data.path) ? (
        <Link href={data.path} target="_blank" rel="noopener" underline="none">
          <NavItem item={data} depth={depth} open={open} active={active} isCollapse={isCollapse} />
        </Link>
      ) : (
        VerticalNavItemFactory(data, depth, open, active, dataTestId, handleClickItem, isCollapse)
      )}

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={!!list.children}
          dataTestId={list.dataTestId}
        />
      ))}
    </>
  );
}
