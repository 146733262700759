export enum KPIMetricheEnum {
  // kpi vivacizzatori
  vivacizzatori = 'vivacizzatori',
  vivacizzatoriConGuadagni = 'vivacizzatoriConGuadagni',
  vivacizzazioniSuVivacizzatori = 'vivacizzazioniSuVivacizzatori',
  vivacizzantiSuVivacizzatori = 'vivacizzantiSuVivacizzatori',

  // kpi vivacizzazioni
  totaleVivacizzazioni = 'totaleVivacizzazioni',
  totaleProvvigioniRevivaPotenziali = 'totaleProvvigioniRevivaPotenziali',
  totaleProvvigioniRevivaMaturato = 'totaleProvvigioniRevivaMaturato',

  // kpi aste
  asteTotali = 'asteTotali',
  asteProvinceCoperte = 'asteProvinceCoperte',
  asteAttiveProvinceCoperte = 'asteAttiveProvinceCoperte',
  asteMultiVivacizzate = 'asteMultiVivacizzate',

  // kpi province
  provinceConVivacizzatori = 'provinceConVivacizzatori',
  provinceSenzaVivacizzatori = 'provinceSenzaVivacizzatori',
  provinceSenzaAsteConVivacizzatori = 'provinceSenzaAsteConVivacizzatori',
  provinceVivacizzabili = 'provinceVivacizzabili',
  percentualeProvinceSenzaVivacizzatori = 'percentualeProvinceSenzaVivacizzatori',
  provinceVivacizzate = 'provinceVivacizzate',

  // kpi rates
  percentualeVivacizzatoriSenzaAste = 'percentualeVivacizzatoriSenzaAste',
  provinceVivacizzateSuVivacizzabili = 'provinceVivacizzateSuVivacizzabili',
  astePerProvinceVivacizzabili = 'astePerProvinceVivacizzabili',
  vivacizzazioniSuAste = 'vivacizzazioniSuAste',

  //kpi vivacizzatori, da confermare con enum pubbklicato dal web service
  // -> sezione "GESTIONE VIVACIZZATORI"
  vivacizzatoriAttiviMese = 'vivacizzatoriAttiviMese',
  nuoviVivacizzatori = 'nuoviVivacizzatori',
  totaleRecessi = 'totaleRecessi',
  // -> sezione MONITORAGGIO VIVACIZZATORI
  totaleRicaviAbbonamenti = 'totaleRicaviAbbonamenti',
  vivacizzatoriRientrati = 'vivacizzatoriRientrati',
  vivacizzatoriConAste = 'vivacizzatoriConAste',
  vivacizzatoriSenzaAste = 'vivacizzatoriSenzaAste',
  vivacizzatoriMaiVivacizzato = 'vivacizzatoriMaiVivacizzato',
  vivacizzatoriSenzaGuadagno = 'vivacizzatoriSenzaGuadagno',
  vivacizzatoriConAsteSenzaGuadagno = 'vivacizzatoriConAsteSenzaGuadagno',
  vivacizzatoriSenzaAsteSuTotale = 'vivacizzatoriSenzaAsteSuTotale',
  vivacizzatoriConAsteSenzaGuadagnoSuTotale = 'vivacizzatoriConAsteSenzaGuadagnoSuTotale',
  vivacizzazioniSenzaGuadagnoSuVivacizzatoriConAste = 'vivacizzazioniSenzaGuadagnoSuVivacizzatoriConAste',
  // -> sezione "DISTRIBUZIONE VIVACIZZATORI/ASTE"
  vivacizzatoriAgentiConAste = 'vivacizzatoriAgentiConAste',
  agentiConAsteSenzaGuadagno = 'agentiConAsteSenzaGuadagno',
  vivacizzatoriConsulentiConAste = 'vivacizzatoriConsulentiConAste',
  consulentiConAsteSenzaGuadagno = 'consulentiConAsteSenzaGuadagno',
  asteSenzaVivacizzatori = 'asteSenzaVivacizzatori',
  // -> sezione "TIPOLOGIA VIVACIZZATORI"
  vivacizzatoriAgenti = 'vivacizzatoriAgenti',
  vivacizzatoriConsulenti = 'vivacizzatoriConsulenti',
  agentiSuVivacizzatori = 'agentiSuVivacizzatori',
  consulentiSuVivacizzatori = 'consulentiSuVivacizzatori',
  // -> sezione "VIVACIZZAZIONI"
  vivacizzazioniDaAgenti = 'vivacizzazioniDaAgenti',
  vivacizzazioniDaConsulenti = 'vivacizzazioniDaConsulenti',
    vivacizzazioniDaAgentiSuTotali = 'vivacizzazioniDaAgentiSuTotali',
  vivacizzazioniDaConsulentiSuTotali = 'vivacizzazioniDaConsulentiSuTotali',

  // kpi vivacizzatori
  vivacizzatoreAsteMese = 'vivacizzatoreAsteMese',
  aggiudicateProvinceVivacizzatore = 'aggiudicateProvinceVivacizzatore',
  vivacizzatoreVivacizzazioni = 'vivacizzatoreVivacizzazioni',
  vivacizzatorePotenzialeGuadagno = 'vivacizzatorePotenzialeGuadagno',
  vivacizzatoreGuadagni = 'vivacizzatoreGuadagni',
}
