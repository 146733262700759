import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';

import Login from 'src/pages/auth/Login';
import UserAccount from 'src/pages/UserAccount';
import Partners from 'src/pages/Partners';
import Servicers from 'src/pages/Servicers';
import PartnerEdit from 'src/pages/PartnerEdit';
import ServicerEdit from 'src/pages/ServicerEdit';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { UserRoles } from 'src/config';
import Notifications from 'src/pages/Notifications';
import NotificationsManager from 'src/sections/@dashboard/notifications/NotificationManager';
import useAuth from 'src/hooks/useAuth';
import { PATH_DASHBOARD } from './paths';
import MediaResourcesManager from 'src/sections/@dashboard/resources/MediaResourcesManager';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { isOperation } = useAuth();
  const defaultPath = () => {
    if (isOperation) {
      return PATH_DASHBOARD.statistics.root;
    } else {
      return PATH_DASHBOARD.immobili.root;
    }
  };

  return useRoutes([
    {
      path: 'auth',
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MediaResourcesManager>
            <NotificationsManager>
              <DashboardLayout />
            </NotificationsManager>
          </MediaResourcesManager>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={`${defaultPath()}`} replace />, index: true },
        { path: 'dashboard', element: <Navigate to={`${defaultPath()}`} replace /> },
        {
          path: 'immobili',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN, UserRoles.PARTNER]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { element: <Navigate to="/dashboard/immobili" replace />, index: true },
            { path: 'lista/:partnerId', element: <Immobili /> },
            { path: 'lista', element: <Immobili /> },
            { path: 'annuncio/:auctionId', element: <AuctionDetails /> },
          ],
        },
        {
          path: 'partners',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { element: <Partners />, index: true },
            { path: 'new', element: <PartnerEdit /> },
            { path: ':id/edit', element: <PartnerEdit /> },
          ],
        },
        {
          path: 'servicers',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { element: <Servicers />, index: true },
            { path: ':id/edit', element: <ServicerEdit /> },
          ],
        },
        {
          path: 'utente',
          children: [
            { element: <Navigate to="/utente/profilo" replace />, index: true },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'notifications',
          children: [{ element: <Notifications />, index: true }],
        },
        {
          path: 'preferiti',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [{ element: <FavouritesTable />, index: true }],
        },
        {
          path: 'nominativi',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [{ element: <NamesTable />, index: true }],
        },
        {
          path: 'guadagni',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [{ element: <EarningsTable />, index: true }],
        },
        {
          path: 'stats',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN, UserRoles.OPERATION]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [{ element: <StatisticsForAdminPage />, index: true }],
        },
        {
          path: 'livenup',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.PARTNER]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [{ element: <StatisticsForUserPage />, index: true }],
        },
        {
          path: 'resources',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.PARTNER, UserRoles.ADMIN]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [{ element: <ResourcesPage />, index: true }],
        },
        {
          path: 'participations',
          element: (
            <RoleBasedGuard hasContent={true} roles={[UserRoles.ADMIN]}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [{ element: <ParticipationPage />, index: true }],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const Immobili = Loadable(lazy(() => import('../pages/Immobili')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const AuctionDetails = Loadable(
  lazy(() => import('../sections/@dashboard/lista/AuctionDetails/AuctionDetails'))
);
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));

const EarningsTable = Loadable(lazy(() => import('../pages/EarningsTable')));
const FavouritesTable = Loadable(lazy(() => import('../pages/FavouritesTable')));
const NamesTable = Loadable(lazy(() => import('../pages/NamesTable')));
const StatisticsForAdminPage = Loadable(lazy(() => import('../pages/StatisticsForAdmin')));
const StatisticsForUserPage = Loadable(lazy(() => import('../pages/StatisticsForUser')));
const ResourcesPage = Loadable(lazy(() => import('../pages/Resources')));
const ParticipationPage = Loadable(lazy(() => import('../pages/Participation')));
