import { useTheme } from '@mui/material/styles';
import { Typography, IconButton } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import Iconify from '../Iconify';

export default function ThemeChooser() {
  const { themeMode, onToggleMode } = useSettings();
  const theme = useTheme();

  return (
    <>
      <IconButton
        sx={{
          bgcolor: themeMode === 'light' ? 'common.white' : 'grey.800',
        }}
        onClick={onToggleMode}
        color="inherit"
      >
        <Iconify
          icon={themeMode === 'light' ? 'ph:moon-duotone' : 'ph:sun-duotone'}
          width={28}
          height={28}
          sx={{
            color: theme.palette.text.primary,
          }}
        />
      </IconButton>
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.text.primary,
        }}
      >
        {themeMode === 'light' ? 'Tema Scuro' : 'Tema Chiaro'}
      </Typography>
    </>
  );
}
