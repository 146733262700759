import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface IPortalState {
  visible: boolean;
  title: string;
  description: string;
}

const initialState: IPortalState = {
  visible: false,
  title: '',
  description: '',
};

const portalSlice = createSlice({
  name: 'portal',
  initialState: initialState,
  reducers: {
    showPortal(state, action: PayloadAction<{title: string, description: string}>) {
      state.visible = true;
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    hidePortal(state) {
      state.visible = false;
      state.title = '';
      state.description = '';
    },
  },
});

export const portalStateActions = portalSlice.actions;

export default portalSlice.reducer;
