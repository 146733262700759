import * as Parse from 'parse';
import { UserModel } from '../models/user.model'

export class ParseService {

    public static async login(username: string, password: string): Promise<UserModel> {
        const user = await UserModel.logIn(username, password) as UserModel
        return user
    }

    public static async checkSession(): Promise<Parse.Session> {
        const session = await Parse.Session.current()
        return session
    }

    public static handleParseError(err: any): void {
        console.error('PARSE Error');
        console.dir(err);
        switch (err.code) {
            case Parse.Error.INVALID_SESSION_TOKEN:
                // logout
                break;
            case Parse.Error.CONNECTION_FAILED:
                // no connection log
                break;
        }
        throw err;
    }

}
