import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { portalStateActions } from 'src/redux/slices/portalSlice';
import { RootState } from 'src/redux/store';
import { PATH_AUTH } from 'src/routes/paths';

export type IModalErrorDialogProps = {};

// eslint-disable-next-line no-empty-pattern
const ModalErrorDialog: React.FC<IModalErrorDialogProps> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const visible = useSelector<RootState, boolean>((state) => state.portal.visible);
  const title = useSelector<RootState, string>((state) => state.portal.title);
  const description = useSelector<RootState, string>((state) => state.portal.description);

  const handleClose = async () => {
    dispatch(portalStateActions.hidePortal());

    await logout();
    navigate(PATH_AUTH.login, { replace: true });
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Dialog
          open={visible}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              sx={{ minWidth: '100px' }}
              variant="contained"
              onClick={handleClose}
              autoFocus
            >
              Ok
            </Button>
            <Button
              color="success"
              sx={{ minWidth: '100px' }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>,
        document.getElementById('modal-root')!
      )}
    </>
  );
};

export default ModalErrorDialog;
