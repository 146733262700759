import { Box, Grid, Skeleton } from "@mui/material";

export default function SkeletonPartnersTable() {
  return (
    <Box
      sx={{
        height: "max-content",
        m: 1
      }}
    >
      {[...Array(5)].map((value, index) => (
        <Grid key={index} container spacing={2} alignItems="center" sx={{ my: "0.5" }}>
          <Grid item xs="auto">
            <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs={0.5}>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
        </Grid>
      ))
      }
    </Box >
  );
}
