import * as Parse from 'parse';

import { AstaModel } from "./asta.model";
import { AzioniGuadagno } from "./interfaces/azioni-guadagno.interface";
import { IStoricoTipologiaGuadagno } from "./interfaces/storico-tipologia-guadagno.interface";
import { UserModel } from "./user.model";

export class GuadagniVivacizzatoriModel extends Parse.Object {

    public static className = "GuadagniVivacizzatori";

    public static fields = {
        objectId: "objectId",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        ACL: "ACL",
        vivacizzatore: "vivacizzatore",
        asta: "asta",
        importoGuadagno: "importoGuadagno",
        storicoTipologiaGuadagno: "storicoTipologiaGuadagno",
        azioniGuadagno: "azioniGuadagno",
        nominativoPartecipante: "nominativoPartecipante",
        dataAsta: "dataAsta"
    };

    constructor() {
        super(GuadagniVivacizzatoriModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(GuadagniVivacizzatoriModel.className, GuadagniVivacizzatoriModel);
    }

    public get objectId(): string {
        return this.get(GuadagniVivacizzatoriModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(GuadagniVivacizzatoriModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(GuadagniVivacizzatoriModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(GuadagniVivacizzatoriModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(GuadagniVivacizzatoriModel.fields.ACL, ACL);
    }

    public get vivacizzatore(): UserModel {
        return this.get(GuadagniVivacizzatoriModel.fields.vivacizzatore);
    }

    public set vivacizzatore(vivacizzatore: UserModel) {
        this.set(GuadagniVivacizzatoriModel.fields.vivacizzatore, vivacizzatore);
    }

    public get asta(): AstaModel {
        return this.get(GuadagniVivacizzatoriModel.fields.asta);
    }

    public set asta(asta: AstaModel) {
        this.set(GuadagniVivacizzatoriModel.fields.asta, asta);
    }

    public get importoGuadagno(): number {
        return this.get(GuadagniVivacizzatoriModel.fields.importoGuadagno);
    }

    public set importoGuadagno(importoGuadagno: number) {
        this.set(GuadagniVivacizzatoriModel.fields.importoGuadagno, importoGuadagno);
    }

    public get storicoTipologiaGuadagno(): IStoricoTipologiaGuadagno {
        return this.get(GuadagniVivacizzatoriModel.fields.storicoTipologiaGuadagno);
    }

    public set storicoTipologiaGuadagno(storicoTipologiaGuadagno: IStoricoTipologiaGuadagno) {
        this.set(GuadagniVivacizzatoriModel.fields.storicoTipologiaGuadagno, storicoTipologiaGuadagno);
    }

    public get azioniGuadagno(): AzioniGuadagno {
        return this.get(GuadagniVivacizzatoriModel.fields.azioniGuadagno);
    }

    public set azioniGuadagno(azioniGuadagno: AzioniGuadagno) {
        this.set(GuadagniVivacizzatoriModel.fields.azioniGuadagno, azioniGuadagno);
    }

    public get nominativoPartecipante(): string {
        return this.get(GuadagniVivacizzatoriModel.fields.nominativoPartecipante);
    }

    public set nominativoPartecipante(nominativoPartecipante: string) {
        this.set(GuadagniVivacizzatoriModel.fields.nominativoPartecipante, nominativoPartecipante);
    }

    public get dataAsta(): Date {
        return this.get(GuadagniVivacizzatoriModel.fields.dataAsta);
    }

    public set dataAsta(dataAsta: Date) {
        this.set(GuadagniVivacizzatoriModel.fields.dataAsta, dataAsta);
    }

}