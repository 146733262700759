// @mui
import {
  Avatar,
  TableRow,
  TableCell,
  Link,
  Button,
  Typography,
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import { StatusUser } from '../../../../parse/models/interfaces/status-user.enum';
import { fCurrency } from '../../../../utils/formatNumber';
import { ListPartnerItem } from 'src/@types/partner';
import PartnerZoneCompetenzaPreview, {
  ModePartnerZoneCompetenzaPreview,
} from '../PartnerZoneCompetenzaPreview';

// ----------------------------------------------------------------------

type Props = {
  row: ListPartnerItem;
  onAuctionListClick: VoidFunction;
  onDetailUserClick: VoidFunction;
};

export default function UserTableRow({
  row,
  onAuctionListClick,
  onDetailUserClick,
}: Props) {
  const onViewRow = () => {
    onDetailUserClick();
  };

  return (
    <TableRow hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={row.nomeCognome} src={row.user.fotoProfilo?.url()} sx={{ mr: 2 }} />
        <div>
          <Link
            noWrap
            variant="subtitle2"
            sx={{ color: 'text.primary', cursor: 'pointer' }}
            color="text.primary"
            onClick={onViewRow}
            >
            {row.nomeCognome}
          </Link>
          <br /> 
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>{row.tipologiaPartner}</Typography>
        </div>
      </TableCell>

      <TableCell align="center">
        <Button
          variant="outlined"
          onClick={onAuctionListClick}
          startIcon={<Iconify icon={'material-symbols:other-houses-outline'} />}
        >
          Immobili
        </Button>
      </TableCell>

      <TableCell align="left">{row.email}</TableCell>

      <TableCell align="left">{row.telefono}</TableCell>

      <TableCell align="left">
        <PartnerZoneCompetenzaPreview
          zoneCompetenza={row.zoneCompetenza}
          mode={ModePartnerZoneCompetenzaPreview.limited}
          limit={3}
        />
      </TableCell>

      <TableCell align="center">{row.numeroVivacizzazioni}</TableCell>

      <TableCell align="center">{fCurrency(row.guadagnoTotale)}</TableCell>

      <TableCell align="center">
        <Iconify
          icon={
            row.status === StatusUser.attivo ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'
          }
          sx={{
            width: 28,
            height: 28,
            color: 'primary.main',
            ...(row.status !== StatusUser.attivo && { color: 'warning.main' }),
          }}
        />
      </TableCell>
    </TableRow>
  );
}
