import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import { fData } from '../../../../utils/formatNumber';
// components
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';
import { Anagrafica } from 'src/parse/models/interfaces/anagrafica.interface';
import { DatiFiscali } from 'src/parse/models/interfaces/dati-fiscali.interface';

import * as Parse from 'parse';
import { UserModel } from 'src/parse/models/user.model';
import PartnerZoneCompetenzaEdit from './PartnerZoneCompetenzaEdit';
import Label from 'src/components/Label';
import PartnerZoneCompetenzaPreview, {
  ModePartnerZoneCompetenzaPreview,
} from '../../partners/PartnerZoneCompetenzaPreview';
import useApplicationSettings from 'src/hooks/useApplicationSettings';
import Iconify from 'src/components/Iconify';
import SendCredentialsModal from './PartnerSendCredentialsModal';
import { StatusUser } from 'src/parse/models/interfaces/status-user.enum';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { UserService } from 'src/parse/services/user.service';
import { ListAdminItem } from 'src/@types/admin';
import { handleParseError } from 'src/utils/parseErrorHandler';
import { useDispatch } from 'react-redux';
import { AuctionLogger } from 'src/utils/loggerManager';
import { CustomFile } from 'src/components/upload';

// ----------------------------------------------------------------------

interface Props {
  isNew: boolean;
  user?: UserModel;
}

type FormValuesProps = {
  fotoProfilo: CustomFile | string | null;
  nome: string;
  cognome: string;
  email: string;
  telefono: string | null;
  indirizzo: string | null;
  comune: string | null;
  cap: string | null;

  ragioneSociale: string | null;
  indirizzoFiscale: string | null;
  comuneFiscale: string | null;
  capFiscale: string | null;
  partitaIva: string | null;
  codiceFiscale: string | null;

  tipologia: string;
  abilitato: boolean;
  referenteId: string;
};

export default function AccountGeneral({ isNew, user }: Props) {
  const { typologiesOfPartner } = useApplicationSettings();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statusSwitch, setStatusSwitch] = useState(
    isNew ? StatusUser.attivo : user ? user.status : StatusUser.attivo
  );
  const { enqueueSnackbar } = useSnackbar();

  const { isAdmin } = useAuth();

  const currentUser = UserModel.current() as UserModel;

  const UpdateUserSchema = Yup.object().shape({
    // anagrafica
    nome: Yup.string().required('Il nome è obbligatorio'),
    cognome: Yup.string().required('Il cognome è obbligatorio'),
    telefono: Yup.string().required('Il cellulare è obbligatorio'),
    indirizzo: Yup.string(),
    comune: Yup.string(),
    cap: Yup.string(),
    email: Yup.string().required('La mail è obbligatoria'),
    // dati fiscali
    ragioneSociale: Yup.string(),
    indirizzoFiscale: Yup.string(),
    comuneFiscale: Yup.string(),
    capFiscale: Yup.string(),
    partitaIva: Yup.string(),
    codiceFiscale: Yup.string(),
    // impostazioni
    abilitato: Yup.boolean().required(''),
    tipologia: Yup.string(),
    referenteId: Yup.string(),
  });

  const defaultValues = {
    fotoProfilo: isNew ? '' : user?.fotoProfilo?.url() || '',
    nome: isNew ? '' : user?.anagrafica?.nome,
    cognome: isNew ? '' : user?.anagrafica?.cognome,
    email: isNew ? '' : user?.email,
    telefono: isNew ? '' : user?.anagrafica?.telefono,
    indirizzo: isNew ? '' : user?.anagrafica?.indirizzo,
    comune: isNew ? '' : user?.anagrafica?.comune,
    cap: isNew ? '' : user?.anagrafica?.cap,

    ragioneSociale: isNew ? '' : user?.datiFiscali?.ragioneSociale,
    indirizzoFiscale: isNew ? '' : user?.datiFiscali?.indirizzoFiscale,
    comuneFiscale: isNew ? '' : user?.datiFiscali?.comuneFiscale,
    capFiscale: isNew ? '' : user?.datiFiscali?.capFiscale,
    partitaIva: isNew ? '' : user?.datiFiscali?.partitaIva,
    codiceFiscale: isNew ? '' : user?.datiFiscali?.codiceFiscale,

    tipologia: isNew ? '' : user?.tipologiaPartner,
    abilitato: isNew ? true : user?.status === StatusUser.attivo ? true : false,
    referenteId: isNew ? currentUser.id : user?.referente?.id,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data: FormValuesProps) => {
    if (isNew) {
      onNewPartnerHandler(data);
    } else {
      onEditPartnerHandler(data);
    }
  };

  const onNewPartnerHandler = async (data: FormValuesProps) => {
    try {
      let creationJson = {
        nome: data.nome,
        cognome: data.cognome,
        telefono: data.telefono,
        email: data.email.trim(),
        indirizzo: data.indirizzo,
        comune: data.comune,
        cap: data.cap,
        ragioneSociale: data.ragioneSociale,
        indirizzoFiscale: data.indirizzoFiscale,
        comuneFiscale: data.comuneFiscale,
        capFiscale: data.capFiscale,
        partitaIva: data.partitaIva,
        codiceFiscale: data.codiceFiscale,
        tipologiaPartner: data.tipologia,
        status: data.abilitato ? StatusUser.attivo : StatusUser.inattivo,
        idReferente: data.referenteId,
      };

      const userId = await Parse.Cloud.run('createUser', creationJson);

      AuctionLogger('Partner', 'UI Action', `New partner created with values: ${JSON.stringify(creationJson)}`);

      enqueueSnackbar('Partner creato correttamente!');
      if (userId) {
        navigate(PATH_DASHBOARD.partners.edit(userId));
      } else {
        navigate(PATH_DASHBOARD.partners.root);
      }
    } catch (error) {
      const errorMessage = handleParseError(error, dispatch, 'Impossibile creare il partner!');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  const onEditPartnerHandler = async (data: FormValuesProps) => {
    try {
      let theUser = user!;
      const anagrafica: Anagrafica = {
        nome: data.nome,
        cognome: data.cognome,
        telefono: data.telefono || '',
        indirizzo: data.indirizzo || '',
        comune: data.comune || '',
        cap: data.cap || '',
        posizione: '',
      };
      const datiFiscali: DatiFiscali = {
        ragioneSociale: data.ragioneSociale || '',
        indirizzoFiscale: data.indirizzoFiscale || '',
        comuneFiscale: data.comuneFiscale || '',
        capFiscale: data.capFiscale || '',
        partitaIva: data.partitaIva || '',
        codiceFiscale: data.codiceFiscale || '',
      };

      theUser.anagrafica = anagrafica;
      theUser.datiFiscali = datiFiscali;
      theUser.email = data.email.trim();
      theUser.tipologiaPartner = data.tipologia;
      theUser.status = data.abilitato ? StatusUser.attivo : StatusUser.inattivo;

      const userModelItem = admins.find((admin) => admin.id === data.referenteId);
      theUser.referente = userModelItem ? userModelItem.userModel : null;

      if (data.fotoProfilo instanceof Object) {
        const arraybuffer = await data.fotoProfilo.arrayBuffer();
        const bytes = new Uint8Array(arraybuffer);
        const fotoProfilo = new Parse.File(data.fotoProfilo.name, Array.from(bytes));
        theUser.fotoProfilo = fotoProfilo;
      }

      AuctionLogger('Partner', 'UI Action', `Edited partner with values: ${JSON.stringify(theUser)}`);

      await theUser.save();
      reset(data);
      enqueueSnackbar('Aggiornamento riuscito!');
    } catch (error) {
      const errorMessage = handleParseError(error, dispatch);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  const onAuctionListClick = (id: string) => {
    AuctionLogger('Partner', 'UI Action', `Open auction list for user ${id}`);
    window.open(PATH_DASHBOARD.immobili.viewByUser(id), '_blank');
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'fotoProfilo',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
          { shouldDirty: true }
        );
      }
    },
    [setValue]
  );

  const handleStatusSwitchChange = () => {
    setStatusSwitch((prevState) => {
      const newState = prevState === StatusUser.attivo ? StatusUser.inattivo : StatusUser.attivo;
      return newState;
    });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [admins, setAdmins] = useState<ListAdminItem[]>([]);
  useEffect(() => {
    const fetchAdmins = async () => {
      const adminList = await UserService.getAdmins();
      const admins = adminList.map((user) => ({
        id: user.id,
        fullName: user.anagrafica?.nome + ' ' + user.anagrafica?.cognome,
        userModel: user,
      }));
      setAdmins(admins);
    };
    fetchAdmins();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {!isNew && (
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <RHFUploadAvatar
                name="fotoProfilo"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Formato *.jpeg, *.jpg, *.png, *.gif
                    <br /> max. {fData(3145728)}
                  </Typography>
                }
              />
            </Card>
          )}

          {isAdmin && !isNew && user && currentUser.id !== user.id && (
            <Card sx={{ mt: 3, py: 3, px: 3, textAlign: 'center' }}>
              <Divider sx={{ mb: 2 }}>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  ZONE DI COMPETENZA{' '}
                </Typography>
              </Divider>
              <Box>
                <PartnerZoneCompetenzaEdit user={user} />
              </Box>
            </Card>
          )}

          {!isAdmin && !isNew && user && (
            <Card sx={{ mt: 3, py: 3, px: 3, textAlign: 'center' }}>
              <Divider sx={{ mb: 2 }}>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  ZONE DI COMPETENZA{' '}
                </Typography>
              </Divider>
              <Box>
                <PartnerZoneCompetenzaPreview
                  zoneCompetenza={user.zoneCompetenza}
                  mode={ModePartnerZoneCompetenzaPreview.full}
                />
              </Box>
            </Card>
          )}
        </Grid>

        <Grid item xs={12} md={isNew ? 12 : 8}>
          <Card sx={{ p: 3 }}>
            {((isAdmin && user && currentUser.id !== user.id) || isNew) && (
              <>
                <Divider>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    IMPOSTAZIONI
                  </Typography>
                </Divider>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <RHFSelect name="tipologia" label="Posizione">
                      <option />
                      {typologiesOfPartner.map((tipology) => (
                        <option key={tipology.id}>{tipology.valore}</option>
                      ))}
                    </RHFSelect>{' '}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <RHFSelect
                      name="referenteId"
                      label="Referente"
                      InputLabelProps={{ shrink: true }} // workaround to avoid label and value sometimes overlap
                    >
                      <option />
                      {admins.map((admin) => (
                        <option value={admin.id} key={admin.id}>
                          {admin.fullName}
                        </option>
                      ))}
                    </RHFSelect>{' '}
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ alignSelf: 'center' }}>
                    <Typography>
                      <RHFSwitch
                        name="abilitato"
                        label="Stato"
                        onClick={handleStatusSwitchChange}
                      />
                      <Label color={statusSwitch === StatusUser.attivo ? 'success' : 'warning'}>
                        {statusSwitch === StatusUser.attivo ? 'ATTIVO' : 'INATTIVO'}
                      </Label>
                    </Typography>{' '}
                  </Grid>
                </Grid>

                <br />
                <br />
              </>
            )}
            <Divider>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                ANAGRAFICA
              </Typography>
            </Divider>
            <br />
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="nome" label="Nome" />
              <RHFTextField name="cognome" label="Cognome" />
              <RHFTextField disabled={!isNew} name="email" label="Email" />
              <RHFTextField name="telefono" label="Cellulare" />
            </Box>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="indirizzo" label="Indirizzo" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <RHFTextField name="comune" label="Comune" />
              </Grid>
              <Grid item xs={12} sm={2}>
                <RHFTextField name="cap" label="CAP" />
              </Grid>
            </Grid>

            <br />
            <br />
            <Divider>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                DATI FISCALI
              </Typography>
            </Divider>
            <br />
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="ragioneSociale" label="Ragione Sociale" />
            </Box>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="indirizzoFiscale" label="Indirizzo sede legale" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <RHFTextField name="comuneFiscale" label="Comune" />
              </Grid>
              <Grid item xs={12} sm={2}>
                <RHFTextField name="capFiscale" label="CAP" />
              </Grid>
            </Grid>
            <br />
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="partitaIva" label="Partita Iva" />
              <RHFTextField name="codiceFiscale" label="Codice Fiscale" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!methods.formState.isDirty}
              >
                {isNew ? 'Crea Partner' : 'Salva modifiche'}
              </LoadingButton>
            </Stack>
          </Card>

          {user && currentUser.id !== user.id && (
            <Card sx={{ mt: 3, py: 3, px: 3, textAlign: 'center' }}>
              <Divider sx={{ mb: 2 }}>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  AZIONI
                </Typography>
              </Divider>
              <Stack
                spacing={3}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="center"
                sx={{ mt: 3 }}
              >
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  startIcon={<Iconify icon={'bi:send'} />}
                >
                  Invia credenziali
                </Button>
                <SendCredentialsModal open={open} user={user} onClose={handleClose} />
                <Button
                  variant="contained"
                  onClick={() => onAuctionListClick(user.id)}
                  startIcon={<Iconify icon={'material-symbols:other-houses-outline'} />}
                >
                  Lista immobili
                </Button>
              </Stack>
            </Card>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}
