import * as Parse from 'parse';

import { TipologiaGuadagnoModel } from "./tipologia-guadagno.model";

export class ServicerModel extends Parse.Object {
    
    public static className = "Servicers";

    public static fields = {
       objectId: "objectId",
       createdAt: "createdAt",
       updatedAt: "updatedAt",
       ACL: "ACL",
       abilitato: "abilitato",
       denominazione: "denominazione",
       nomeVisualizzato: "nomeVisualizzato",
       tipologiaGuadagno: "tipologiaGuadagno",
       feeReviva: "feeReviva"
   };
    
    constructor() {
        super(ServicerModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(ServicerModel.className, ServicerModel);
    }

    public get objectId(): string {
        return this.get(ServicerModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(ServicerModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(ServicerModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(ServicerModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(ServicerModel.fields.ACL, ACL);
    }

    public get abilitato(): boolean {
        return this.get(ServicerModel.fields.abilitato);
    }

    public set abilitato(abilitato: boolean) {
        this.set(ServicerModel.fields.abilitato, abilitato);
    }

    public get denominazione(): string {
        return this.get(ServicerModel.fields.denominazione);
    }

    public set denominazione(denominazione: string) {
        this.set(ServicerModel.fields.denominazione, denominazione);
    }

    public get nomeVisualizzato(): string {
        return this.get(ServicerModel.fields.nomeVisualizzato);
    }

    public set nomeVisualizzato(nomeVisualizzato: string) {
        this.set(ServicerModel.fields.nomeVisualizzato, nomeVisualizzato);
    }

    public get tipologiaGuadagno(): TipologiaGuadagnoModel {
        return this.get(ServicerModel.fields.tipologiaGuadagno);
    }

    public set tipologiaGuadagno(tipologiaGuadagno: TipologiaGuadagnoModel) {
        this.set(ServicerModel.fields.tipologiaGuadagno, tipologiaGuadagno);
    }
    
    public get feeReviva(): number {
        return this.get(ServicerModel.fields.feeReviva);
    }

    public set feeReviva(feeReviva: number) {
        this.set(ServicerModel.fields.feeReviva, feeReviva);
    }
}

