import * as Parse from 'parse';

export class TipologiaGuadagnoModel extends Parse.Object {

    public static className = "TipologieGuadagno";

    public static fields = {
        objectId: "objectId",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        ACL: "ACL",
        nome: "nome",
        descrizione: "descrizione",
        icona: "icona",
        feeFissa: "feeFissa",
        feePercDeposito: "feePercDeposito",
        feePercRilancio: "feePercRilancio",
        feePercAggiudicazione: "feePercAggiudicazione",
        feePercPartecipazioneDiretta: "feePercPartecipazioneDiretta",
        limiteGuadagno: "limiteGuadagno"
    };

    constructor() {
        super(TipologiaGuadagnoModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(TipologiaGuadagnoModel.className, TipologiaGuadagnoModel);
    }

    public get objectId(): string {
        return this.get(TipologiaGuadagnoModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(TipologiaGuadagnoModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(TipologiaGuadagnoModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(TipologiaGuadagnoModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(TipologiaGuadagnoModel.fields.ACL, ACL);
    }

    public get nome(): string {
        return this.get(TipologiaGuadagnoModel.fields.nome);
    }

    public set nome(nome: string) {
        this.set(TipologiaGuadagnoModel.fields.nome, nome);
    }

    public get descrizione(): string {
        return this.get(TipologiaGuadagnoModel.fields.descrizione);
    }

    public set descrizione(descrizione: string) {
        this.set(TipologiaGuadagnoModel.fields.descrizione, descrizione);
    }

    public get icona(): string {
        return this.get(TipologiaGuadagnoModel.fields.icona);
    }

    public set icona(icona: string) {
        this.set(TipologiaGuadagnoModel.fields.icona, icona);
    }

    public get feePercDeposito(): number {
        return this.get(TipologiaGuadagnoModel.fields.feePercDeposito);
    }

    public set feePercDeposito(feeDeposito: number) {
        this.set(TipologiaGuadagnoModel.fields.feePercDeposito, feeDeposito);
    }

    public get feePercRilancio(): number {
        return this.get(TipologiaGuadagnoModel.fields.feePercRilancio);
    }

    public set feePercRilancio(feeRilancio: number) {
        this.set(TipologiaGuadagnoModel.fields.feePercRilancio, feeRilancio);
    }

    public get feePercAggiudicazione(): number {
        return this.get(TipologiaGuadagnoModel.fields.feePercAggiudicazione);
    }

    public set feePercAggiudicazione(feeAggiudicazione: number) {
        this.set(TipologiaGuadagnoModel.fields.feePercAggiudicazione, feeAggiudicazione);
    }

    public get feePercPartecipazioneDiretta(): number {
        return this.get(TipologiaGuadagnoModel.fields.feePercPartecipazioneDiretta);
    }

    public set feePercPartecipazioneDiretta(feePartecipazioneDiretta: number) {
        this.set(TipologiaGuadagnoModel.fields.feePercPartecipazioneDiretta, feePartecipazioneDiretta);
    }

    public get feeFissa(): number {
        return this.get(TipologiaGuadagnoModel.fields.feeFissa);
    }

    public set feeFissa(feeFissa: number) {
        this.set(TipologiaGuadagnoModel.fields.feeFissa, feeFissa);
    }

    public get limiteGuadagno(): number {
        return this.get(TipologiaGuadagnoModel.fields.limiteGuadagno);
    }

    public set limiteGuadagno(limiteGuadagno: number) {
        this.set(TipologiaGuadagnoModel.fields.limiteGuadagno, limiteGuadagno);
    }
}
