import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap, ParseContextType } from 'src/@types/auth';
import { UserModel } from 'src/parse/models/user.model';
import { ParseService } from 'src/parse/services/parse.service';
import { UserService } from 'src/parse/services/user.service';

// ----------------------------------------------------------------------

export enum LoginTypes {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type ParseAuthPayload = {
  [LoginTypes.Initial]: {
    isAuthenticated: boolean;
    isAdmin: boolean;
    isOperation: boolean;
    user: null | UserModel;
  };
  [LoginTypes.Login]: {
    user: null | UserModel;
    isAdmin: boolean;
    isOperation: boolean;
  };
  [LoginTypes.Logout]: undefined;
  [LoginTypes.Register]: {
    user: null | UserModel;
  };
};

export type ParseActions = ActionMap<ParseAuthPayload>[keyof ActionMap<ParseAuthPayload>];

export const LOGIN_LOGOUT_CHANNEL_NAME = 'loginLogoutChannelName';

const initialState: any = {
  isAuthenticated: false,
  isAdmin: false,
  isOperation: false,
  isInitialized: false,
  user: null,
};

const ParseReducer = (state: any, action: ParseActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isAdmin: action.payload.isAdmin,
        isOperation: action.payload.isOperation,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isAdmin: action.payload.isAdmin,
        isOperation: action.payload.isOperation,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        isOperation: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: false,
        isOperation: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<ParseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(ParseReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const userSession = await ParseService.checkSession();

        if (userSession) {
          const user = UserModel.current() as UserModel;

          const isOperation = await UserService.isOperation(user);

          const isAdmin = await UserService.isAdmin(user);

          dispatch({
            type: LoginTypes.Initial,
            payload: {
              isAdmin,
              isOperation,
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LoginTypes.Initial,
            payload: {
              isAdmin: false,
              isOperation: false,
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LoginTypes.Initial,
          payload: {
            isAdmin: false,
            isOperation: false,
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const user = (await ParseService.login(username, password)) as UserModel;
      const isAdmin = await UserService.isAdmin(user);
      const isOperation = await UserService.isOperation(user);

      dispatch({
        type: LoginTypes.Login,
        payload: {
          user,
          isAdmin,
          isOperation,
        },
      });
    } catch (error) {
      ParseService.handleParseError(error);
    }
  };

  const register = async (
    username: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    dispatch({
      type: LoginTypes.Register,
      payload: {
        user: null,
      },
    });
  };

  const logout = async () => {
    UserModel.logOut();

    dispatch({ type: LoginTypes.Logout });
  };

  const onLogout = () => {
    dispatch({ type: LoginTypes.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'parse',
        login,
        logout,
        register,
        onLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
