import { Card, Grid, Skeleton } from "@mui/material";

export default function SkeletonServicerEdit() {
  return (
    
    <Grid container spacing={3}>
      <Grid item xs={12}>
         <Skeleton variant="text"  width="40%" sx={{ fontSize: '1.5rem' }} />
         <Skeleton variant="text"  width="60%" sx={{ fontSize: '1.5rem' }} />
         <br />
         <Skeleton variant="text"  width="30%" sx={{ fontSize: '1.5rem' }} />
         <br />
         <Skeleton variant="text"  width="30%" sx={{ fontSize: '1.5rem' }} />
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '100%', borderRadius: 2 }} />
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '100%', borderRadius: 2 }} />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
