import { IKPIMetrica } from 'src/parse/models/interfaces/kpi-metrica.interface';
import { KPIMetricheEnum } from 'src/parse/models/interfaces/kpi-metriche.enum';

export class KPIInternalModel {

  readonly _createdAt: Date;

  private _objectId: string;

  private _referenceDate: Date;

  private _kpis: Map<KPIMetricheEnum, IKPIMetrica>;

  constructor() {
    this._createdAt = new Date();
    this._objectId = '';
    this._referenceDate = new Date();
    this._kpis = new Map<KPIMetricheEnum, IKPIMetrica>();
  }

  public get objectId(): string {
    return this._objectId;
  }

  public set objectId(objectId: string) {
    this._objectId = objectId;
  }

  public get referenceDate(): Date {
    return this._referenceDate;
  }

  public set referenceDate(date: Date) {
    this._referenceDate = date;
  }

  public get kpis(): Map<KPIMetricheEnum, IKPIMetrica> {
    return this._kpis;
  }

  public set kpis(kpis: Map<KPIMetricheEnum, IKPIMetrica>) {
    this._kpis = kpis;
  }
}
