import * as Parse from 'parse';
import { TipoComunicazioniAsta } from "./interfaces/tipo-comunicazioni-asta.enum";
import { AstaModel } from "./asta.model";
import { UserModel } from "./user.model";
import { InOutLogsModel } from './in-out-logs.model';
import { StatoComunicazioniAsta } from './interfaces/stato-comunicazioni-asta';

export class ComunicazioniAstaModel extends Parse.Object {

    public static className = "ComunicazioniAsta";

    public static fields = {
        objectId: "objectId",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        ACL: "ACL",
        vivacizzatore: "vivacizzatore",
        asta: "asta",
        tipo: "tipo",
        valore: "valore",
        nota: "nota",
        idAffidamentoReviva: "idAffidamentoReviva",
        stato: "stato",
        inOutLog: "inOutLog",
    };

    constructor() {
        super(ComunicazioniAstaModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(ComunicazioniAstaModel.className, ComunicazioniAstaModel);
    }

    public get objectId(): string {
        return this.get(ComunicazioniAstaModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(ComunicazioniAstaModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(ComunicazioniAstaModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(ComunicazioniAstaModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(ComunicazioniAstaModel.fields.ACL, ACL);
    }

    public get vivacizzatore(): UserModel {
        return this.get(ComunicazioniAstaModel.fields.vivacizzatore);
    }

    public set vivacizzatore(vivacizzatore: UserModel) {
        this.set(ComunicazioniAstaModel.fields.vivacizzatore, vivacizzatore);
    }

    public get asta(): AstaModel {
        return this.get(ComunicazioniAstaModel.fields.asta);
    }

    public set asta(asta: AstaModel) {
        this.set(ComunicazioniAstaModel.fields.asta, asta);
    }

    public get tipo(): TipoComunicazioniAsta {
        return this.get(ComunicazioniAstaModel.fields.tipo);
    }

    public set tipo(tipo: TipoComunicazioniAsta) {
        this.set(ComunicazioniAstaModel.fields.tipo, tipo);
    }

    public get valore(): string {
        return this.get(ComunicazioniAstaModel.fields.valore);
    }

    public set valore(valore: string) {
        this.set(ComunicazioniAstaModel.fields.valore, valore);
    }

    public get nota(): string {
        return this.get(ComunicazioniAstaModel.fields.nota);
    }

    public set nota(nota: string) {
        this.set(ComunicazioniAstaModel.fields.nota, nota);
    }


    public get idAffidamentoReviva(): string {
        return this.get(ComunicazioniAstaModel.fields.idAffidamentoReviva);
    }

    public set idAffidamentoReviva(idAffidamentoReviva: string) {
        this.set(ComunicazioniAstaModel.fields.idAffidamentoReviva, idAffidamentoReviva);
    }

    public get stato(): StatoComunicazioniAsta {
        return this.get(ComunicazioniAstaModel.fields.stato);
    }

    public set stato(stato: StatoComunicazioniAsta) {
        this.set(ComunicazioniAstaModel.fields.stato, stato);
    }

    public get inOutLog(): InOutLogsModel {
        return this.get(ComunicazioniAstaModel.fields.inOutLog);
    }

    public set inOutLog(inOutLog: InOutLogsModel) {
        this.set(ComunicazioniAstaModel.fields.inOutLog, inOutLog);
    }
}
