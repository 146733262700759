export function formatDateITLocale(theDate: Date) {
    return theDate ?  theDate.toLocaleDateString('IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }) : '';
  }


  export function formatDateYearMonthOnlyITLocale(theDate: Date) {
    return theDate ? theDate.toLocaleDateString('IT', {
        year: 'numeric',
        month: 'long',
      }) : '';
  }