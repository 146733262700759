import * as Parse from 'parse';
import { useEffect, useState } from 'react';
import { AreaKey, AuctionLogger } from 'src/utils/loggerManager';
import usePrevious from 'src/utils/usePrevious';

export interface SubscriptionProps<T> {
  recordId?: string | undefined;
  onNew?: (record: T) => void;
  onUpdate?: (record: T) => void;
  onDelete?: (record: T) => void;
  onSnapshot?: (records: T[]) => void;
}

export interface Props<T> extends SubscriptionProps<T> {
  loggingKey: AreaKey;
  query: Parse.Query<Parse.Object<Parse.Attributes>>;
}

export default function BaseSubscriber<T extends Parse.Object>({
  query,
  loggingKey,
  recordId,
  onNew,
  onUpdate,
  onDelete,
  onSnapshot,
}: Props<T>) {
  const [subscription, setSubscription] = useState<Parse.LiveQuerySubscription | null>(null);

  const previousRecordId = usePrevious(recordId);

  useEffect(() => {
    if (subscription === null) {
      const startSubscription = async () => {
        const subscription = await query.subscribe();

        setSubscription(subscription);

        subscription.on('create', (object) => {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey}: [${recordId ? recordId : 'ALL'}] New Resource!`,
            object
          );
          if (onNew) {
            onNew(object as T);
          }
        });

        subscription.on('enter', (object) => {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey}: [${recordId ? recordId : 'ALL'}] Enter Resource!`,
            object
          );
          if (onUpdate) {
            onUpdate(object as T);
          }
        });

        subscription.on('leave', (object) => {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey}: [${recordId ? recordId : 'ALL'}] Leave Resource!`,
            object
          );
          if (onDelete) {
            onDelete(object as T);
          }
        });

        subscription.on('update', (object) => {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey}: [${recordId ? recordId : 'ALL'}] Update Resource!`,
            object
          );
          if (onUpdate) {
            onUpdate(object as T);
          }
        });

        subscription.on('delete', (object) => {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey}: [${recordId ? recordId : 'ALL'}] Delete Resource!`,
            object
          );
          if (onDelete) {
            onDelete(object as T);
          }
        });

        subscription.on('close', () => {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey} [${recordId ? recordId : 'ALL'}] Close Channel!`
          );
        });

        subscription.on('open', async () => {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey} [${recordId ? recordId : 'ALL'}] Open Channel!`
          );
          if (onSnapshot) {
            const records = (await query.findAll()) as T[];
            onSnapshot(records);
            AuctionLogger(
              loggingKey,
              'Data',
              `${loggingKey}, [${recordId ? recordId : 'ALL'}] downloaded entries #: `,
              records.length
            );
          }
        });
      };

      startSubscription().catch(console.error);
    } else {
      if (recordId !== previousRecordId) {
        if (subscription !== null) {
          AuctionLogger(
            loggingKey,
            'Data',
            `${loggingKey} [${recordId ? recordId : 'ALL'}] change subscription, From, To`,
            previousRecordId,
            recordId
          );
          subscription.unsubscribe();
          setSubscription(null);
        }
      }
    }
  }, [
    loggingKey,
    recordId,
    query,
    subscription,
    previousRecordId,
    onNew,
    onUpdate,
    onDelete,
    onSnapshot,
  ]);

  useEffect(
    () => () => {
      if (subscription !== null) {
        AuctionLogger(
          loggingKey,
          'Data',
          `${loggingKey} useEffect clean code on unmount`,
          subscription
        );
        subscription.unsubscribe();
        setSubscription(null);
      }
    },
    [subscription, loggingKey]
  );

  return <></>;
}
