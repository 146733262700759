// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';

// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
// sections
import { LoginForm } from '../../sections/auth/login';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { uiStateActions } from 'src/redux/slices/uiSlice';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(uiStateActions.clearFilters());
  }, [dispatch]);

  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="column" alignItems="center" sx={{ mb: 5, marginBottom: '60px' }}>
              <Logo />
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Entra in Vivapro
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Inserisci le tue credenziali
                </Typography>
              </Box>
            </Stack>

            <LoginForm />

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
