// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const IMMOBILI = '/immobili'

export const USER = '/user'

export const EDIT_PATH = 'edit'

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  partners: {
    root: path(ROOTS_DASHBOARD, '/partners'),
    new: path(ROOTS_DASHBOARD, `/partners/new`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/partners/${id}/${EDIT_PATH}`)
  },

  servicers: {
    root: path(ROOTS_DASHBOARD, '/servicers'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/servicers/${id}/${EDIT_PATH}`)
  },

  statistics: {
    root: path(ROOTS_DASHBOARD, '/stats'),
  },

  earnings: {
    root: path(ROOTS_DASHBOARD, '/guadagni'),
  },

  participations: {
    root: path(ROOTS_DASHBOARD, '/participations'),
  },

  favourites: {
    root: path(ROOTS_DASHBOARD, '/preferiti'),
  },

  names: {
    root: path(ROOTS_DASHBOARD, '/nominativi'),
  },

  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    immobili: path(ROOTS_DASHBOARD, IMMOBILI),
  },
  immobili: {
    root: path(ROOTS_DASHBOARD, `${IMMOBILI}/lista`),
    viewByUser: (name: string) => path(ROOTS_DASHBOARD, `${IMMOBILI}/lista/${name}`),
    view: (name: string) => path(ROOTS_DASHBOARD, `${IMMOBILI}/annuncio/${name}`),
    public: (name: string) => path(ROOTS_DASHBOARD, `/public/${name}`),
  },
  notifications : {
    root: path(ROOTS_DASHBOARD, '/notifications'),
  },
  resources : {
    root: path(ROOTS_DASHBOARD, '/resources'),
  },

  user: {
    root: path(ROOTS_DASHBOARD, '/utente'),
    new: path(ROOTS_DASHBOARD, `${USER}/new`),
    list: path(ROOTS_DASHBOARD, `${USER}/list`),
    cards: path(ROOTS_DASHBOARD, `${USER}/cards`),
    profile: path(ROOTS_DASHBOARD, `${USER}/profile`),
    account: path(ROOTS_DASHBOARD, `${USER}/account`),
    preferences: path(ROOTS_DASHBOARD, `${USER}/preferenze`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

};
