import { useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Grid,
  Card,
  Stack,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from '../../../../components/hook-form';

import { ServicerModel } from 'src/parse/models/servicer.model';
import Iconify from 'src/components/Iconify';
import { TipologiaGuadagnoModel } from 'src/parse/models/tipologia-guadagno.model';
import EmptyContent from 'src/components/EmptyContent';
import Label from 'src/components/Label';
import { handleParseError } from 'src/utils/parseErrorHandler';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

interface Props {
  servicer: ServicerModel;
  typologies: TipologiaGuadagnoModel[];
}

type FormValuesProps = {
  nomeVisualizzato: string;
  abilitato: boolean;
  feeReviva: number;
  tipologiaGuadagno: string;
};

export default function ServicerGeneral({ servicer, typologies }: Props) {

  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [statusSwitch, setStatusSwitch] = useState(servicer.abilitato);
  const [selectedTypology, setSelectedTypology] = useState(servicer.tipologiaGuadagno);
  const [isTypologyDefined, setIsTypologyDefined] = useState(selectedTypology ? true : false);

  const UpdateUserSchema = Yup.object().shape({
    nomeVisualizzato: Yup.string().required('Il nome del servicer è obbligatorio'),
    feeReviva: Yup.number()
    .typeError('Inserire un numero valido')
                    .required("La Fee Reviva è obbligatoria.")
                    .positive("Solo valori positivi.")
                    .max(100, 'Valore troppo grande.'),
    tipologiaGuadagno: Yup.string().required('La Tipologia di guadagno è obbligatoria'),
    abilitato: Yup.boolean().required(''),
  });

  const defaultValues = {
    denominazione: servicer.denominazione,
    nomeVisualizzato: servicer.nomeVisualizzato,
    abilitato: servicer.abilitato,
    feeReviva: servicer.feeReviva,
    tipologiaGuadagno: servicer.tipologiaGuadagno?.nome || "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;
    

  const handleStatusSwitchChange = () => {
    setStatusSwitch(!statusSwitch);
  };

  const handleTypologyChange = (event: any) => {
    let typologyName = event.target.value;
    const typology = typologies.filter((typology) => typology.nome === typologyName);
    if (typology.length) {
      setSelectedTypology(typology[0]);
      setIsTypologyDefined(true);
    } else {
      setIsTypologyDefined(false);
    }
  };

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (servicer) {
        servicer.nomeVisualizzato = data.nomeVisualizzato;
        servicer.feeReviva = data.feeReviva;
        servicer.abilitato = data.abilitato;
        const submittedTypology = typologies.filter(
          (typology) => typology.nome === data.tipologiaGuadagno
        );
        servicer.tipologiaGuadagno = submittedTypology[0];

        await servicer.save();

        reset(data);
        enqueueSnackbar('Aggiornamento riuscito!');
      }
    } catch (error) {
      const errorMessage = handleParseError(error, dispatch);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Divider>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                SERVICER
              </Typography>
            </Divider>
            <br />
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField disabled name="denominazione" label="Nome in Django" />
              <RHFTextField name="nomeVisualizzato" label="Nome visualizzato" />
              <RHFTextField
                type="number"
                name="feeReviva"
                label="Fee Reviva"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Iconify icon={'ic:twotone-percent'} sx={{ width: 20, height: 20 }} />
                    </InputAdornment>
                  ),
                }}
              />

              <RHFSelect
                name="tipologiaGuadagno"
                label="Tipologia di guadagno"
                onClick={handleTypologyChange}
                InputLabelProps={{ shrink: true }} // workaround to avoid label and value sometimes overlap
              >
                <option />
                {typologies.map((tipology) => (
                  <option key={tipology.id}>{tipology.nome}</option>
                ))}
              </RHFSelect>

              <Typography>
                <RHFSwitch name="abilitato" label="Stato" onClick={handleStatusSwitchChange} />
                <Label color={statusSwitch ? 'success' : 'warning'}>
                  {statusSwitch ? 'ATTIVO' : 'INATTIVO'}
                </Label>
              </Typography>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Divider>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                TIPOLOGIA
              </Typography>
            </Divider>
            <br />

            {isTypologyDefined ? (
              <>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                  }}
                >
                  <Typography align="center" variant="h5" sx={{ color: 'text.secondary' }}>
                    <Iconify
                      icon={selectedTypology.icona}
                      color={theme.palette.grey[700]}
                      width={28}
                      height={28}
                    />
                    <span style={{ verticalAlign: 'super' }}> {selectedTypology.nome}</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    paragraph
                    dangerouslySetInnerHTML={{
                      __html: selectedTypology.descrizione,
                    }}
                  />
                </Box>

                <Divider>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    PARAMETRI
                  </Typography>
                </Divider>
                <br />

                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <TextField
                    variant="outlined"
                    disabled
                    value={selectedTypology.feePercDeposito}
                    label="Fee deposito"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'ic:twotone-percent'} sx={{ width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    variant="outlined"
                    disabled
                    value={selectedTypology.feePercRilancio}
                    label="Fee rilancio"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'ic:twotone-percent'} sx={{ width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    variant="outlined"
                    disabled
                    value={selectedTypology.feePercAggiudicazione}
                    label="Fee aggiudicazione"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'ic:twotone-percent'} sx={{ width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    variant="outlined"
                    disabled
                    value={selectedTypology.feeFissa}
                    label="Fee fissa"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'bx:euro'} sx={{ width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    variant="outlined"
                    disabled
                    value={selectedTypology.feePercPartecipazioneDiretta}
                    label="Fee partecipaz. diretta"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'ic:twotone-percent'} sx={{ width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    variant="outlined"
                    disabled
                    value={selectedTypology.limiteGuadagno}
                    label="Limite guadagno"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'bx:euro'} sx={{ width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <br />
              </>
            ) : (
              <EmptyContent
                title="Nessuna tipologia associata"
                sx={{ color: 'text.secondary',
                  '& span.MuiBox-root': { height: 160 },
                }}
              />
            )}
          </Card>

          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!methods.formState.isDirty}
            >
              Salva modifiche
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
