import * as Parse from 'parse';
import { UserRoles } from 'src/config';
import { AstaModel } from '../models/asta.model';
import { StatusUser } from '../models/interfaces/status-user.enum';
import { UserModel } from '../models/user.model';

export class UserService {
  public static async getRoles(user: UserModel): Promise<Parse.Role[]> {
    const query = new Parse.Query(Parse.Role);
    query.equalTo('users', user);
    const result = await query.findAll();
    return result;
  }

  public static async isAdmin(user: UserModel): Promise<boolean> {
    let isAdmin = false;
    const roles = await this.getRoles(user);
    for (const role of roles) {
      if (role.getName() === UserRoles.ADMIN) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
    //return false;
  }

  public static async isOperation(user: UserModel): Promise<boolean> {
    let isOperation = false;
    const roles = await this.getRoles(user);
    for (const role of roles) {
      if (role.getName() === UserRoles.OPERATION) {
        isOperation = true;
        break;
      }
    }
    return isOperation;
    //return true;
  }

  public static getComuniFromUser(user: UserModel): string[] {
    let comuniLabels: string[] = [];
    const { comuni } = user.zoneCompetenza;
    if (comuni) {
      comuniLabels = comuni.map((comune) => comune.valore);
      comuniLabels.sort();
    }
    return comuniLabels;
  }

  public static getProvinceFromUser(user: UserModel): string[] {
    let provinceLabels: string[] = [];
    const { province } = user.zoneCompetenza;
    if (province) {
      provinceLabels = province.map((provincia) => provincia.valore);
      provinceLabels.sort();
    }
    return provinceLabels;
  }

  public static getRegioniFromUser(user: UserModel): string[] {
    let regioniLabels: string[] = [];
    const { regioni } = user.zoneCompetenza;
    if (regioni) {
      regioniLabels = regioni.map((regione) => regione.valore);
      regioniLabels.sort();
    }
    return regioniLabels;
  }

  public static async getPartners(): Promise<UserModel[]> {
    let partners = [] as UserModel[];
    const query = new Parse.Query(Parse.Role);
    query.contains('name', UserRoles.PARTNER);
    const role = await query.first();
    if (role) {
      partners = (await role.getUsers().query().findAll()) as UserModel[];
    }
    return partners;
  }

  public static async getPartnersByName(search: string): Promise<UserModel[]> {
    const namePointer = `${UserModel.fields.anagrafica}.nome`;
    const surnamePointer = `${UserModel.fields.anagrafica}.cognome`;
    
    const nameQuery = new Parse.Query(Parse.User);
    nameQuery.matches(namePointer, new RegExp(`${search}`, 'i'))
    const surnameQuery = new Parse.Query(Parse.User);
    surnameQuery.matches(surnamePointer, new RegExp(`${search}`, 'i'))
    
    const mainQuery = Parse.Query.or(nameQuery, surnameQuery);
    const partners = (await mainQuery.findAll()) as UserModel[];
    return partners;
  }


  public static async getAdmins(): Promise<UserModel[]> {
    let admins = [] as UserModel[];
    const query = new Parse.Query(Parse.Role);
    query.contains('name', UserRoles.ADMIN);
    const role = await query.first();
    if (role) {
      admins = (await role.getUsers().query().findAll()) as UserModel[];
    }
    return admins;
  }

  public static async getUserById(userId: string): Promise<UserModel> {
    const query = new Parse.Query(UserModel.className);
    query.equalTo(UserModel.fields.objectId, userId);
    const partner = (await query.first()) as UserModel;
    return partner;
  }

  public static async getPartnersByIds(ids: string[]): Promise<UserModel[]> {
    const query = new Parse.Query(UserModel.className);
    query.containedIn(UserModel.fields.objectId,ids);
    const partner = (await query.findAll()) as UserModel[];
    return partner;
  }


  public static async getUsersBelongsToAuction(auction: AstaModel): Promise<UserModel[]> {
    let users = await UserService.getPartners()
    users = users.filter(
        (user) => user.zoneCompetenza.province.find( (element) => element.codice === auction.comune.siglaProvincia));
    
        users = users.filter( (user) => user.status === StatusUser.attivo)
    return users;
  }
}
