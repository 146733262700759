import { NotificaModel } from 'src/parse/models/notifica.model';
import { NotificheService } from 'src/parse/services/notifiche.service';
import BaseSubscriber, { SubscriptionProps } from './BaseSubscriber';

export default function NotificationSubscriber({
  onNew: onCreate,
  onUpdate,
  onDelete,
  onSnapshot: onOpenSubscription,
}: SubscriptionProps<NotificaModel>) {
  const query = NotificheService.getNotificationSubscriptionQuery();

  return (
    <BaseSubscriber<NotificaModel>
      query={query}
      loggingKey="Notification"
      onNew={onCreate}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onSnapshot={onOpenSubscription}
    />
  );
}
