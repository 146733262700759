import { Badge, Box, Button, Divider } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
import useApplicationSettings from 'src/hooks/useApplicationSettings';
import useAuth from 'src/hooks/useAuth';
import { NotificaModel } from 'src/parse/models/notifica.model';
import { RootState } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import NotificationsList from './NotificationsList';
import MenuPopover from 'src/components/menu-popover';

export type INotificationPopoverProps = {};

const NotificationsPopover: React.FC<INotificationPopoverProps> = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  
  const { notificationsEnabled } = useApplicationSettings();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleViewAll = () => {
    navigate(PATH_DASHBOARD.notifications.root, { replace: true });
    setOpen(null);
  };

  const notifications = useSelector<RootState, NotificaModel[]>(
    (state) => state.notification.notifications
  );

  const unread = notifications.filter((notification) => !notification.lettoDa.includes(user!.id));
  const totalUnRead = unread.length;

  const handleOnClickItem = (notifications: NotificaModel) => {
    setOpen(null);
  };

  return (
    <>
      {notificationsEnabled && (
        <>
          <IconButtonAnimate
            color={open ? 'primary' : 'default'}
            onClick={handleOpen}
            sx={{ width: 40, height: 40 }}
          >
            <Badge badgeContent={totalUnRead} color="error">
              <Iconify icon="eva:bell-fill" width={20} height={20} />
            </Badge>
          </IconButtonAnimate>

          <MenuPopover
            open={open}
            anchorEl={open}
            onClose={handleClose}
            sx={{ overflow: 'auto', width: 360, p: 0, mt: 1.5, ml: 0.75 }}
          >
            <NotificationsList
              onClickItem={handleOnClickItem}
              maxRead={2}
              notifications={notifications}
            />

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Box sx={{ p: 1 }}>
              <Button onClick={handleViewAll} fullWidth disableRipple>
                Mostra tutto
              </Button>
            </Box>
          </MenuPopover>
        </>
      )}
    </>
  );
};

export default NotificationsPopover;
