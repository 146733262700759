import * as Parse from 'parse';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { useNavigate } from 'react-router';
import { PATH_AUTH } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import { handleParseError } from 'src/utils/parseErrorHandler';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("E' richiesta la vecchia password"),
    newPassword: Yup.string()
      .min(8, 'La password deve essere almeno di 8 caratteri')
      .required("E' richiesta una nuova password"),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'Le password devono combaciare'
    ),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    //console.log('data: ', data);
    try {
      await Parse.Cloud.run('changePassword', {
        oldPassword: data.oldPassword,
        password: data.newPassword,
      });
      //console.log('response: ', response);
      reset();
      enqueueSnackbar('Password aggiornata!');
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      const errorMessage = handleParseError(error, dispatch);
      enqueueSnackbar(errorMessage, { variant: 'error' });

    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField name="oldPassword" type="password" label="Vecchia Password" />
          <RHFTextField name="newPassword" type="password" label="Nuova Password" />
          <RHFTextField
            name="confirmNewPassword"
            type="password"
            label="Conferma la nuova Password"
          />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Salva modifiche
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
