import { TipoRisorse } from "../models/interfaces/tipo-risorse.enum";
import { RisorsaModel } from "../models/risorsa.model";
import * as Parse from 'parse';

export class ResourcesService {
    public static buildBaseResourceQuery() {
        const query = new Parse.Query(RisorsaModel.className);
        query.include(RisorsaModel.fields.updatedAt); 
        query.include(RisorsaModel.fields.createdAt); 
        query.include(RisorsaModel.fields.nome);
        query.include(RisorsaModel.fields.tipo);
        query.include(RisorsaModel.fields.contentFile);
        query.include(RisorsaModel.fields.contentString); 
        query.include(RisorsaModel.fields.ACL); 
        return query;
    }

    public static async getResources(): Promise<RisorsaModel[]> {
        const query = ResourcesService.buildBaseResourceQuery();
        const servicers = await query.findAll() as RisorsaModel[];
        return servicers;
    }

    public static async getResourcesByType(type: TipoRisorse): Promise<RisorsaModel[]> {
        const query = ResourcesService.buildBaseResourceQuery();
        query.equalTo(RisorsaModel.fields.tipo, type);
        const servicers = await query.findAll() as RisorsaModel[];
        return servicers;
    }

    public static getResourceByTypeSubscription(type: TipoRisorse) {
        const query = ResourcesService.buildBaseResourceQuery();
        query.equalTo(RisorsaModel.fields.tipo, type);
        query.findAll()
        return query;
    }

    public static getResourceSubscription() {
        const query = ResourcesService.buildBaseResourceQuery();
        query.findAll()
        return query;
    }
}