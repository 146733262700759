import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILoadingStatus {
  isLoading: boolean;
  error: Error | string | null;
}

export interface ILoadingState {
  auctionStatus: ILoadingStatus;
  provinceStatus: ILoadingStatus;
  typologyStatus: ILoadingStatus;
}

const initialState: ILoadingState = {
  auctionStatus: { isLoading: false, error: null },
  provinceStatus: { isLoading: false, error: null },
  typologyStatus: { isLoading: false, error: null },
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    startAuctionLoading(state) {
      state.auctionStatus.isLoading = true;
    },

    stopAuctionLoading(state) {
      state.auctionStatus.isLoading = false;
    },

    setAuctionError(state, action: PayloadAction<Error | string | null>) {
      state.auctionStatus.isLoading = false;
      state.auctionStatus.error = action.payload;
    },

    startProvinceLoading(state) {
      state.provinceStatus.isLoading = true;
    },

    stopProvinceLoading(state) {
      state.provinceStatus.isLoading = false;
    },

    setProvinceError(state, action: PayloadAction<Error | string | null>) {
      state.provinceStatus.isLoading = false;
      state.provinceStatus.error = action.payload;
    },

    startTypologhyLoading(state) {
      state.typologyStatus.isLoading = true;
    },

    stopTypologhyLoading(state) {
      state.typologyStatus.isLoading = false;
    },

    setTypologhyError(state, action: PayloadAction<Error | string | null>) {
      state.typologyStatus.isLoading = false;
      state.typologyStatus.error = action.payload;
    },
  },
});

export const globalStateActions = globalSlice.actions;

export default globalSlice.reducer;
