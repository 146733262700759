import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import { SubscriptionService } from 'src/parse/services/subscription.service';
import { LoadingButton } from '@mui/lab';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuctionLogger } from 'src/utils/loggerManager';

export type ISubscriptionsDownloadProps = {};

type FormValuesProps = {
  date: Date;
};

const SubscriptionsDownload: React.FC<ISubscriptionsDownloadProps> = () => {
  const nominalNameSchema = Yup.object().shape({
    date: Yup.date()
      .required('La Data non può essere vuota')
      .nullable()
      .typeError('Data non valida'),
  });

  const defaultValues = {
    date: new Date(),
  };

  const methods = useForm({
    resolver: yupResolver(nominalNameSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    AuctionLogger('Partner', 'UI Action', `Export subscriptions data start`);

    AuctionLogger('Partner', 'FCALL', 'Ask for subscriptions: ', data);

    const subscriptions = await SubscriptionService.getSubscription(
      data.date.getMonth() + 1,
      data.date.getFullYear()
    );

    AuctionLogger('Partner', 'FRES', 'Subscriptions received ', subscriptions);
    const data2export: string[][] = [];
    const titles = [
      'Nome',
      'Cognome',
      'Nominativo',
      'E-Mail',
      'Province',
      'Province Con Aste',
      'Prezzo Stimato',
    ];
    data2export.push(titles);

    subscriptions.forEach((subscription) => {
      const elements = [];
      elements.push(subscription.nome);
      elements.push(subscription.cognome);
      elements.push(subscription.nominativo);
      elements.push(subscription.email);
      elements.push(subscription.province.join(' '));
      elements.push(subscription.provinceConAste.join(' '));
      elements.push(subscription.prezzoStimato + '');
      data2export.push(elements);
    });

    let csvContent =
      'data:text/csv;charset=utf-8,' + data2export.map((e) => e.join('|')).join('\n');

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'abbonamenti.csv');
    document.body.appendChild(link);
    link.click();

    AuctionLogger('Partner', 'UI Action', `Export subscriptions data complete`);
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Tooltip title="Scarica i guadagni in formato CSV per il mese selezionato">
            <>
              <LoadingButton
                type="submit"
                variant="outlined"
                loading={isSubmitting}
                disabled={!methods.formState.isValid}
                startIcon={<Iconify icon="ri:money-euro-circle-fill" />}
              >
                Abbonamenti
              </LoadingButton>
            </>
          </Tooltip>

          <Controller
            key="date"
            name="date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                <DatePicker
                  label="Data"
                  views={['year', 'month']}
                  openTo={'year'}
                  value={field.value}
                  onChange={(newValue: Date | null) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      sx={{
                        width: '12rem',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Stack>
      </FormProvider>
    </>
  );
};
export default SubscriptionsDownload;
