import { useEffect, useState } from 'react';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material';
// components
import { IconButtonAnimate } from '../../../components/animate';

import useAuth from 'src/hooks/useAuth'
import { PATH_AUTH } from 'src/routes/paths'
import useIsMountedRef from 'src/hooks/useIsMountedRef'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { UserModel } from 'src/parse/models/user.model'
import createAvatar from 'src/utils/createAvatar'
import { BroadcastChannel } from 'broadcast-channel';
import { LoginTypes, LOGIN_LOGOUT_CHANNEL_NAME } from 'src/contexts/ParseContext';
import { AuctionLogger } from 'src/utils/loggerManager';
import { useDispatch } from 'react-redux';
import { handleParseError } from 'src/utils/parseErrorHandler';
import { kpiStateActions } from 'src/redux/slices/kpiSlice';
import MenuPopover from 'src/components/menu-popover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Account',
    linkTo: '/utente/account',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const { logout, onLogout } = useAuth();  
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  
  const user = UserModel.current() as UserModel

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel<LoginTypes>>();

  useEffect(() => {
    //once the login provider has been mounted, create a broadcast
    //channel to listen Login/Logout events from other opened tab
    if (!broadcastChannel) {
      const channel = new BroadcastChannel<LoginTypes>(LOGIN_LOGOUT_CHANNEL_NAME);
      setBroadcastChannel(channel);
      channel.onmessage = (message) => {
        AuctionLogger('Login', 'Broadcast', 'Received message from broadcast channel', message);
        if (message === LoginTypes.Logout) {
          //here I am receiving a logout event, so update the context
          //to pass the logged guard and then go to main login page
          onLogout();
          navigate(PATH_AUTH.login, { replace: true });
          //message consumend, close the channel
          AuctionLogger('Login', 'Broadcast', 'Close broadcast channel', broadcastChannel);
          channel.close();
        }
      };
      AuctionLogger('Login', 'Broadcast', 'Create broadcast channel');
    }
  }, [broadcastChannel, navigate, onLogout]);

   const handleLogout = async () => {
    try {
      await logout();
      dispatch(kpiStateActions.reset());
      
      navigate(PATH_AUTH.login, { replace: true });

      if (broadcastChannel) {
        AuctionLogger('Login', 'Broadcast', 'Post value broadcast channel', LoginTypes.Logout);
        broadcastChannel.postMessage(LoginTypes.Logout);
        //message sent, close the channel
        AuctionLogger('Login', 'Broadcast', 'Close broadcast channel', broadcastChannel);
        broadcastChannel.close();
      }

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
//      console.error(error);
      const errorMessage = handleParseError(error, dispatch, 'Impossibile eseguire la logout');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        data-testid="account-popover-main-user-icon-button"
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={user.fotoProfilo?.url()}
          alt={user.anagrafica?.nome + ' ' + user.anagrafica?.cognome}
        >
          {createAvatar(user?.anagrafica?.nome + ' ' + user?.anagrafica?.cognome).name}
        </Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.anagrafica?.nome + ' ' + user.anagrafica?.cognome}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label}
                      onClick={handleClose}
                      to={option.linkTo}
                      component={RouterLink}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem data-testid="account-popover-main-user-exit-menu-item" onClick={handleLogout} sx={{ m: 1 }}>
          Esci
        </MenuItem>
      </MenuPopover>
    </>
  );
}
