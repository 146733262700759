import { useState } from 'react';
// @mui
import { Button, MenuItem, Typography } from '@mui/material';

// components
import Iconify from '../../../components/Iconify';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { uiStateActions } from 'src/redux/slices/uiSlice';
import MenuPopover from 'src/components/menu-popover';

export const SORT_BY_PRICE_DESC_KEY = 'offertaMinimaDesc'
export const SORT_BY_PRICE_ASC_KEY = 'offertaMinimaAsc'
export const SORT_BY_DATE_ASC_KEY = 'dataAstaAsc'
export const SORT_BY_DATE_DESC_KEY = 'dataAstaDesc'

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  //{ value: 'piuRecente', label: 'Più recente' },
  { value: SORT_BY_PRICE_DESC_KEY, label: 'Prezzo più alto' },
  { value: SORT_BY_PRICE_ASC_KEY, label: 'Prezzo più basso' },
  { value: SORT_BY_DATE_ASC_KEY, label: 'Data Asta più vicina' },
  { value: SORT_BY_DATE_DESC_KEY, label: 'Data Asta più lontana' },
];

function renderLabel(label: string | null) {
   
    if (label === SORT_BY_PRICE_DESC_KEY) {
        return 'Prezzo più alto';
    }
    if (label === SORT_BY_PRICE_ASC_KEY) {
        return 'Prezzo più basso';
    }
    if (label === SORT_BY_DATE_ASC_KEY) {
      return 'Data asta più vicina';
    }
    if (label === SORT_BY_DATE_DESC_KEY) {
      return 'Data asta più lontana';
    }

  return 'Data Asta più lontana';
}

// ----------------------------------------------------------------------

export default function AsteSort() {
  const dispatch = useDispatch();

  const sortBy = useSelector<RootState, string | null>((state) => state.ui.sortBy);

  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (currentTarget: HTMLButtonElement) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSortBy = (value: string) => {
    handleClose();
    dispatch(uiStateActions.setSorting(value));
  };

  return (
    <>
      <Button
        // disabled // TODO
        color="inherit"
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        Ordinamento:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {renderLabel(sortBy)}
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={open}
        onClose={handleClose}
        sx={{
          width: 'auto',
          '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortBy}
            onClick={() => handleSortBy(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
