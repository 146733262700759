import * as Parse from 'parse';
import { TipologiaGuadagnoModel } from '../models/tipologia-guadagno.model'

export class TipologieGuadagnoService {

    public static async getTypologies(): Promise<TipologiaGuadagnoModel[]> {
        const query = new Parse.Query(TipologiaGuadagnoModel.className);
        query.include(TipologiaGuadagnoModel.fields.nome);
        query.include(TipologiaGuadagnoModel.fields.descrizione);
        query.include(TipologiaGuadagnoModel.fields.icona);
        query.include(TipologiaGuadagnoModel.fields.feePercDeposito);
        query.include(TipologiaGuadagnoModel.fields.feePercRilancio); 
        query.include(TipologiaGuadagnoModel.fields.feePercAggiudicazione); 
        query.include(TipologiaGuadagnoModel.fields.feePercPartecipazioneDiretta); 
        query.include(TipologiaGuadagnoModel.fields.feeFissa); 
        query.include(TipologiaGuadagnoModel.fields.limiteGuadagno); 
        query.include(TipologiaGuadagnoModel.fields.updatedAt); 
        query.include(TipologiaGuadagnoModel.fields.createdAt); 
        const typologies = await query.findAll() as TipologiaGuadagnoModel[];
        return typologies;
    }

    public static async getTypologyById(typologyId: string): Promise<TipologiaGuadagnoModel> {
        const query = new Parse.Query(TipologiaGuadagnoModel.className);
        query.include(TipologiaGuadagnoModel.fields.nome);
        query.include(TipologiaGuadagnoModel.fields.descrizione);
        query.include(TipologiaGuadagnoModel.fields.icona);
        query.include(TipologiaGuadagnoModel.fields.feePercDeposito);
        query.include(TipologiaGuadagnoModel.fields.feePercRilancio); 
        query.include(TipologiaGuadagnoModel.fields.feePercAggiudicazione); 
        query.include(TipologiaGuadagnoModel.fields.feePercPartecipazioneDiretta); 
        query.include(TipologiaGuadagnoModel.fields.feeFissa); 
        query.include(TipologiaGuadagnoModel.fields.limiteGuadagno); 
        query.include(TipologiaGuadagnoModel.fields.updatedAt); 
        query.include(TipologiaGuadagnoModel.fields.createdAt); 
        query.equalTo(TipologiaGuadagnoModel.fields.objectId, typologyId);
        const typology = await query.first() as TipologiaGuadagnoModel;
        return typology;
    }

}
