import * as Parse from 'parse';

export class TipologiaImmobileModel extends Parse.Object {
    
    public static className = "TipologieImmobile";

    public static fields = {
       objectId: "objectId",
       createdAt: "createdAt",
       updatedAt: "updatedAt",
       ACL: "ACL",
       nome: "nome",
       sinonimi: "sinonimi"
   };
    
    constructor() {
        super(TipologiaImmobileModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(TipologiaImmobileModel.className, TipologiaImmobileModel);
    }

    public get objectId(): string {
        return this.get(TipologiaImmobileModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(TipologiaImmobileModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(TipologiaImmobileModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(TipologiaImmobileModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(TipologiaImmobileModel.fields.ACL, ACL);
    }

    public get nome(): string {
        return this.get(TipologiaImmobileModel.fields.nome);
    }

    public set nome(nome: string) {
        this.set(TipologiaImmobileModel.fields.nome, nome);
    }
    
    public get sinonimi(): string[] {
        return this.get(TipologiaImmobileModel.fields.sinonimi);
    }

    public set sinonimi(sinonimi: string[]) {
        this.set(TipologiaImmobileModel.fields.sinonimi, sinonimi);
    }

}

