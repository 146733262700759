import { Stack, Tooltip } from '@mui/material';
import { ListPartnerItem } from 'src/@types/partner';
import { IconButtonAnimate } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
import { formatDateITLocale } from 'src/utils/formatDate';
import { AuctionLogger } from 'src/utils/loggerManager';

export type IPartnersDownloadProps = {
  partners: ListPartnerItem[];
};

const PartnersDownload: React.FC<IPartnersDownloadProps> = ({ partners }) => {
  const handleDownload = (partners: ListPartnerItem[]) => {
    AuctionLogger('Partner', 'UI Action', `Export partner data start`);
    const data2export: string[][] = [];
    const titles = [
      'Nome',
      'Cognome',
      'E-Mail',
      'Tipologia',
      'Stato',
      'Referente',
      'Zone di Competenza',
      'Data Creazione',
    ];
    data2export.push(titles);

    partners.forEach((partner) => {
      const elements = [];
      elements.push(partner.nome);
      elements.push(partner.cognome);
      elements.push(partner.email);
      elements.push(partner.tipologiaPartner);
      elements.push(partner.status);
      elements.push( (partner.referente && partner.referente.anagrafica) ?  `${partner.referente.anagrafica.nome} ${partner.referente.anagrafica.cognome}` : '-');
      elements.push(partner.zoneCompetenza.province.map(function(elem){
        return elem.codice;
    }).join(","));
      elements.push(formatDateITLocale(partner.dataCreazione));
      data2export.push(elements);
    });

    let csvContent =
      'data:text/csv;charset=utf-8,' + data2export.map((e) => e.join('|')).join('\n');

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'partners.csv');
    document.body.appendChild(link);
    link.click();
    AuctionLogger('Partner', 'UI Action', `Export partner data complete`);
  };

  return (
    <Stack sx={{ alignSelf: 'center' }}>
      <Tooltip title="Scarica i partner in formato CSV">
        <IconButtonAnimate onClick={() => handleDownload(partners)} sx={{ width: 40, height: 40, alignSelf: 'center' }}>
          <Iconify
            icon="material-symbols:download-for-offline-outline-rounded"
            width={24}
            height={24}
            alignSelf={'center'}
          />
        </IconButtonAnimate>
      </Tooltip>
    </Stack>
  );
};
export default PartnersDownload;
