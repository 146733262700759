import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RisorsaModel } from 'src/parse/models/risorsa.model';
import { resourceStateActions } from 'src/redux/slices/resourceSlice';
import ResourceSubscriber from 'src/subscribers/ResourceSubscriber';

export type IMediaResourcesManager = {
  children: ReactNode;
};

const MediaResourcesManager: React.FC<IMediaResourcesManager> = ({ children }) => {
  const [resources, setResources] = useState<RisorsaModel[]>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resources) {
      dispatch(resourceStateActions.setResources(resources));
    }
  }, [dispatch, resources]);

  const handleResourceSubscription = (snapshot: RisorsaModel[]) => {
    setResources(snapshot);
  };

  const handleNewOrUpdateResource = (data: RisorsaModel) => {
    setResources((prevState) => {
      if (data !== null) {
        if (!prevState) {
          const newState: RisorsaModel[] = [data];
          return newState;
        } else {
          const index = prevState?.findIndex((item: RisorsaModel) => item.id === data.id);
          if (index === -1) {
            let copy = [...prevState];
            copy.push(data);
            return copy;
          } else {
            let copy = [...prevState];
            copy[index] = data;
            return copy;
          }
        }
      }
      return prevState;
    });
  };

  const handleDeleteResource = (data: RisorsaModel) => {
    setResources((prevState) => {
      if (prevState) {
        let copy = [...prevState];
        if (data !== null) {
          const index = prevState?.findIndex((item: RisorsaModel) => item.id === data.id);
          if (index !== -1) {
            copy.splice(index, 1);
          }
        }
        return copy;
      }
      return prevState;
    });
  };

  return (
    <>
      <ResourceSubscriber
        onNew={handleNewOrUpdateResource}
        onUpdate={handleNewOrUpdateResource}
        onDelete={handleDeleteResource}
        onSnapshot={handleResourceSubscription}
      />
      {children}
    </>
  );
};

export default MediaResourcesManager;
