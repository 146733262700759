import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Divider,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// hooks
import useTabs from '../hooks/useTabs';
import useSettings from '../hooks/useSettings';
import useTable from '../hooks/useTable';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  getComparator,
  emptyRows,
  TableColumnDefinition,
} from '../components/table';
// sections
import { ServicerTableToolbar, ServicerTableRow } from '../sections/@dashboard/servicers/list';
import { ListServicerItem } from 'src/@types/servicer';
import { SkeletonServicers } from 'src/components/skeleton';
import { ServicersService } from '../parse/services/servicers.service';
import STATUS_OPTIONS, { STATUS_OPTION_ACTIVE, STATUS_OPTION_ALL } from './common/TabNames';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nomeVisualizzato', label: 'Nome', align: 'left' },
  { id: 'feeReviva', label: '% Fee reviva', align: 'left' },
  { id: 'tipologiaGuadagno', label: 'Tipologia guadagno', align: 'left' },
  { id: 'abilitato', label: 'Stato', align: 'center' },
] as TableColumnDefinition[];

// ----------------------------------------------------------------------

export default function Servicers() {

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [tableData, setTableData] = useState<ListServicerItem[]>([]);

  const [filterServicer, setFilterServicer] = useState('');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(STATUS_OPTION_ACTIVE);

  useEffect(() => {
    const fetchServicers = async () => {
      setLoading(true);
      const servicers = await ServicersService.getServicers();
      const listServicers = servicers.map(
        (servicer) =>
          ({
            denominazione: servicer.denominazione,
            nomeVisualizzato: servicer.nomeVisualizzato,
            abilitato: servicer.abilitato,
            feeReviva: servicer.feeReviva,
            tipologiaGuadagnoIcona: servicer.tipologiaGuadagno?.icona,
            tipologiaGuadagnoNome: servicer.tipologiaGuadagno?.nome,
            servicerId: servicer.id,
          } as ListServicerItem)
      );

      listServicers.sort((a, b) =>
        a.nomeVisualizzato.toLowerCase() > b.nomeVisualizzato.toLowerCase() ? 1 : -1
      );

      setTableData(listServicers);
      setLoading(false);
    };

    fetchServicers().catch(console.error);
  }, []);

  const handleFilterServicer = (filterServicer: string) => {
    setFilterServicer(filterServicer);
    setPage(0);
  };

  const handleDetailServicerClick = (id: string) => {
    navigate(PATH_DASHBOARD.servicers.edit(id));
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterServicer,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterServicer) || (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Servicers">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Servicers"
          links={[
            { name: 'Servicers', href: PATH_DASHBOARD.servicers.root },
          ]}
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <ServicerTableToolbar
            filterServicer={filterServicer}
            onFilterServicer={handleFilterServicer}
          />

          <Scrollbar>
            {loading ? (
              <SkeletonServicers />
            ) : (
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    tableColumns={TABLE_HEAD}
                    rowCount={tableData.length}
                    onSort={onSort}
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                        <ServicerTableRow
                          key={row.servicerId}
                          row={row}
                          onDetailServicerClick={() => handleDetailServicerClick(row.servicerId)}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelDisplayedRows={(page) =>
                `${page.from}-${page.to === -1 ? page.count : page.to} di ${page.count}`
              }
              labelRowsPerPage="Righe per pagina"
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Compatto"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterServicer,
  filterStatus,
}: {
  tableData: ListServicerItem[];
  comparator: (a: any, b: any) => number;
  filterServicer: string;
  filterStatus: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterServicer) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.nomeVisualizzato?.toLowerCase().indexOf(filterServicer.toLowerCase()) > -1 ||
        item.feeReviva?.toString().indexOf(filterServicer.toLowerCase()) > -1 ||
        item.tipologiaGuadagnoNome?.toLowerCase().indexOf(filterServicer.toLowerCase()) > -1
    );
  }

  if (filterStatus !== STATUS_OPTION_ALL) {
    let mappedFilterStatus = false;
    if (filterStatus === STATUS_OPTION_ACTIVE) {
      mappedFilterStatus = true;
    }
    tableData = tableData.filter(
      (item: Record<string, any>) => item.abilitato === mappedFilterStatus
    );
  }

  return tableData;
}
