import { ComuniService } from '../../parse/services/comuni.service';
import { staticDataStateActions } from '../slices/staticDataSlice';
import { globalStateActions } from '../slices/globalSlice';
import { AppThunk } from '../store';
import { TipologieImmobileService } from 'src/parse/services/tipologie-immobile.service';
import { handleParseError } from 'src/utils/parseErrorHandler';

export const fetchProvince = (): AppThunk => async (dispatch) => {
  dispatch(globalStateActions.startProvinceLoading());
  try {
    const province = await ComuniService.getProvince();
    dispatch(staticDataStateActions.setProvince(province));
    dispatch(globalStateActions.stopProvinceLoading());
  } catch (error) {
    dispatch(globalStateActions.stopProvinceLoading());
    dispatch(globalStateActions.setProvinceError(error));
    handleParseError(error, dispatch);
  }
};

export const fetchTypology = (): AppThunk => async (dispatch) => {
  dispatch(globalStateActions.startTypologhyLoading());
  try {
    const typologyObjects = await TipologieImmobileService.getTypologies();
    const typologyNames = typologyObjects.map((typologyObj) => typologyObj.nome);
    typologyNames.sort();

    dispatch(staticDataStateActions.setTypology(typologyNames));
    dispatch(globalStateActions.stopTypologhyLoading());
  } catch (error) {
    dispatch(globalStateActions.stopTypologhyLoading());
    dispatch(globalStateActions.setTypologhyError(error));
    handleParseError(error, dispatch);
  }
};
