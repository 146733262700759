import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { AuctionLogger } from 'src/utils/loggerManager';
import * as Parse from 'parse';
import { TipologiaPartner } from 'src/parse/models/interfaces/tipologie-partner';
import { CADASTRAL_SURVAY_KEY, CADASTRAL_SURVAY_VALUE, DEPOSIT_NOTE_KEY, DEPOSIT_NOTE_VALUE, FileTag, IDENTITY_CARD_KEY, IDENTITY_CARD_VALUE, OTHER_KEY, OTHER_VALUE, PASSPORT_KEY, PASSPORT_VALUE, RESIDENCY_PERMIT_KEY, RESIDENCY_PERMIT_VALUE, WIRE_TRANSFERT_KEY, WIRE_TRANSFERT_VALUE } from 'src/parse/models/interfaces/file-tag.interface';

interface IApplicationSettingsContext {
  shareAuctionsEnabled: boolean;
  maxNoOfDaysForCommunication: number;
  warningDaysForCommunication: number;
  errorDaysForCommunication: number;
  notificationsEnabled: boolean;
  typologiesOfPartner: TipologiaPartner[];
  visibilityDaysMax: number;
  visibilityDaysMin: number;
  fileTags: FileTag[];
}

const ApplicationSettingsContext = React.createContext({
  shareAuctionsEnabled: false,
  maxNoOfDaysForCommunication: 0,
  notificationsEnabled: false,
  errorDaysForCommunication: 4,
  warningDaysForCommunication: 6,
  visibilityDaysMax: 60,
  visibilityDaysMin: -30,
  typologiesOfPartner: [
    { id: 'agente', valore: 'Agente', icona: 'map:real-estate-agency' },
    { id: 'consulente', valore: 'Consulente', icona: 'ic:baseline-support-agent' },
  ],
} as IApplicationSettingsContext);

interface ApplicationSettingsContextProps {
  children: ReactNode;
}

const COMMUNICATION_DAYS_MAX_ERROR = 'giorniComunicazioneNominativoErrore';
const COMMUNICATION_DAYS_MAX = 'giorniComunicazioneNominativoMax';
const COMMUNICATION_DAYS_WARNING = 'giorniComunicazioneNominativoWarning';
const NOTIFICATION_ENABLED = 'notificationsEnabled';
const SHARING_ENABLED = 'shareAuctionsEnabled';
const PARTNERS_TYPE = 'tipologiePartner';
const FILE_TAGS = 'fileTags';
const VISIBILITY_DAYS_MAX = 'giorniVisibilitaMax';
const VISIBILITY_DAYS_MIN = 'giorniVisibilitaMin';

const FILE_TAGS_DEFAULTS = [
  { key: IDENTITY_CARD_KEY, value: IDENTITY_CARD_VALUE },
  { key: PASSPORT_KEY, value: PASSPORT_VALUE },
  { key: RESIDENCY_PERMIT_KEY, value: RESIDENCY_PERMIT_VALUE },
  { key: CADASTRAL_SURVAY_KEY, value: CADASTRAL_SURVAY_VALUE },
  { key: WIRE_TRANSFERT_KEY, value: WIRE_TRANSFERT_VALUE },
  { key: DEPOSIT_NOTE_KEY, value: DEPOSIT_NOTE_VALUE },
  { key: OTHER_KEY, value: OTHER_VALUE },
];

export const ApplicationSettingsProvider: FunctionComponent<ApplicationSettingsContextProps> = (
  props
) => {
  const [maxNoOfDaysForCommunication, setMaxNoOfDaysForCommunication] = useState(0);
  const [warningDaysForCommunication, setWarningDaysForCommunication] = useState(0);
  const [errorDaysForCommunication, setErrorDaysForCommunication] = useState(0);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [shareAuctionsEnabled, setShareAuctionsEnabled] = useState(false);
  const [typologiesOfPartner, setTypologiesOfPartner] = useState([
    { id: 'agente', valore: 'Agente', icona: 'map:real-estate-agency' },
    { id: 'consulente', valore: 'Consulente', icona: 'ic:baseline-support-agent' },
  ]);
  const [fileTags, setFileTags] = useState(FILE_TAGS_DEFAULTS);
  const [visibilityDaysMax, setVisibilityDaysMax] = useState(60);
  const [visibilityDaysMin, setVisibilityDaysMin] = useState(-30);

  useEffect(() => {
    Parse.Config.get().then(
      function (config) {
        AuctionLogger('Settings', 'Data', 'Config was fetched from the server', config);

        const noOfDays = config.get(COMMUNICATION_DAYS_MAX);
        setMaxNoOfDaysForCommunication(noOfDays);

        let errorNoOfDays = config.get(COMMUNICATION_DAYS_MAX_ERROR);
        if (errorNoOfDays === undefined) {
          errorNoOfDays = 4;
        }
        setErrorDaysForCommunication(errorNoOfDays);

        let warningNoOfDays = config.get(COMMUNICATION_DAYS_WARNING);
        if (warningNoOfDays === undefined) {
          warningNoOfDays = 6;
        }
        setWarningDaysForCommunication(warningNoOfDays);

        const notificationsEnabled = config.get(NOTIFICATION_ENABLED);
        setNotificationsEnabled(notificationsEnabled);

        const shareAuctionsEnabled = config.get(SHARING_ENABLED);
        setShareAuctionsEnabled(shareAuctionsEnabled);

        const partnerTypologies = config.get(PARTNERS_TYPE);
        setTypologiesOfPartner(partnerTypologies);

        const fileTags = config.get(FILE_TAGS);
        setFileTags(fileTags);

        let visibilityDaysMax = config.get(VISIBILITY_DAYS_MAX);
        if (visibilityDaysMax === undefined) {
          visibilityDaysMax = 60;
        }
        setVisibilityDaysMax(visibilityDaysMax);

        let visibilityDaysMin = config.get(VISIBILITY_DAYS_MIN);
        if (visibilityDaysMin === undefined) {
          visibilityDaysMin = -30;
        }
        setVisibilityDaysMin(visibilityDaysMin);
      },
      function (error) {
        const config = Parse.Config.current();
        AuctionLogger('Settings', 'Data', 'Failed to fetch. Using Cached Config', config);

        let cachedNoOfDays = config.get(COMMUNICATION_DAYS_MAX);
        if (cachedNoOfDays === undefined) {
          cachedNoOfDays = 3;
        }

        let chachedErrorNoOfDays = config.get(COMMUNICATION_DAYS_MAX_ERROR);
        if (chachedErrorNoOfDays === undefined) {
          chachedErrorNoOfDays = 4;
        }

        let cachedWarningNoOfDays = config.get(COMMUNICATION_DAYS_WARNING);
        if (cachedWarningNoOfDays === undefined) {
          cachedWarningNoOfDays = 6;
        }

        let cachedNotificationsEnabled = config.get(NOTIFICATION_ENABLED);
        if (cachedNotificationsEnabled === undefined) {
          cachedNotificationsEnabled = false;
        }

        let cachedShareAuctionsEnabled = config.get(SHARING_ENABLED);
        if (cachedShareAuctionsEnabled === undefined) {
          cachedShareAuctionsEnabled = false;
        }

        let cachedPartnerTypologies = config.get(PARTNERS_TYPE);
        if (cachedPartnerTypologies === undefined) {
          cachedPartnerTypologies = [
            { id: 'agente', valore: 'Agente', icona: 'map:real-estate-agency' },
            { id: 'consulente', valore: 'Consulente', icona: 'ic:baseline-support-agent' },
          ];
        }

        let cachedFileTags = config.get(FILE_TAGS);
        if (cachedFileTags === undefined) {
          cachedFileTags = FILE_TAGS_DEFAULTS;
        }

        let chachedVisibilityDaysMax = config.get(VISIBILITY_DAYS_MAX);
        if (chachedVisibilityDaysMax === undefined) {
          chachedVisibilityDaysMax = 60;
        }

        let chachedVisibilityDaysMin = config.get(VISIBILITY_DAYS_MIN);
        if (chachedVisibilityDaysMin === undefined) {
          chachedVisibilityDaysMin = -30;
        }

        setMaxNoOfDaysForCommunication(cachedNoOfDays);
        setErrorDaysForCommunication(chachedErrorNoOfDays);
        setWarningDaysForCommunication(cachedWarningNoOfDays);
        setNotificationsEnabled(cachedNotificationsEnabled);
        setTypologiesOfPartner(cachedPartnerTypologies);
        setShareAuctionsEnabled(cachedShareAuctionsEnabled);
        setVisibilityDaysMax(chachedVisibilityDaysMax);
        setVisibilityDaysMin(chachedVisibilityDaysMin);
        setFileTags(cachedFileTags);
      }
    );
  }, []);

  return (
    <ApplicationSettingsContext.Provider
      value={{
        maxNoOfDaysForCommunication: maxNoOfDaysForCommunication,
        warningDaysForCommunication: warningDaysForCommunication,
        errorDaysForCommunication: errorDaysForCommunication,
        notificationsEnabled: notificationsEnabled,
        typologiesOfPartner: typologiesOfPartner,
        shareAuctionsEnabled: shareAuctionsEnabled,
        visibilityDaysMax: visibilityDaysMax,
        visibilityDaysMin: visibilityDaysMin,
        fileTags: fileTags,
      }}
    >
      {props.children}
    </ApplicationSettingsContext.Provider>
  );
};

export default ApplicationSettingsContext;
