// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextFieldProps, Select, Chip } from '@mui/material';
import { Box } from '@mui/system'


// ----------------------------------------------------------------------

type IProps = {
  name: string;
  chipColor: string;
  children: React.ReactNode;
};

type Props = IProps & TextFieldProps;

export default function RHFSelectMultiple({ name, chipColor, children, ...other }: Props) {
  const { control } = useFormContext();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Controller
      name={name}
      defaultValue={[]}
      control={control}
      render={({ field }) => (
        <Select
        {...field}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value: string) => (
              <Chip key={value} label={value} sx={{ backgroundColor: chipColor }}/>
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        >
         {children}
      </Select>

      )}
    />
  );
}
