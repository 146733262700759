import { Box, Grid, Skeleton } from "@mui/material";

export default function SkeletonServicersTable() {
  return (
    <Box
      sx={{
        height: "max-content",
        m: 1
      }}
    >
      {[...Array(5)].map((value, index) => (
        <Grid key={index} container spacing={2} alignItems="center" sx={{ my: "0.5" }}>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} sx={{ height: 40 }} />
          </Grid>
          <Grid item xs>
            <Skeleton key={index} width="30%" sx={{ height: 40 }} />
          </Grid>
        </Grid>
      ))
      }
    </Box >
  );
}
