import * as Parse from 'parse';
import { DirezioneInOutLogs } from "./interfaces/direzione-in-out-logs";
import { SistemaInOutLogs } from "./interfaces/sistema-in-out-logs";
import { TipoInOutLogs } from "./interfaces/tipo-in-out-logs";

export class InOutLogsModel extends Parse.Object {
    
    public static className = "InOutLogs";

    public static fields = {
       objectId: "objectId",
       createdAt: "createdAt",
       updatedAt: "updatedAt",
       ACL: "ACL",
       tipo: "tipo",
       sistema: "sistema",
       dataInvio: "dataInvio",
       direzione: "direzione",
       risultato: "risultato",
       statusCode: "statusCode",
       riferimenti: "riferimenti",
       statusDetail: "statusDetail"
   };
    
    constructor() {
        super(InOutLogsModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(InOutLogsModel.className, InOutLogsModel);
    }

    public get objectId(): string {
        return this.get(InOutLogsModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(InOutLogsModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(InOutLogsModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(InOutLogsModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(InOutLogsModel.fields.ACL, ACL);
    }

    public get tipo(): TipoInOutLogs {
        return this.get(InOutLogsModel.fields.tipo);
    }

    public set tipo(tipo: TipoInOutLogs) {
        this.set(InOutLogsModel.fields.tipo, tipo);
    }

    public get sistema(): SistemaInOutLogs {
        return this.get(InOutLogsModel.fields.sistema);
    }

    public set sistema(sistema: SistemaInOutLogs) {
        this.set(InOutLogsModel.fields.sistema, sistema);
    }

    public get dataInvio(): Date {
        return this.get(InOutLogsModel.fields.dataInvio);
    }

    public set dataInvio(dataInvio: Date) {
        this.set(InOutLogsModel.fields.dataInvio, dataInvio);
    }

    public get direzione(): DirezioneInOutLogs {
        return this.get(InOutLogsModel.fields.direzione);
    }

    public set direzione(direzione: DirezioneInOutLogs) {
        this.set(InOutLogsModel.fields.direzione, direzione);
    }

    public get risultato(): boolean {
        return this.get(InOutLogsModel.fields.risultato);
    }

    public set risultato(risultato: boolean) {
        this.set(InOutLogsModel.fields.risultato, risultato);
    }

    public get statusCode(): string {
        return this.get(InOutLogsModel.fields.statusCode);
    }

    public set statusCode(statusCode: string) {
        this.set(InOutLogsModel.fields.statusCode, statusCode);
    }

    public get riferimenti(): Parse.Pointer[] | Parse.Object[] {
        return this.get(InOutLogsModel.fields.riferimenti);
    }

    public set riferimenti(riferimenti: Parse.Pointer[] | Parse.Object[]) {
        this.set(InOutLogsModel.fields.riferimenti, riferimenti);
    }

    public get statusDetail(): string {
        return this.get(InOutLogsModel.fields.statusDetail);
    }

    public set statusDetail(statusDetail: string) {
        this.set(InOutLogsModel.fields.statusDetail, statusDetail);
    }
}