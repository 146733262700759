import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useApplicationSettings from 'src/hooks/useApplicationSettings';
import { NotificaModel } from 'src/parse/models/notifica.model';
import { notificationStateActions } from 'src/redux/slices/notificationSlice';
import NotificationSubscriber from 'src/subscribers/NotificationSubscriber';

export type INotificationManager = {
  children: ReactNode;
};

const NotificationsManager: React.FC<INotificationManager> = ({ children }) => {
  const { notificationsEnabled } = useApplicationSettings();
  const [notifications, setNotifications] = useState<NotificaModel[]>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (notifications) {
      dispatch(notificationStateActions.setNotifications(notifications));
    }
  }, [dispatch, notifications]);

  const handleOpenNotificationSubscription = (snapshot: NotificaModel[]) => {
    setNotifications(snapshot);
  };

  const handleNewOrUpdateNotification = (data: NotificaModel) => {
    setNotifications((prevState) => {
      if (data !== null) {
        if (!prevState) {
          const newState: NotificaModel[] = [data];
          return newState;
        } else {
          const index = prevState?.findIndex((item: NotificaModel) => item.id === data.id);
          if (index === -1) {
            let copy = [...prevState];
            copy.push(data);
            return copy;
          } else {
            let copy = [...prevState];
            copy[index] = data;
            return copy;
          }
        }
      }
      return prevState;
    });
  };

  const handleDeleteNotification = (data: NotificaModel) => {
    setNotifications((prevState) => {
      if (prevState) {
        let copy = [...prevState];
        if (data !== null) {
          const index = prevState?.findIndex((item: NotificaModel) => item.id === data.id);
          if (index !== -1) {
            copy.splice(index, 1);
          }
        }
        return copy;
      }
      return prevState;
    });
  };

  return (
    <>
      {notificationsEnabled && (
        <NotificationSubscriber
          onNew={handleNewOrUpdateNotification}
          onUpdate={handleNewOrUpdateNotification}
          onDelete={handleDeleteNotification}
          onSnapshot={handleOpenNotificationSubscription}
        />
      )}
      {children}
    </>
  );
};

export default NotificationsManager;
