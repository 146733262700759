import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ModalErrorDialog from './components/ModalErrorDialog';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import Page500 from './pages/Page500';


// ----------------------------------------------------------------------

const isProductionEnv = process.env.NODE_ENV === 'production';

Sentry.init({
  enabled: isProductionEnv,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || 'https://128203f1389e4d5ba18502e9be9eac25@o264833.ingest.sentry.io/4504729870336000',
  integrations: isProductionEnv ? [new Integrations.BrowserTracing()] : [],
  tracesSampleRate: 1.0,
});

export default function App() {
  return (
    <Sentry.ErrorBoundary fallback={<Page500 />}>
      <MotionLazyContainer>
        <ThemeProvider>
          <NotistackProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeProvider>
      </MotionLazyContainer>
      <ModalErrorDialog />
    </Sentry.ErrorBoundary>
  );
}
