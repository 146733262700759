import { RisorsaModel } from 'src/parse/models/risorsa.model';
import { ResourcesService } from 'src/parse/services/resources.service';
import BaseSubscriber, { SubscriptionProps } from './BaseSubscriber';

export default function ResourceSubscriber({
  onNew,
  onUpdate,
  onDelete,
  onSnapshot,
}: SubscriptionProps<RisorsaModel>) {
  const query = ResourcesService.getResourceSubscription();

  return (
    <BaseSubscriber<RisorsaModel>
      query={query}
      loggingKey="Resources"
      onNew={onNew}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onSnapshot={onSnapshot}
    />
  );
}
