export enum TipoNotifiche {
    ADMIN_NOMINATIVO = "ADMIN_NOMINATIVO",
    ADMIN_RICHIESTA_PROVINCE = "ADMIN_RICHIESTA_PROVINCE",
    ADMIN_IMPORT = "ADMIN_IMPORT",
    PARTNER_GUADAGNO_ADD = "PARTNER_GUADAGNO_ADD",
    PARTNER_GUADAGNO_EDIT = "PARTNER_GUADAGNO_EDIT",
    PARTNER_GUADAGNO_DELETE = "PARTNER_GUADAGNO_DELETE",
    PARTNER_BROADCAST = "PARTNER_BROADCAST",
    PARTNER_BROADCAST_RISORSA_ADD = "PARTNER_BROADCAST_RISORSA_ADD",
    PARTNER_PARTICIPATION_ADD = "PARTNER_PARTICIPATION_ADD",
    PARTNER_PARTICIPATION_ACCEPTED = "PARTNER_PARTICIPATION_ACCEPTED",
    PARTNER_PARTICIPATION_REJECTED = "PARTNER_PARTICIPATION_REJECTED",
    PARTNER_PARTICIPATION_LATE = "PARTNER_PARTICIPATION_LATE",
}
