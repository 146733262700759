import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { KPITimeScale } from 'src/@types/kpi';
import { TabStatusType } from 'src/pages/common/TabNames';
import { IKPIModel } from 'src/parse/models/interfaces/kpi-interface';
import { IKPIMetrica } from 'src/parse/models/interfaces/kpi-metrica.interface';
import { getQuarterFromDate, getSemesterFromDate } from 'src/utils/kpiTools';
import * as _ from 'underscore';
import { KPIInternalModel } from '../models/KPIInternalModel';

export interface IKPIState {
  dataAvailable: boolean;
  timeUnit: KPITimeScale;
  data: KPIInternalModel[];
  ui: IKPIDrilldown;
}

export interface IKPIDrilldown {
  ids: string[];
  drilldownVisible: boolean;
  showAuctions: boolean;
  showEarnings: boolean;
  showPartners: boolean;
  showProvince: boolean;
  tabStatusType: TabStatusType;
}

const initialState: IKPIState = {
  dataAvailable: false,
  timeUnit: KPITimeScale.MONTH,
  data: [],
  ui: {
    ids: [],
    drilldownVisible: false,
    showAuctions: false,
    showEarnings: false,
    showPartners: false,
    showProvince: false,
    tabStatusType: 'attivi',
  },
};

export function transformRawData(timeUnit: KPITimeScale, rawData: IKPIModel[]) {
  //sort raw data by date
  const sortedRawData = rawData.sort((a, b) => (a.dataRiferimento > b.dataRiferimento ? 1 : -1));
  //split raw data in bucket, grouped by year and month
  const rawDataByMonthBucket = _.groupBy(sortedRawData, function (obj) {
    return `${obj.dataRiferimento.getFullYear()}_${obj.dataRiferimento.getMonth()}`;
  });
  //console.log('[EB] [KPI] Transform Data STEP 1: split by month', rawDataByMonthBucket);
  //now extract for each bucket the last element which is the oldest one for each bucket
  const lastElementPerMonth: IKPIModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of Object.entries(rawDataByMonthBucket)) {
    var lastElement = value[value.length - 1];
    lastElementPerMonth.push(lastElement);
  }
  //console.log('[EB] [KPI] Transform Data STEP 2: get only last month element', lastElementPerMonth);
  //if time unit is monthly based, this is done, we can return the array of KPI to be then displayed by UI
  //otherwise we need to aggregate the KPI per semester, quarter or by year
  return timeUnit === KPITimeScale.MONTH
    ? convertToInternalModel(lastElementPerMonth)
    : aggregateKPI(timeUnit, lastElementPerMonth);
}

function convertToInternalModel(kpis: IKPIModel[]) {
  const result: KPIInternalModel[] = [];
  kpis.forEach((kpi) => {
    const kpiInternalModel = new KPIInternalModel();
    kpiInternalModel.objectId = kpi.id;
    kpiInternalModel.referenceDate = kpi.dataRiferimento;
    kpiInternalModel.kpis = kpi.metriche;
    result.push(kpiInternalModel);
  });
  //console.log('[EB] [KPI] Transform Data COMPLETE: data to display', result);
  return result;
}

function aggregateKPI(timeUnit: KPITimeScale, data2aggregate: IKPIModel[]) {
  //sort items in ascending orders, from newest to oldest
  const sortedData2Aggregate = _.sortBy(data2aggregate, function (obj) {
    return obj.dataRiferimento;
  }).reverse();

  let dataByAggregation = _.groupBy(sortedData2Aggregate, function (obj) {
    if (timeUnit === KPITimeScale.YEAR) return `${obj.dataRiferimento.getFullYear()}`;
    else if (timeUnit === KPITimeScale.SEMESTER)
      return `${obj.dataRiferimento.getFullYear()}_${getSemesterFromDate(obj.dataRiferimento)}`;
    //it returns the semester number given the date
    else if (timeUnit === KPITimeScale.QUARTER)
      return `${obj.dataRiferimento.getFullYear()}_${getQuarterFromDate(obj.dataRiferimento)}`;
    //it returns the quarter number given the date
    else return `${obj.dataRiferimento.getFullYear()}_${obj.dataRiferimento.getMonth()}`;
  });
  //console.log('[EB] [KPI] Transform Data STEP 3: timeUnit', timeUnit);
  //console.log('[EB] [KPI] Transform Data STEP 3: aggregate data', dataByAggregation);

  const finalKPI: KPIInternalModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, aggregatedKpi] of Object.entries(dataByAggregation)) {
    //value contains an array of KPI modal that need to be added up
    const item2Add = new KPIInternalModel();
    let cycleCounter = 0;
    aggregatedKpi.forEach((kpi: IKPIModel) => {
      item2Add.objectId = kpi.id;
      item2Add.referenceDate = kpi.dataRiferimento;
      kpi.metriche.forEach(function (singleMetric, key) {
        if (!item2Add.kpis.has(key)) {
          item2Add.kpis.set(key, singleMetric);
        } else {
          const existingValue = item2Add.kpis.get(key)!;
          const [ids, value] = calculateKpiValue(existingValue);
          //const existingValuae = sasaas(existingValue);
          existingValue.valore = value;
          existingValue.id = ids;
          item2Add.kpis.set(key, existingValue);
        }

        function calculateKpiValue(existingValue: IKPIMetrica): [string[], number] {
          const ids: string[] = [];
          const value = 0;
          //to calculate properly the aggregation of a KPI we need to take into account "delta" and "formato" attributes.
          //depending on the combinations, a different approach has to be taken:
          // - <cumulativo,percentuale> => "last KPI in time". In the current implementation this last KPI in time is the already added item (see code line #107)
          // - <cumulativo,numero> => "last KPI in time". In the current implementation this last KPI in time is the already added item (see code line #107)
          // - <mese,numero> => this should be the sum of all KPI in the current aggregation, removing the duplicates in the drilldown
          // - <mese,percentage> => this should be the avergae of all KPI in the current aggregation, removing the duplicates
          if (singleMetric.delta === 'cumulativo') {
            return [existingValue.id, existingValue.valore];
          } else if (singleMetric.delta === 'mese') {
            if (singleMetric.formato === 'numero') {
              const uniqueIds = existingValue.id
                .concat(singleMetric.id)
                .filter((value, index, array) => array.indexOf(value) === index);
              return [uniqueIds, singleMetric.valore + existingValue.valore];
            } else if (singleMetric.formato === 'percentuale') {
              const uniqueIds = existingValue.id
                .concat(singleMetric.id)
                .filter((value, index, array) => array.indexOf(value) === index);
              const incrementalAverageIndex = cycleCounter + 1;
              const average =
                (existingValue.valore * (incrementalAverageIndex - 1) + singleMetric.valore) /
                incrementalAverageIndex;
              return [uniqueIds, average];
            }
          }

          return [ids, value];
        }
      });
      cycleCounter = cycleCounter + 1;
    });
    finalKPI.push(item2Add);
  }

  //to keep visualization consistent (from the olrest to the newest) final KPI needs to be ordered in ascending order by referenceDate
  const sortedFinalKPI = _.sortBy(finalKPI, function (kpi) {
    return kpi.referenceDate;
  });
  //console.log('[EB] [KPI] Transform Data COMPLETE: data to display', sortedFinalKPI);
  return sortedFinalKPI;
}

function applyDrilldownState(
  state: Draft<IKPIState>,
  ids: string[],
  visibility?: {
    showAuctions?: boolean;
    showEarnings?: boolean;
    showPartners?: boolean;
    showProvince?: boolean;
  }
) {
  state.ui.ids = ids;
  state.ui.showAuctions = visibility
    ? visibility.showAuctions
      ? visibility.showAuctions
      : false
    : false;
  state.ui.showEarnings = visibility
    ? visibility.showEarnings
      ? visibility.showEarnings
      : false
    : false;
  state.ui.showPartners = visibility
    ? visibility.showPartners
      ? visibility.showPartners
      : false
    : false;
  state.ui.showProvince = visibility
    ? visibility.showProvince
      ? visibility.showProvince
      : false
    : false;
  state.ui.drilldownVisible =
    state.ui.showAuctions ||
    state.ui.showEarnings ||
    state.ui.showPartners ||
    state.ui.showProvince;
}

const kpiSlice = createSlice({
  name: 'kpi',
  initialState: initialState,
  reducers: {
    reset(state) {
      state.dataAvailable = false;
      state.timeUnit = KPITimeScale.MONTH;
      state.data = [];
      state.ui = {
        ids: [],
        drilldownVisible: false,
        showAuctions: false,
        showEarnings: false,
        showPartners: false,
        showProvince: false,
        tabStatusType: 'attivi',
      };
    },

    setDataAvailable(state, action: PayloadAction<boolean>) {
      state.dataAvailable = action.payload;
    },

    showAuctionsDrilldown(state, action: PayloadAction<string[]>) {
      applyDrilldownState(state, action.payload, { showAuctions: true });
    },

    showEarningsDrilldown(state, action: PayloadAction<string[]>) {
      applyDrilldownState(state, action.payload, { showEarnings: true });
    },

    showPartnerDrilldown(
      state,
      action: PayloadAction<{ ids: string[]; tabStatusType?: TabStatusType }>
    ) {
      applyDrilldownState(state, action.payload.ids, { showPartners: true });
      state.ui.tabStatusType = action.payload.tabStatusType
        ? action.payload.tabStatusType
        : 'attivi';
    },

    showProvinceDrilldown(state, action: PayloadAction<string[]>) {
      applyDrilldownState(state, action.payload, { showProvince: true });
    },

    hideDrilldown(state) {
      applyDrilldownState(state, []);
    },

    setData(
      state,
      action: PayloadAction<{
        rawData: IKPIModel[];
        timeUnit: KPITimeScale;
      }>
    ) {
      state.dataAvailable = true;
      state.timeUnit = action.payload.timeUnit;
      state.data = transformRawData(action.payload.timeUnit, action.payload.rawData);
    },
  },
});

export const kpiStateActions = kpiSlice.actions;

export default kpiSlice.reducer;
