// @mui
import { TableRow, TableCell, Typography, useTheme, Link } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import { ListServicerItem } from 'src/@types/servicer'


// ----------------------------------------------------------------------

type Props = {
  row: ListServicerItem;
  onDetailServicerClick: VoidFunction;
};

export default function ServicerTableRow({
  row,
  onDetailServicerClick
}: Props) {

  const onViewRow = () => {
      onDetailServicerClick();
    };  

  const theme = useTheme();

  return (
    <TableRow hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Link
          noWrap
          variant="subtitle2"
          sx={{ color: 'text.primary', cursor: 'pointer' }}
          color="text.primary"
          onClick={onViewRow}
        >
          {row.nomeVisualizzato}
        </Link>
      </TableCell>

      <TableCell align="left">
        <Typography variant="subtitle1" noWrap>
          {row.feeReviva ? (
            row.feeReviva
          ) : (
            <Iconify icon={'codicon:dash'} color={theme.palette.grey[700]} width={28} height={28} />
          )}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Iconify
          icon={row.tipologiaGuadagnoIcona ? row.tipologiaGuadagnoIcona : 'codicon:dash'}
          color={theme.palette.grey[700]}
          width={28}
          height={28}
        />
        <span style={{ fontWeight: 'bold', verticalAlign: 'super', marginLeft: 5 }}>
          {row.tipologiaGuadagnoNome}
        </span>
      </TableCell>

      <TableCell align="center">
        <Iconify
          icon={row.abilitato === true ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
          sx={{
            width: 28,
            height: 28,
            color: 'primary.main',
            ...(row.abilitato !== true && { color: 'warning.main' }),
          }}
        />
      </TableCell>
    </TableRow>
  );
}
