import { PARSE_SERVER_API } from 'src/config';
import { AstaModel } from './models/asta.model';
import { ComuneModel } from './models/comune.model';
import { ComunicazioniAstaModel } from './models/comunicazioni-asta.model';
import { GuadagniVivacizzatoriModel } from './models/guadagni-vivacizzatori.model';
import { KPIVivacizzatoriModel } from './models/kpi-vivacizzatori.model';
import { KPIModel } from './models/kpi.model';
import { NotificaModel } from './models/notifica.model';
import { ServicerModel } from './models/servicer.model';
import { TipologiaGuadagnoModel } from './models/tipologia-guadagno.model';
import { TipologiaImmobileModel } from './models/tipologia-immobile.model';
import { UserModel } from './models/user.model';
import { RisorsaModel } from './models/risorsa.model';
import { InOutLogsModel } from './models/in-out-logs.model';
import { PartecipazioniModel } from './models/partecipazioni.model';
import { PartecipazioniFilesModel } from './models/partecipazioni-files.model';

const Parse = require('parse');

export class ParseConfig {
  public static initialize(): void {
    Parse.initialize(PARSE_SERVER_API.appId, PARSE_SERVER_API.jsKey);
    Parse.serverURL = PARSE_SERVER_API.apiHost + PARSE_SERVER_API.apiMount;
    Parse.liveQueryServerURL = PARSE_SERVER_API.ws;
  }

  public static registerSubclasses(): void {
    UserModel.registerParseSubclass();
    AstaModel.registerParseSubclass();
    ComuneModel.registerParseSubclass();
    ServicerModel.registerParseSubclass();
    TipologiaGuadagnoModel.registerParseSubclass();
    TipologiaImmobileModel.registerParseSubclass();
    ComunicazioniAstaModel.registerParseSubclass();
    GuadagniVivacizzatoriModel.registerParseSubclass();
    NotificaModel.registerParseSubclass();
    KPIModel.registerParseSubclass();
    KPIVivacizzatoriModel.registerParseSubclass();
    RisorsaModel.registerParseSubclass();
    InOutLogsModel.registerParseSubclass();
    PartecipazioniModel.registerParseSubclass();
    PartecipazioniFilesModel.registerParseSubclass();
  }
}
