// @mui
import { Box, Tooltip, ListItemButtonProps, Badge } from '@mui/material';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { NavItemProps } from 'src/components/nav-section/type';
import Iconify from 'src/components/Iconify';
import {
  ListItemStyle,
  ListItemIconStyle,
  ListItemTextStyle,
} from 'src/components/nav-section/vertical/style';
import ResourceNew from './ResourceNew';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { RisorsaModel } from 'src/parse/models/risorsa.model';

// ----------------------------------------------------------------------

type Props = NavItemProps & ListItemButtonProps;

export default function ResourceVerticalNavItem({
  item,
  depth,
  active,
  open,
  isCollapse,
  ...other
}: Props) {
  const { title, icon, info, children, disabled, caption, roles } = item;
  const wholeResources = useSelector<RootState, RisorsaModel[]>(
    (state) => state.resource.resources
  );
  const hasNews = () => wholeResources.filter((item) => item.novita).length > 0;
  const renderContent = (
    <ListItemStyle depth={depth} active={active} disabled={disabled} {...other}>
      {icon && hasNews() && isCollapse && (
        <ListItemIconStyle>
          <Badge badgeContent={wholeResources.filter((item) => item.novita).length} color="warning">
            <Box sx={{ width: '24px', height: '24px', marginLeft: '-16px' }}>{icon}</Box>
          </Badge>
        </ListItemIconStyle>
      )}

      {icon && !(hasNews() && isCollapse) && <ListItemIconStyle>{icon}</ListItemIconStyle>}

      {depth !== 1 && <DotIcon active={active && depth !== 1} />}

      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={
          <>
            {title}

            <ResourceNew sx={{ marginLeft: 2 }} isNew={hasNews()} />
          </>
        }
        secondary={
          caption && (
            <Tooltip title={caption} placement="top-start">
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {!isCollapse && (
        <>
          {info && (
            <Box component="span" sx={{ lineHeight: 0 }}>
              {info}
            </Box>
          )}

          {!!children && (
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
            />
          )}
        </>
      )}
    </ListItemStyle>
  );

  return <RoleBasedGuard roles={roles}>{renderContent}</RoleBasedGuard>;
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}
