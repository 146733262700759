import * as Parse from 'parse';
import { UserModel } from './user.model';
import { AstaModel } from './asta.model';
import { ComunicazioniAstaModel } from './comunicazioni-asta.model';
import { InOutLogsModel } from './in-out-logs.model';

export class PartecipazioniModel extends Parse.Object {
  public static className = 'Partecipazioni';

  public static fields = {
    objectId: 'objectId',
    idAffidamentoReviva: 'idAffidamentoReviva',
    ACL: 'ACL',
    vivacizzatore: 'vivacizzatore',
    updatedAt: 'updatedAt',
    nota: 'nota',
    files: 'files',
    accettato: 'accettato',
    createdAt: 'createdAt',
    nominativo: 'nominativo',
    importoOfferta: 'importoOfferta',
    asta: 'asta',
    inOutLog: "inOutLog",
  };

  constructor() {
    super(PartecipazioniModel.className);
  }

  public static registerParseSubclass(): void {
    Parse.Object.registerSubclass(PartecipazioniModel.className, PartecipazioniModel);
  }

  public get objectId(): string {
    return this.get(PartecipazioniModel.fields.objectId);
  }

  public get createdAt(): Date {
    return this.get(PartecipazioniModel.fields.createdAt);
  }

  public get updatedAt(): Date {
    return this.get(PartecipazioniModel.fields.updatedAt);
  }

  public get ACL(): Parse.ACL {
    return this.get(PartecipazioniModel.fields.ACL);
  }

  public set ACL(ACL: Parse.ACL) {
    this.set(PartecipazioniModel.fields.ACL, ACL);
  }

  public get vivacizzatore(): UserModel {
    return this.get(PartecipazioniModel.fields.vivacizzatore);
  }

  public set vivacizzatore(vivacizzatore: UserModel) {
    this.set(PartecipazioniModel.fields.vivacizzatore, vivacizzatore);
  }

  public get asta(): AstaModel {
    return this.get(PartecipazioniModel.fields.asta);
  }

  public set asta(asta: AstaModel) {
    this.set(PartecipazioniModel.fields.asta, asta);
  }

  public get importoOfferta(): number {
    return this.get(PartecipazioniModel.fields.importoOfferta);
  }

  public set importoOfferta(valore: number) {
    this.set(PartecipazioniModel.fields.importoOfferta, valore);
  }

  public get nominativo(): ComunicazioniAstaModel {
    return this.get(PartecipazioniModel.fields.nominativo);
  }

  public set nominativo(valore: ComunicazioniAstaModel) {
    this.set(PartecipazioniModel.fields.nominativo, valore);
  }

  public get accettato(): boolean {
    return this.get(PartecipazioniModel.fields.accettato);
  }

  public set accettato(valore: boolean) {
    this.set(PartecipazioniModel.fields.accettato, valore);
  }

  public get nota(): string {
    return this.get(PartecipazioniModel.fields.nota);
  }

  public set nota(valore: string) {
    this.set(PartecipazioniModel.fields.nota, valore);
  }

  public get idAffidamentoReviva(): string {
    return this.get(PartecipazioniModel.fields.idAffidamentoReviva);
  }

  public set idAffidamentoReviva(idAffidamentoReviva: string) {
    this.set(PartecipazioniModel.fields.idAffidamentoReviva, idAffidamentoReviva);
  }

  public get files(): Parse.Pointer[] | Parse.Object[] {
    return this.get(PartecipazioniModel.fields.files);
  }

  public set files(files: Parse.Pointer[] | Parse.Object[]) {
    this.set(PartecipazioniModel.fields.files, files);
  }

  public get inOutLog(): InOutLogsModel {
    return this.get(PartecipazioniModel.fields.inOutLog);
  }

  public set inOutLog(inOutLog: InOutLogsModel) {
    this.set(PartecipazioniModel.fields.inOutLog, inOutLog);
  }
}
