import * as Parse from 'parse';
import { TipologiaImmobileModel } from '../models/tipologia-immobile.model'

export class TipologieImmobileService {

    public static async getTypologies(): Promise<TipologiaImmobileModel[]> {
        const query = new Parse.Query(TipologiaImmobileModel.className);
        query.include(TipologiaImmobileModel.fields.nome);
        query.include(TipologiaImmobileModel.fields.sinonimi);
        query.include(TipologiaImmobileModel.fields.updatedAt); 
        query.include(TipologiaImmobileModel.fields.createdAt); 
        const typologies = await query.findAll() as TipologiaImmobileModel[];
        return typologies;
    }

}
