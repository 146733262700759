import { useSelector } from 'react-redux';
import { NotificaModel } from 'src/parse/models/notifica.model';
import { RootState } from 'src/redux/store';
import NotificationsList from 'src/sections/@dashboard/notifications/NotificationsList';

export type INotificationProps = {};

const Notifications: React.FC<INotificationProps> = () => {
  const notifications = useSelector<RootState, NotificaModel[]>(
    (state) => state.notification.notifications
  );

  return (
    <>
      <NotificationsList notifications={notifications} />
    </>
  );
};

export default Notifications;
