import { Stack, InputAdornment, TextField, FormControlLabel, Switch } from '@mui/material';
import { UserModel } from 'src/parse/models/user.model';
// components
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  filterUser: string;
  filterOwner: boolean;
  filterUnassigned: boolean;
  onFilterUser: (value: string) => void;
  onFilterOwner: (value: boolean) => void;
  onFilterUnassigned: (value: boolean) => void;
};

export default function UserTableToolbar({
  filterUser,
  filterOwner,
  filterUnassigned,
  onFilterUser,
  onFilterOwner,
  onFilterUnassigned,
}: Props) {
  const currentUser = UserModel.current() as UserModel;

  const handleOwnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && filterUnassigned) {
      filterUnassigned = false;
    }
    onFilterUnassigned(false);
    onFilterOwner(event.target.checked);
  };

  const handleUnassignedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && filterOwner) {
      filterOwner = false;
    }
    onFilterOwner(false);
    onFilterUnassigned(event.target.checked);
  };

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        // fullWidth
        value={filterUser}
        onChange={(event) => onFilterUser(event.target.value)}
        placeholder="Cerca partner..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <FormControlLabel
        control={<Switch checked={filterOwner} onChange={handleOwnerChange} />}
        label={`Assegnati a ${currentUser.anagrafica.nome} ${currentUser.anagrafica.cognome}`}
      />

      <FormControlLabel
        control={<Switch checked={filterUnassigned} onChange={handleUnassignedChange} />}
        label={`Non Assegnati`}
      />
    </Stack>
  );
}
