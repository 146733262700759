import * as Parse from 'parse';
import { ServicerModel } from '../models/servicer.model'

export class ServicersService {

    public static async getServicers(): Promise<ServicerModel[]> {
        const query = new Parse.Query(ServicerModel.className);
        // query.include(ServicerModel.fields.objectId);
        query.include(ServicerModel.fields.denominazione);
        query.include(ServicerModel.fields.nomeVisualizzato);
        query.include(ServicerModel.fields.tipologiaGuadagno);
        query.include(ServicerModel.fields.abilitato);
        query.include(ServicerModel.fields.feeReviva); 
        query.include(ServicerModel.fields.updatedAt); 
        query.include(ServicerModel.fields.createdAt); 
        const servicers = await query.findAll() as ServicerModel[];
        return servicers;
    }

    public static async getServicerById(servicerId: string): Promise<ServicerModel> {
        const query = new Parse.Query(ServicerModel.className);
        query.include(ServicerModel.fields.denominazione);
        query.include(ServicerModel.fields.tipologiaGuadagno);
        query.include(ServicerModel.fields.nomeVisualizzato);
        query.include(ServicerModel.fields.abilitato);
        query.include(ServicerModel.fields.feeReviva); 
        query.include(ServicerModel.fields.updatedAt); 
        query.include(ServicerModel.fields.createdAt);
        query.equalTo(ServicerModel.fields.objectId, servicerId);
        const servicer = await query.first() as ServicerModel;
        return servicer;
    }

}
