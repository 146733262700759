import * as Parse from 'parse';
import { IKPIModel } from './interfaces/kpi-interface';
import { IKPIMetrica } from './interfaces/kpi-metrica.interface';
import { KPIMetricheEnum } from './interfaces/kpi-metriche.enum';

export class KPIModel extends Parse.Object implements IKPIModel {
  public static className = 'KPI';

  public static fields = {
    objectId: 'objectId',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    ACL: 'ACL',
    dataRiferimento: 'dataRiferimento',
    metriche: 'metriche',
  };

  constructor() {
    super(KPIModel.className);
  }

  public static registerParseSubclass(): void {
    Parse.Object.registerSubclass(KPIModel.className, KPIModel);
  }

  public get objectId(): string {
    return this.get(KPIModel.fields.objectId);
  }

  public set objectId(objectId: string) {
    this.set(KPIModel.fields.objectId, objectId);
  }

  public get createdAt(): Date {
    return this.get(KPIModel.fields.createdAt);
  }

  public set createdAt(date: Date) {
    this.set(KPIModel.fields.createdAt, date);
  }

  public get updatedAt(): Date {
    return this.get(KPIModel.fields.updatedAt);
  }

  public set updatedAt(date: Date) {
    this.set(KPIModel.fields.updatedAt, date);
  }

  public get ACL(): Parse.ACL {
    return this.get(KPIModel.fields.ACL);
  }

  public set ACL(ACL: Parse.ACL) {
    this.set(KPIModel.fields.ACL, ACL);
  }

  public get dataRiferimento(): Date {
    return this.get(KPIModel.fields.dataRiferimento);
  }

  public set dataRiferimento(dataRiferimento: Date) {
    this.set(KPIModel.fields.dataRiferimento, dataRiferimento);
  }

  public get metriche(): Map<KPIMetricheEnum, IKPIMetrica> {
    const metricheJson = this.get(KPIModel.fields.metriche);
    if (metricheJson) {
      return new Map(Object.entries(metricheJson)) as Map<KPIMetricheEnum, IKPIMetrica>;
    }
    return new Map();
  }

  public set metriche(metriche: Map<KPIMetricheEnum, IKPIMetrica>) {
    const metricheJson = JSON.parse(JSON.stringify(Object.fromEntries(metriche)));
    this.set(KPIModel.fields.metriche, metricheJson);
  }
}
