import * as Parse from 'parse';
import { Anagrafica } from './interfaces/anagrafica.interface'
import { DatiFiscali } from './interfaces/dati-fiscali.interface'
import { IInfoVivacizzazioni } from './interfaces/info-vivacizzazioni.interface';
import { StatusUser } from './interfaces/status-user.enum';
import { ZoneCompetenza } from './interfaces/zone-competenza.interface';

export class UserModel extends Parse.User {

    public static className = "_User";

    public static fields = {
        objectId: "objectId",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        ACL: "ACL",
        username: "username",
        password: "password",
        email: "email",
        emailVerified: "emailVerified",
        authData: "authData",

        // ------- custom fields --------
        datiFiscali: "datiFiscali",
        fotoProfilo: "fotoProfilo",
        anagrafica: "anagrafica", 
        tipologiaPartner: "tipologiaPartner",
        status: "status",  
        zoneCompetenza: "zoneCompetenza",
        preferiti: "preferiti",
        infoVivacizzazioni: "infoVivacizzazioni",
        referente:"referente"
    };

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(UserModel.className, UserModel);
    }

    public get objectId(): string {
        return this.id;
    }

    public get createdAt(): Date {
        return this.get(UserModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(UserModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(UserModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(UserModel.fields.ACL, ACL);
    }

    public get username(): string {
        return this.get(UserModel.fields.username);
    }

    public set username(username: string) {
        this.set(UserModel.fields.username, username);
    }

    public get password(): string {
        return this.get(UserModel.fields.password);
    }

    public set password(password: string) {
        this.set(UserModel.fields.password, password);
    }

    public get email(): string {
        return this.get(UserModel.fields.email);
    }

    public set email(email: string) {
        this.set(UserModel.fields.email, email);
    }

    public get emailVerified(): boolean {
        return this.get(UserModel.fields.emailVerified);
    }

    public set emailVerified(emailVerified: boolean) {
        this.set(UserModel.fields.emailVerified, emailVerified);
    }

    public get authData(): any {
        return this.get(UserModel.fields.authData);
    }

    public set authData(authData: any) {
        this.set(UserModel.fields.authData, authData);
    }

    public get fotoProfilo(): Parse.File {
        return this.get(UserModel.fields.fotoProfilo);
    }

    public set fotoProfilo(fotoProfilo: Parse.File) {
        this.set(UserModel.fields.fotoProfilo, fotoProfilo);
    }

    public get datiFiscali(): DatiFiscali {
        return this.get(UserModel.fields.datiFiscali);
    }

    public set datiFiscali(datiFiscali: DatiFiscali) {
        this.set(UserModel.fields.datiFiscali, datiFiscali);
    }

    public get anagrafica(): Anagrafica {
        return this.get(UserModel.fields.anagrafica);
    }

    public set anagrafica(anagrafica: Anagrafica) {
        this.set(UserModel.fields.anagrafica, anagrafica);
    }

    public get status(): StatusUser {
        return this.get(UserModel.fields.status);
    }

    public set status(status: StatusUser) {
        this.set(UserModel.fields.status, status);
    }

    public get zoneCompetenza(): ZoneCompetenza {
        return this.get(UserModel.fields.zoneCompetenza);
    }

    public set zoneCompetenza(zoneCompetenza: ZoneCompetenza) {
        this.set(UserModel.fields.zoneCompetenza, zoneCompetenza);
    }

    public get preferiti(): string[] {
        return this.get(UserModel.fields.preferiti);
    }

    public set preferiti(preferiti: string[]) {
        this.set(UserModel.fields.preferiti, preferiti);
    }

    public get infoVivacizzazioni(): IInfoVivacizzazioni  {
        return this.get(UserModel.fields.infoVivacizzazioni);
    }

    public set infoVivacizzazioni(infoVivacizzazioni: IInfoVivacizzazioni) {
        this.set(UserModel.fields.infoVivacizzazioni, infoVivacizzazioni);
    }

    public get tipologiaPartner(): string {
        return this.get(UserModel.fields.tipologiaPartner);
    }

    public set tipologiaPartner(tipologiaPartner: string) {
        this.set(UserModel.fields.tipologiaPartner, tipologiaPartner);
    }

    public get referente(): UserModel | null {
        return this.get(UserModel.fields.referente);
    }

    public set referente(referente: UserModel | null) {
        this.set(UserModel.fields.referente, referente);
    }

}

