import { AuthProvider } from './contexts/ParseContext';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
//import { PersistGate } from 'redux-persist/integration/react'

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import { ParseConfig } from './parse/parse-config';

import App from './App';
import store from './redux/store';
import { ApplicationSettingsProvider } from './contexts/ApplicationSettingsContext';

// ----------------------------------------------------------------------

// init parse sever
ParseConfig.initialize();
ParseConfig.registerSubclasses();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <HelmetProvider>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          <SettingsProvider>
            <ApplicationSettingsProvider>
              <CollapseDrawerProvider>
                <App />
              </CollapseDrawerProvider>
            </ApplicationSettingsProvider>
          </SettingsProvider>
          {/* </PersistGate> */}
        </HelmetProvider>
      </AuthProvider>
    </BrowserRouter>
  </ReduxProvider>
);
