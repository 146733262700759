import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { themeMode } = useSettings();
  
  const logo = (
    <Box
      component="img"
      src={themeMode === 'light' ? "/logo/vivapro_logo_light.png" : "/logo/vivapro_logo_dark.png"}
      sx={{  cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
