import { useSnackbar } from 'notistack';
// @mui
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Checkbox, CircularProgress, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserModel } from 'src/parse/models/user.model';
import { fetchProvince } from 'src/redux/actions/staticDataActions';
import { useAppDispatch } from 'src/redux/hooks';
import { RootState } from 'src/redux/store';
import { ZonaCompetenzaValore } from '../../../../parse/models/interfaces/zone-competenza.interface';

// ----------------------------------------------------------------------

interface Props {
  user: UserModel;
}

export default function PartnerZoneCompetenzaEdit({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const loading = useSelector<RootState, boolean>((state) => state.global.provinceStatus.isLoading);
  const province = useSelector<RootState, ZonaCompetenzaValore[]>(
    (state) => state.staticData.province
  );

  const [selectedValues, setSelectedValues] = useState<ZonaCompetenzaValore[]>(
    user.zoneCompetenza.province
  );
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    if (!province || province.length === 0) {
      dispatch(fetchProvince());
    }
  }, [dispatch, province]);

  const handleChangeOption = (event: any, values: ZonaCompetenzaValore[]) => {
    setSelectedValues(values);
    const differencesA = user.zoneCompetenza.province.filter(
      (object1) => !values.some((object2) => object1.codice === object2.codice)
    );
    const differencesB = values.filter(
      (object1) =>
        !user.zoneCompetenza.province.some((object2) => object1.codice === object2.codice)
    );
    if (differencesA.length > 0 || differencesB.length > 0) {
      setEnableSaveButton(true);
    } else {
      setEnableSaveButton(false);
    }
  };

  const handleSubmit = async () => {
    setLoadingSave(true);
    user.zoneCompetenza.province = selectedValues;
    await user.save();
    setLoadingSave(false);
    enqueueSnackbar('Aggiornamento riuscito!');
    setEnableSaveButton(false);
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Stack>
          {!loadingSave && (
            <Autocomplete
              multiple
              id="checkboxes-zone-competenza"
              options={province}
              disableCloseOnSelect
              getOptionLabel={(option) => option.valore}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.valore}
                </li>
              )}
              defaultValue={[...user.zoneCompetenza.province]}
              isOptionEqualToValue={(option, value) => option.codice === value.codice}
              onChange={handleChangeOption}
              renderInput={(params) => (
                <TextField {...params} label="Province" placeholder="Cerca..." />
              )}
            />
          )}
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton
              loading={loadingSave}
              onClick={handleSubmit}
              variant="contained"
              disabled={!enableSaveButton}
            >
              Salva modifiche
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </>
  );
}
