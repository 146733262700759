import { Box, Divider, IconButton, List, ListSubheader, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import useAuth from 'src/hooks/useAuth';
import { NotificaModel } from 'src/parse/models/notifica.model';
import { AuctionLogger } from 'src/utils/loggerManager';
import NotificationItem from './NotificationItem';

export type INotificationsListProps = {
  notifications: NotificaModel[];
  maxRead?: number;
  onClickItem?: (notifications: NotificaModel) => void;
};

const NotificationsList: React.FC<INotificationsListProps> = ({
  notifications,
  maxRead,
  onClickItem,
}) => {
  const { user } = useAuth();

  const read = notifications
    .filter((notification) => notification.lettoDa.includes(user!.id))
    .sort(function (a, b) {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  const unread = notifications
    .filter((notification) => !notification.lettoDa.includes(user!.id))
    .sort(function (a, b) {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  const totalUnRead = unread.length;
  const totalRead = read.length;

  const handleMarkAllAsRead = () => {
    AuctionLogger('Notification', 'UI Action', `Click on mark all notification as read`);
    if (unread && unread.length > 0) {
      unread.forEach((notification) => {
        if (!notification.lettoDa.includes(user!.id)) {
          notification.addUnique(NotificaModel.fields.lettoDa, user!?.id);
          AuctionLogger('Notification', 'UI Action', `Mark notification as read:`, notification.id);
          notification.save();
        }
      });
    }
  };

  const getReadToShow = () => {
    if (!maxRead) {
      return read.length;
    } else {
      return maxRead > totalRead ? totalRead : maxRead;
    }
  };

  const isReadLimited = () => {
    if (!maxRead) {
      return false;
    } else {
      return totalRead > maxRead;
    }
  };

  const handleClickItem = (notification: NotificaModel) => {
    if (onClickItem) {
      AuctionLogger('Notification', 'UI Action', `Click on notification item: ${notification.id}`);
      onClickItem(notification);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">Notifiche</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Hai {totalUnRead} notifiche non lette
          </Typography>
        </Box>

        {totalUnRead > 0 && (
          <Tooltip title=" Segna tutte come già lette">
            <IconButton color="primary" onClick={handleMarkAllAsRead}>
              <Iconify icon="eva:done-all-fill" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
        {totalUnRead > 0 && (
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Da Leggere
              </ListSubheader>
            }
          >
            {unread.map((notification) => (
              <NotificationItem
                onClickItem={handleClickItem}
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        )}

        {totalRead > 0 && (
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {isReadLimited() ? `Ultime ${getReadToShow()} Lette` : `Lette`}
              </ListSubheader>
            }
          >
            {read.slice(0, getReadToShow()).map((notification) => (
              <NotificationItem
                onClickItem={handleClickItem}
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        )}

        {totalRead === 0 && totalUnRead === 0 && (
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Nessuna Notifica Presente
              </ListSubheader>
            }
          />
        )}

        {isReadLimited() && totalRead > 0 && (
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline', textAlign: 'center' }}
              >
                ...
              </ListSubheader>
            }
          />
        )}
      </Scrollbar>
    </>
  );
};

export default NotificationsList;
