import * as Parse from "parse";
import { TipoNotifiche } from "./interfaces/tipo-notifiche.enum";
import { UserModel } from "./user.model";

export class NotificaModel extends Parse.Object {
    
    public static className = "Notifiche";

    public static fields = {
       objectId: "objectId",
       createdAt: "createdAt",
       updatedAt: "updatedAt",
       ACL: "ACL",
       tipo: "tipo",
       users: "users",
       content: "content",
       originator: "originator",
       riferimenti: "riferimenti",
       lettoDa: "lettoDa"
   };
    
    constructor() {
        super(NotificaModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(NotificaModel.className, NotificaModel);
    }

    public get objectId(): string {
        return this.get(NotificaModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(NotificaModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(NotificaModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(NotificaModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(NotificaModel.fields.ACL, ACL);
    }

    public get tipo(): TipoNotifiche {
        return this.get(NotificaModel.fields.tipo);
    }

    public set tipo(tipo: TipoNotifiche) {
        this.set(NotificaModel.fields.tipo, tipo);
    }

    public get users(): any[] {
        return this.get(NotificaModel.fields.users);
    }

    public set users(users: any[]) {
        this.set(NotificaModel.fields.users, users);
    }

    public get content(): string {
        return this.get(NotificaModel.fields.content);
    }

    public set content(content: string) {
        this.set(NotificaModel.fields.content, content);
    }

    public get originator(): UserModel {
        return this.get(NotificaModel.fields.originator);
    }

    public set originator(originator: UserModel) {
        this.set(NotificaModel.fields.originator, originator);
    }

    public get riferimenti(): Parse.Object[] {
        return this.get(NotificaModel.fields.riferimenti);
    }

    public set riferimenti(riferimenti: Parse.Object[]) {
        this.set(NotificaModel.fields.riferimenti, riferimenti);
    }

    public get lettoDa(): string[] {
        return this.get(NotificaModel.fields.lettoDa);
    }

    public set lettoDa(lettoDa: string[]) {
        this.set(NotificaModel.fields.lettoDa, lettoDa);
    }
}