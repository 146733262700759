import ResourceVerticalNavItem from 'src/sections/@dashboard/resources/widgets/ResourceVerticalNavItem';
import { NavListProps } from '../type';
import NavItem from './NavItem';

export function VerticalNavItemFactory(
  data: NavListProps,
  depth: number,
  open: boolean,
  active: boolean,
  dataTestId: string,
  handleClickItem: () => void,
  isCollapse?: boolean
) {
  switch (data.key) {
    case 'resources':
      return (
        <ResourceVerticalNavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
          data-testid={dataTestId}
        />
      );
    default:
      return (
        <NavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isCollapse={isCollapse}
          onClick={handleClickItem}
          data-testid={dataTestId}
        />
      );
  }
}
