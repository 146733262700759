import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import { NotificaModel } from 'src/parse/models/notifica.model';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fToNow } from 'src/utils/formatTime';
import { AuctionLogger } from 'src/utils/loggerManager';
import { getAuctionModelFromNotification, getNotificationData2Display } from './NotificationUtils';
import { TipoNotifiche } from 'src/parse/models/interfaces/tipo-notifiche.enum';

type INotificationItemsProps = {
  notification: NotificaModel;
  onClickItem?: (item: NotificaModel) => void;
};

function renderContent(notification: NotificaModel, theme: Theme) {
  const data2display = getNotificationData2Display(notification, theme);

  const title = (
    <Typography variant="subtitle2">
      {data2display.primaryText}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {data2display.secondaryText}
      </Typography>
    </Typography>
  );

  return {
    avatar: (
      <Iconify icon={data2display.iconPath} color={data2display.iconColor} borderColor={'blue'} />
    ),
    title,
  };
}

const NotificationItems: React.FC<INotificationItemsProps> = ({
  notification,
  onClickItem,
}: INotificationItemsProps) => {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();

  const { avatar, title } = renderContent(notification, theme);

  const handleClick = async () => {
    const auctionModel = getAuctionModelFromNotification(notification);

    if (onClickItem) {
      if (!notification.lettoDa.includes(user!.id)) {
        notification.addUnique(NotificaModel.fields.lettoDa, user!?.id);
        await notification.save();
      }
      onClickItem(notification);
    }
    AuctionLogger(
      'Notification',
      'UI Action',
      'Navigate to',
      auctionModel ? auctionModel.id : 'empty model'
    );

    if (notification.tipo === TipoNotifiche.PARTNER_BROADCAST_RISORSA_ADD) {
      navigate(PATH_DASHBOARD.resources.root, { replace: true });
    } else if (auctionModel) {
      navigate(PATH_DASHBOARD.immobili.view(auctionModel.id), { replace: true });
    }
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.lettoDa.includes(user!.id) && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar onClick={handleClick}>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        onClick={handleClick}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default NotificationItems;
