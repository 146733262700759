export type AreaKey =
  | 'Login'
  | 'Calc'
  | 'Logging'
  | 'Generic'
  | 'Preference'
  | 'Auction'
  | 'Auction Details'
  | 'Favourite'
  | 'Settings'
  | 'Earnings'
  | 'Resources'
  | 'Notification'
  | 'KPI'
  | 'Partner'
  | 'Names'
  | 'Communication'
  | 'Participation'

export type ActionKey =
  | 'FCALL'
  | 'FRES'
  | 'UI Action'
  | 'Component Created'
  | 'Data'
  | 'Broadcast'

export interface ILogItem {
  key: string;
  date: string;
  area: AreaKey;
  action: ActionKey;
  message: string;
  optionalParams: unknown[];
}

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export function AuctionLogger(
    areaKey: AreaKey,
    actionKey: ActionKey,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message?: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...optionalParams: any[]
  ) {
    var dt = new Date();
    var dataTag = `${dt.getFullYear().toString().padStart(4, '0')}-${dt
      .getDate()
      .toString()
      .padStart(2, '0')}-${(dt.getMonth() + 1).toString().padStart(2, '0')} ${dt
      .getHours()
      .toString()
      .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${dt.getMilliseconds().toString().padStart(4, '0')}`;
    //in case you are in development mode, write in the consolo log of the web browser immediately
    let parameterAsString = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionalParams.forEach((value: any) => {
      let jsonValue = '';
      try {
        jsonValue = JSON.stringify(value);
      } catch {
        jsonValue = 'JSON PARSE ERROR!';
      }
      parameterAsString = parameterAsString + jsonValue + ', ';
    });
  
    if (IS_DEVELOPMENT) {
      const log2write = `${dataTag} - [VivaPro] [${areaKey}] [${actionKey}] ${message} ${parameterAsString}`;
      console.log(log2write);
    }
    //in any case update the store and wait for writing the log on web service side
    const logItem: ILogItem = {
      key: dataTag,
      date: dataTag,
      area: areaKey,
      action: actionKey,
      message: message,
      optionalParams: optionalParams,
    };
    return logItem;
  }