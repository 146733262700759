import * as Parse from 'parse';
import { NotificaModel } from "../models/notifica.model";

export class NotificheService {

    public static getNotificationSubscriptionQuery() {
        const query = new Parse.Query(NotificaModel.className);
        query.include(NotificaModel.fields.originator);
        query.include(NotificaModel.fields.riferimenti);
        query.include("riferimenti.asta")
        query.findAll()
        return query;
    }
}