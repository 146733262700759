import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ZonaCompetenzaValore } from 'src/parse/models/interfaces/zone-competenza.interface';

export interface IStaticDataState {
  province: ZonaCompetenzaValore[],
  typology: string[]
}

const initialState: IStaticDataState = {
  province: [],
  typology: []
};


const staticDataSlice = createSlice({
  name: 'staticData',
  initialState: initialState,
  reducers: {
    setProvince(state, action: PayloadAction<ZonaCompetenzaValore[]>) {
      state.province = action.payload;
    },

    setTypology(state, action: PayloadAction<string[]>) {
        state.typology = action.payload;
      },

  },
});

export const staticDataStateActions = staticDataSlice.actions;

export default staticDataSlice.reducer;
