// components
import { styled } from '@mui/material/styles';
import { UserRoles } from 'src/config';
import Iconify from '../../../components/Iconify';
import { NavListProps } from 'src/components/nav-section/type';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

const ICONS = {
  dashboard: <IconStyle icon={'mdi:tablet-dashboard'} />,
  immobili: <IconStyle icon={'mdi:home-group'} />,
  analytics: <IconStyle icon={'carbon:analytics'} />,
  user: <IconStyle icon={'mdi:people'} />,
  servicer: <IconStyle icon={'mdi:bank'} />,
  favourites: <IconStyle icon={'material-symbols:favorite-rounded'} />,
  names: <IconStyle icon={'mdi:rename-outline'} />,
  earnings: <IconStyle icon={'mdi:currency-usd'} />,
  stats: <IconStyle icon={'ic:sharp-query-stats'} />,
  resources: <IconStyle icon={'mdi:folder-media-outline'} />,
  documents: <IconStyle icon={'mdi:file-document-outline'} />
};

export type NavType =
  | 'auctions'
  | 'partners'
  | 'servicers'
  | 'favourites'
  | 'partners'
  | 'earnings'
  | 'stats'
  | 'resources'
  | 'livenup';

const navConfig = [
  {
    subheader: '',
    items: [
      {
        key: 'auctions',
        title: 'Immobili',
        path: '/immobili/lista',
        dataTestId: 'nav-config-auction-list',
        icon: ICONS.immobili,
        roles: [UserRoles.ADMIN, UserRoles.PARTNER],
      },
      {
        key: 'partners',
        title: 'Partners',
        path: '/partners',
        dataTestId: 'nav-config-partners',
        icon: ICONS.user,
        roles: [UserRoles.ADMIN],
      },
      {
        key: 'servicers',
        title: 'Servicers',
        path: '/servicers',
        dataTestId: 'nav-config-servicers',
        icon: ICONS.servicer,
        roles: [UserRoles.ADMIN],
      },
      {
        key: 'favourites',
        title: 'Preferiti',
        path: '/preferiti',
        dataTestId: 'nav-config-favourites',
        icon: ICONS.favourites,
        roles: [UserRoles.ADMIN],
      },
      {
        key: 'partners',
        title: 'Nominativi',
        path: '/nominativi',
        dataTestId: 'nav-config-partners',
        icon: ICONS.names,
        roles: [UserRoles.ADMIN],
      },
      {
        key: 'earnings',
        title: 'Guadagni',
        path: '/guadagni',
        dataTestId: 'nav-config-earnings',
        icon: ICONS.earnings,
        roles: [UserRoles.ADMIN],
      },
      {
        key: 'stats',
        title: 'Statistiche',
        path: '/stats',
        dataTestId: 'nav-config-stats',
        icon: ICONS.stats,
        roles: [UserRoles.ADMIN, UserRoles.OPERATION],
      },
      {
        key: 'resources',
        title: 'Media',
        path: '/resources',
        dataTestId: 'nav-config-resources',
        icon: ICONS.resources,
        roles: [UserRoles.ADMIN, UserRoles.PARTNER],
      },
      {
        key: 'documents',
        title: 'Partecipazioni',
        path: '/participations',
        dataTestId: 'nav-config-documents',
        icon: ICONS.documents,
        roles: [UserRoles.ADMIN],
      },
    ] as NavListProps[],
  },
];

export default navConfig;
