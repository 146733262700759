import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'src/parse/models/user.model';

export interface IPartnerState {
  selectedPartner: UserModel | null;
}

const initialState: IPartnerState = {
  selectedPartner: null,
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState: initialState,
  reducers: {
    setSelectedPartner(state, action: PayloadAction<UserModel | null>) {
      state.selectedPartner = action.payload;
    },
    cleanSelectedPartner(state) {
      state.selectedPartner = null;
    },
  },
});

export const partnerStateActions = partnerSlice.actions;

export default partnerSlice.reducer;
