//
import { CustomFile } from '../types';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null;
};

export default function SingleFilePreview({ file }: Props) {
  const isPdf = () => {
    const fileAsCustom = file as CustomFile;
    let isPdf = false;
    if (fileAsCustom) {
      isPdf = fileAsCustom.type === 'application/pdf';
    }
    return isPdf;
  };

  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;
  return (
    <>
      {!isPdf() && (
        <img
          alt="file preview"
          src={imgUrl}
          style={{
            top: 8,
            left: 8,
            zIndex: 8,
            borderRadius: 1,
            position: 'absolute',
            width: 'calc(100% - 16px)',
          }}
        />
      )}
      {isPdf() && (
        <embed
          src={imgUrl}
          style={{
            top: 8,
            left: 8,
            zIndex: 8,
            borderRadius: 1,
            position: 'absolute',
            width: 'calc(100% - 16px)',
            height: 'calc(100% - 16px)',
          }}
        />
      )}
    </>
  );
}
