import * as Parse from "parse";
import { TipoRisorse } from "./interfaces/tipo-risorse.enum";
import { IRisorsaContentAttribute } from "./interfaces/risorsa-content-attribute.interface";

export class RisorsaModel extends Parse.Object {
    
    public static className = "Risorse";

    public static fields = {
       objectId: "objectId",
       createdAt: "createdAt",
       updatedAt: "updatedAt",
       ACL: "ACL",
       nome: "nome",
       tipo: "tipo",
       contentFile: "contentFile",
       contentString: "contentString",
       contentAttribute: "contentAttribute",
       novita: "novita",
   };
    
    constructor() {
        super(RisorsaModel.className);
    }

    public static registerParseSubclass(): void {
        Parse.Object.registerSubclass(RisorsaModel.className, RisorsaModel);
    }

    public get objectId(): string {
        return this.get(RisorsaModel.fields.objectId);
    }

    public get createdAt(): Date {
        return this.get(RisorsaModel.fields.createdAt);
    }

    public get updatedAt(): Date {
        return this.get(RisorsaModel.fields.updatedAt);
    }

    public get ACL(): Parse.ACL {
        return this.get(RisorsaModel.fields.ACL);
    }

    public set ACL(ACL: Parse.ACL) {
        this.set(RisorsaModel.fields.ACL, ACL);
    }

    public get nome(): string {
        return this.get(RisorsaModel.fields.nome);
    }

    public set nome(nome: string) {
        this.set(RisorsaModel.fields.nome, nome);
    }

    public get tipo(): TipoRisorse {
        return this.get(RisorsaModel.fields.tipo);
    }

    public set tipo(tipo: TipoRisorse) {
        this.set(RisorsaModel.fields.tipo, tipo);
    }

    public get contentFile(): Parse.File {
        return this.get(RisorsaModel.fields.contentFile);
    }

    public set contentFile(contentFile: Parse.File) {
        this.set(RisorsaModel.fields.contentFile, contentFile);
    }

    public get contentString(): string {
        return this.get(RisorsaModel.fields.contentString);
    }

    public set contentString(contentString: string) {
        this.set(RisorsaModel.fields.contentString, contentString);
    }

    public get contentAttribute(): IRisorsaContentAttribute {
        return this.get(RisorsaModel.fields.contentAttribute);
    }

    public set contentAttribute(contentAttribute: IRisorsaContentAttribute) {
        this.set(RisorsaModel.fields.contentAttribute, contentAttribute);
    }

    public get novita(): boolean {
        return this.get(RisorsaModel.fields.novita);
    }

    public set novita(novita: boolean) {
        this.set(RisorsaModel.fields.novita, novita);
    }
}