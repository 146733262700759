import { Grid, Skeleton } from "@mui/material";

export default function SkeletonPartnerEdit() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={5}>
        <Skeleton variant="circular" width={80} height={80} sx={{ m: "auto" }} />
        <Skeleton variant="text" height={240} />
      </Grid>
      <Grid item xs={12} md={6} lg={7}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '100%', borderRadius: 2 }} />
      </Grid>
    </Grid>
  );
}