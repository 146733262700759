import * as Parse from "parse";
import { LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { UserModel } from 'src/parse/models/user.model';
import Iconify from 'src/components/Iconify'
import { useState } from 'react'
import { handleParseError } from "src/utils/parseErrorHandler";
import { useDispatch } from "react-redux";

export type ISendCredentialsModalProps = {
  open: boolean;
  user: UserModel;
  onClose: () => void;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 620,
  bgcolor: 'background.paper',
  border: '1px solid #F4F6F8',
  boxShadow: 24,
  p: 4,
};

const SendCredentialsModal: React.FC<ISendCredentialsModalProps> = ({
    user,
    open,
    onClose,
}) => {

    
  const [isSending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  
  const handleSendCredentials = async () => {
    try {
      setIsSending(true);
      await Parse.Cloud.run('sendCredentials', { userId: user.id });
      enqueueSnackbar('Invio avvenuto con successo!');
      onClose();
    } catch (error) {
        const errorMessage = handleParseError(error, dispatch, 'Invio non riuscito!');
        enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          <Card sx={style}>
            <Typography
            color='primary'
              sx={{ textAlign: 'center', pb:2 }}
              id="transition-modal-title"
              variant="h4"
              component="h5"
            >Invio delle credenziali a:
            </Typography>
            <Typography
              sx={{ textAlign: 'center', pb: 3 }}
              id="transition-modal-title"
              variant="h6"
              component="h5"
            >
              {user.anagrafica.nome} {user.anagrafica.cognome}
              <br />
              {user.email}
            </Typography>

            <Stack direction="row"
                   justifyContent="center"
                   spacing={2}>
                <LoadingButton
                  loading={isSending}
                  startIcon={<Iconify icon={'bi:send'} />}
                  type="submit"
                  variant="contained"
                  onClick={handleSendCredentials}
                >
                  Invia
                </LoadingButton>

                <Button
                    disabled={isSending}
                    color={'inherit'}
                    variant="contained"
                    onClick={onClose}
                >
                  Annulla
                </Button>
            </Stack>
         
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default SendCredentialsModal;
