export const KPI_TIME_FRAME_MONTH_VALUE = 'Mese';
export const KPI_TIME_FRAME_QUARTER_VALUE = 'Quarto';
export const KPI_TIME_FRAME_SEMESTER_VALUE = 'Semestre';
export const KPI_TIME_FRAME_YEAR_VALUE = 'Anno';
export const KPI_TIME_FRAME_VALUES = [KPI_TIME_FRAME_MONTH_VALUE, KPI_TIME_FRAME_QUARTER_VALUE, KPI_TIME_FRAME_SEMESTER_VALUE, KPI_TIME_FRAME_YEAR_VALUE]

export enum KPITimeScale {
  MONTH,
  QUARTER,
  SEMESTER,
  YEAR,
}

export function fromStringToKPIScale(value: string) {
  if (value === KPI_TIME_FRAME_MONTH_VALUE) {
    return KPITimeScale.MONTH;
  } else if (value === KPI_TIME_FRAME_QUARTER_VALUE) {
    return KPITimeScale.QUARTER;
  } else if (value === KPI_TIME_FRAME_SEMESTER_VALUE) {
    return KPITimeScale.SEMESTER;
  } else if (value === KPI_TIME_FRAME_YEAR_VALUE) {
    return KPITimeScale.YEAR;
  } else {
    return KPITimeScale.MONTH;
  }
}
