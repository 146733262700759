//these values MUST correspond to the tagFiles config files in parse
export const IDENTITY_CARD_KEY = 1;
export const PASSPORT_KEY = 2;
export const RESIDENCY_PERMIT_KEY = 3
export const CADASTRAL_SURVAY_KEY = 4
export const WIRE_TRANSFERT_KEY = 5
export const DEPOSIT_NOTE_KEY = 6
export const OTHER_KEY = 7

export const IDENTITY_CARD_VALUE = "Carta d'indentità";
export const PASSPORT_VALUE = 'Passaporto';
export const RESIDENCY_PERMIT_VALUE = 'Permesso di Soggiorno'
export const CADASTRAL_SURVAY_VALUE = 'Visura'
export const WIRE_TRANSFERT_VALUE = 'Bonifico/Assegno'
export const DEPOSIT_NOTE_VALUE = 'Nota di Deposito'
export const OTHER_VALUE = 'Altro'

export interface FileTag {
    key: number,
    value: string
}
