import * as Parse from 'parse';
import { IKPIModel } from './interfaces/kpi-interface';
import { IKPIMetrica } from './interfaces/kpi-metrica.interface';
import { KPIMetricheEnum } from './interfaces/kpi-metriche.enum';
import { UserModel } from './user.model';

export class KPIVivacizzatoriModel extends Parse.Object implements IKPIModel {
  public static className = 'KPIVivacizzatori';

  public static fields = {
    objectId: 'objectId',
    createdAt: 'createdAt',
    updatedAt: 'updatedAt',
    ACL: 'ACL',
    vivacizzatore: 'vivacizzatore',
    dataRiferimento: 'dataRiferimento',
    metriche: 'metriche',
  };

  constructor() {
    super(KPIVivacizzatoriModel.className);
  }

  public static registerParseSubclass(): void {
    Parse.Object.registerSubclass(KPIVivacizzatoriModel.className, KPIVivacizzatoriModel);
  }

  public get objectId(): string {
    return this.get(KPIVivacizzatoriModel.fields.objectId);
  }

  public get createdAt(): Date {
    return this.get(KPIVivacizzatoriModel.fields.createdAt);
  }

  public get updatedAt(): Date {
    return this.get(KPIVivacizzatoriModel.fields.updatedAt);
  }

  public get ACL(): Parse.ACL {
    return this.get(KPIVivacizzatoriModel.fields.ACL);
  }

  public set ACL(ACL: Parse.ACL) {
    this.set(KPIVivacizzatoriModel.fields.ACL, ACL);
  }

  public get vivacizzatore(): UserModel {
    return this.get(KPIVivacizzatoriModel.fields.vivacizzatore);
  }

  public set vivacizzatore(vivacizzatore: UserModel) {
    this.set(KPIVivacizzatoriModel.fields.vivacizzatore, vivacizzatore);
  }

  public get dataRiferimento(): Date {
    return this.get(KPIVivacizzatoriModel.fields.dataRiferimento);
  }

  public set dataRiferimento(dataRiferimento: Date) {
    this.set(KPIVivacizzatoriModel.fields.dataRiferimento, dataRiferimento);
  }

  public get metriche(): Map<KPIMetricheEnum, IKPIMetrica> {
    const metricheJson = this.get(KPIVivacizzatoriModel.fields.metriche);
    if (metricheJson) {
      return new Map(Object.entries(metricheJson)) as Map<KPIMetricheEnum, IKPIMetrica>;
    }
    return new Map();
  }

  public set metriche(metriche: Map<KPIMetricheEnum, IKPIMetrica>) {
    const metricheJson = JSON.parse(JSON.stringify(Object.fromEntries(metriche)));
    this.set(KPIVivacizzatoriModel.fields.metriche, metricheJson);
  }
}
