import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs, Typography } from '@mui/material';
// hooks
import useTabs from '../hooks/useTabs';
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
// sections
import { ServicerGeneral } from '../sections/@dashboard/servicers/edit';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { SkeletonServicerEdit } from 'src/components/skeleton';
import { ServicerModel } from 'src/parse/models/servicer.model';
import { ServicersService } from 'src/parse/services/servicers.service';
import { TipologiaGuadagnoModel } from 'src/parse/models/tipologia-guadagno.model';
import { TipologieGuadagnoService } from 'src/parse/services/tipologie-guadagno.service';

export default function ServicerEdit() {
  const { themeStretch } = useSettings();

  const { id = '' } = useParams();

  const [servicer, setServicer] = useState<ServicerModel>();
  const [typologies, setTypologies] = useState<TipologiaGuadagnoModel[]>([]);

  useEffect(() => {
    const getServicer = async () => {
      const servicer = await ServicersService.getServicerById(id);
      setServicer(servicer);
    };

    getServicer().catch(console.error);
  }, [id]);

  useEffect(() => {
    const getTypologies = async () => {
      const typologies = await TipologieGuadagnoService.getTypologies();
      setTypologies(typologies);
    };

    getTypologies().catch(console.error);
  }, []);

  const { currentTab, onChangeTab } = useTabs('generale');

  const generateTabs = () => {
    if (servicer) {
      return [
        {
          value: 'generale',
          icon: <Iconify icon={'mdi:bank'} width={20} height={20} />,
          component: <ServicerGeneral servicer={servicer} typologies={typologies} />,
        },
      ];
    }
    return [];
  };

  return (
    <Page title="Gestione servicer">
      {servicer ? (
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="Gestione servicer"
            links={[{ name: 'Servicers', href: '/servicers' }, { name: servicer.nomeVisualizzato }]}
          />

          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            {servicer.nomeVisualizzato}
          </Typography>
          <br />

          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {generateTabs().map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 5 }} />

          {generateTabs().map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      ) : (
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <SkeletonServicerEdit />
        </Container>
      )}
    </Page>
  );
}
